<template>
  <validation-observer v-slot="{invalid, pristine}">
    <b-modal id="invitation-form"
      v-on:ok="createOrSave"
      @hidden="resetModal"
      size="sm"
      :title="title"
      :ok-disabled="invalid || pristine"
    >
      <validation-provider rules="required" v-slot="v">
        <b-form-group
          label="Name"
          label-for="invitation-full-name">
          <b-form-input type="text" id="invitation-full-name" v-model="invitationForm.nameFull" :state="v.errors.length > 0 ? false : null"></b-form-input>
          <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider rules="required|email" v-slot="v">
        <b-form-group
          label="Email"
          label-for="invitation-email">
          <b-form-input type="email" id="invitation-email" v-model="invitationForm.email" :state="v.errors.length > 0 ? false : null" :disabled="isEditing"></b-form-input>
          <b-form-text v-if="isEditing">Email cannot be changed. Create a new Invitation instead.</b-form-text>
          <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider rules="required" v-slot="v">
        <b-form-group
          label="Size"
          label-for="invitation-size">
          <b-form-input type="number" id="invitation-size" v-model="invitationForm.size" :state="v.errors.length > 0 ? false : null"></b-form-input>
          <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider rules="required" v-slot="v">
        <b-form-group
          label="Expiration"
          label-for="invitation-expiration">
          <b-form-datepicker id="invitation-expiration" v-model="invitationForm.expiration" :state="v.errors.length > 0 ? false : null"></b-form-datepicker>
          <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('required', {
	...required,
	message: 'This field is required'
})
extend('email', {
	...email,
	message: 'Must be a valid email'
})

export default {
  name: 'InvitationFormModal',
  components: {
    ValidationProvider, ValidationObserver
  },
  data: function() {
    return {
      invitationForm: {},
    }
  },
  props: {
    globals: Object,
    invitation: [Object, Boolean],
    groupId: Number,
  },
  computed: {
    isEditing() {
      return typeof(this.invitation) !== 'undefined';
    },
    title() {
      return (this.isEditing ? 'Edit' : 'Add') + ' Invitation';
    },
  },
  methods: {
    createOrSave(bvModalEvt) {
      const action = !this.invitation ? this.createInvitation : this.patchInvitation;
      action(bvModalEvt);
    },
    createInvitation: async function(bvModalEvt) {
      const request = {
        url: '/invitations',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'post',
        data: { eventId: this.globals.event.id, groupId: this.groupId, ...this.invitationForm },
      }

      try {
        // Execute request
				const response = await this.$api3(request);

        // Update relevant data
				this.$emit('reload');

        // Succeeded, so send the email
        await this.sendInvitationEmail(response.data.email, response.data.token).then(() => {
          this.$emit('alert', { message: `The Invitation was created and an email notification has been sent to ${response.data.email}.` });
        }).catch(error => {
          console.log(error.response);
          let message = 'The Invitation was created, but the email notification could not be sent.';

          if (error.response) {
            // Server responded
            if(error.response.status === 401) {
              this.$emit('logout');
            }

            message = 'Server: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Request: ' + error;
          }

          // Emit the error
          this.$emit('alert', { error: true, message: message });
        });
      } catch (error) {
        console.log(error.response);
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      } finally {
        // Either way, close the model
        bvModalEvt.vueTarget.hide();
      }
    },
    sendInvitationEmail: async function(email, token) {
      const request = {
        url: '/emails',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'POST',
        data: {
          to: email,
          template: 'e3',
          id: token,
        }
      }

      return this.$api3(request);
    },
    patchInvitation: async function(bvModalEvt) {
      const changes = this.diffObjects(this.invitationForm, this.invitation);
      
      const request = {
        url: `/invitations/${this.invitation.token}`,
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'patch',
        data: changes,
      }

      try {
        // Execute request
				await this.$api3(request);

        // Tell the parent component to reload
        this.$emit('reload');
      } catch (error) {
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      } finally {
        // Either way, close the model
        bvModalEvt.vueTarget.hide();
      }
    },
    diffObjects: function(edited, original) {
			// Determines if any of the values in edited differ from those in original
			const changes = {};

			// If there aren't two objects, return the edited object in full
			if (typeof (edited) !== 'object' || typeof (original) !== 'object') return changes;

			for (let i in edited) {
        if (edited[i] == original[i]) continue;
        
        // Set the changed value, converting blank strings to null
				changes[i] = edited[i] === "" ? null : edited[i];
			}

			return changes;
		},
    resetModal() {
      this.invitationForm = {};
      this.$emit('hidden');
    }
  },
  watch: {
    invitation: function(newValue) {
      this.invitationForm = { ...newValue };
    }
  },
}
</script>

<style>

</style>