<template>
	<div v-if="profile.id">
		<b-row class="mb-3">
			<b-col cols="10">
				<h2>{{ badgeNameFull }}
					<small class="text-muted"> Profile {{ profile.id }}</small>
				</h2>
			</b-col>
			<b-col cols="2">
				<div class="text-right">
          <b-button :href="`/accounts/${profile.account.token}`" v-b-tooltip.hover title="View Account">
            <b-iconstack>
              <b-icon stacked icon="folder-fill"></b-icon>
              <b-icon stacked icon="person-fill" scale="0.55" shift-v="-0.4" variant="secondary"></b-icon>
            </b-iconstack>
          </b-button>
				</div>
			</b-col>
		</b-row>
		<b-card-group deck>
			<b-card>
				<template v-slot:header>
					<h6 class="mb-0">
						Contact
						<b-icon-pencil-square class="float-right" style="cursor: pointer" v-b-modal.contact></b-icon-pencil-square>
					</h6>
				</template>
				<dl>
					<dt>Legal Name</dt>
					<dd>{{ nameFull }}</dd>
					<dt>Badge Name</dt>
					<dd>{{ badgeNameFull }}</dd>
          <template v-if="profile.email">
					<dt>Email</dt>
					<dd><a v-bind:href="'mailto:' + profile.email">{{ profile.email }}</a></dd>
          </template>
          <template v-if="profile.phoneNumber">
					<dt>Phone</dt>
					<dd>{{ profile.phoneNumber.nationalFormat || profile.phoneNumber.phoneNumber }}<template v-if="profile.phoneNumber.carrierType"> ({{ profile.phoneNumber.carrierType }})</template></dd>
          </template>
          <template v-if="profile.address">
          <dt>Address</dt>
					<dd>{{ profile.address.primaryLine}}<br />
						<template v-if="profile.address.secondaryLine">{{ profile.address.secondaryLine }}<br /></template>
						{{ profile.address.lastLine }}<br />
						{{ profile.address.countryCode }}
					</dd>
          </template>
				</dl>
			</b-card>
			<b-card>
				<template v-slot:header>
					<h6 class="mb-0">
						Demographics
						<b-icon-pencil-square class="float-right" style="cursor: pointer" v-b-modal.demographics></b-icon-pencil-square>
					</h6>
				</template>
				<dl>
          <template v-if="profile.birthday">
					<dt>Birthdate</dt>
					<dd>{{ $dateHelper(profile.birthday, '$date') }} (Age {{ differenceInYears(new Date(), new Date(profile.birthday))}})</dd>
          </template>
					<dt v-if="profile.usMensShirtSize">US Mens Shirt Size</dt>
					<dd>{{ profile.usMensShirtSize }}</dd>
					<dt v-if="profile.accomodations">Special Accomodations</dt>
					<dd>{{ profile.accomodations }}</dd>
					<template v-if="profile.id !== profile.account.primaryProfileId">
          <dt>Primary</dt>
					<dd><b-link :href="`/accounts/${profile.account.token}`">{{ profile.account.primaryProfile.badgeNameFull }}</b-link></dd>
          </template>

          <dt>Previous</dt>
					<dd>{{ alumniString }}</dd>

					<dt>Tags</dt>
					<dd class="h5">
						<span v-for="tag in profile.tags" :key="tag.id">
							<b-badge variant="primary" class="mr-1" :id="'tab-button-'+tag.id" :to="'/profiles?tag='+tag.slug">{{ tag.name }}</b-badge>
							<b-tooltip :target="'tab-button-'+tag.id" :delay="{ show: 1000, hide: 0 }" variant="danger">
								<b-icon icon="trash" v-on:click="untagProfile(tag.id)"></b-icon>
							</b-tooltip>
						</span>
						<b-badge variant="secondary" v-b-modal.profile-tag href="#">+</b-badge>
					</dd>
				</dl>
			</b-card>
			<b-card>
				<template v-slot:header>
					<h6 class="mb-0">
						Registrations
					</h6>
				</template>
				<dl>
          <template v-if="profile.registrations">
          <template v-for="registration in sortedRegistrations">
					<dt :key="`dt-${registration.id}`">{{ registration.group.event.name }}</dt>
					<dd :key="`dd-status-${registration.id}`">
            <b-badge :to="`/registrations/${registration.token}`" :variant="registration.active ? 'success' : 'danger'">
              {{ registration.active ? 'Active' : 'Inactive' }}
            </b-badge>
					</dd>
          <dd :key="`dd-group-${registration.id}`">
            <b-link :to="'/groups/' + registration.group.token"> {{ registration.group.account.primaryProfile.badgeNameFull }} Group</b-link>
          </dd>
          </template>
          </template>
					<dt v-else>No Registrations</dt>
				</dl>
			</b-card>
		</b-card-group>

		<b-modal id="profile-tag"
			v-on:show="loadProfileTags"
			v-on:ok="tagProfile"
			v-on:close="resetTag"
			size="sm"
			title="Tag Profile"
			ok-title="Tag"
		>
			<b-form-select v-model="tagId" :options="tags" value-field="id" text-field="name">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled />
				</template>
			</b-form-select>
		</b-modal>

		<validation-observer v-slot="{invalid, pristine}">
			<b-modal id="contact"
				centered
				v-on:show="loadContact"
				v-on:hidden="cleanUpModal"
				v-on:ok="saveProfile"
				title="Contact"
				ok-title="Save"
				:ok-disabled="invalid || pristine"
			>
				<validation-provider rules="required" v-slot="v">
					<b-form-group
						id="input-group-modal-name-first"
						label="First Name"
						label-for="input-modal-name-first"
						description="As it appears on their photo id"
					>
						<b-form-input id="input-modal-name-first" v-model="modal.profile.nameFirst" :state="v.errors.length > 0 ? false : null"></b-form-input>
						<b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>
				<b-form-group
						id="input-group-modal-name-last"
						label="Last Name"
						label-for="input-modal-name-last"
				>
					<b-form-input id="input-modal-name-last" v-model="modal.profile.nameLast"></b-form-input>
				</b-form-group>

				<validation-provider rules="required" v-slot="v">
					<b-form-group
						id="input-group-modal-badge-name-first"
						label="Badge First Name"
						label-for="input-modal-badge-name-first"
						description="As it should appear on their badge"
					>
						<b-form-input id="input-modal-badge-name-first" v-model="modal.profile.badgeNameFirst" :state="v.errors.length > 0 ? false : null"></b-form-input>
						<b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>
				<b-form-group
						id="input-group-modal-badge-name-last"
						label="Badge Last Name"
						label-for="input-modal-bdage-name-last"
				>
					<b-form-input id="input-modal-badge-name-last" v-model="modal.profile.badgeNameLast"></b-form-input>
				</b-form-group>

				<validation-provider rules="email" v-slot="v">
					<b-form-group
						id="input-group-email"
						label="Email"
						label-for="input-email">
						<b-form-input type="email" id="input-email" v-model="modal.profile.email" :state="v.errors.length > 0 ? false : null"></b-form-input>
						<b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>

				<validation-provider :rules="phoneNumberRules" v-slot="v">
					<b-form-group
						id="input-group-phone"
						:label="phoneNumberLabel"
						label-for="input-phone">
						<b-form-select
							id="input-phone"
							v-model="modal.profile.phoneNumberId"
							v-on:change="overridePhoneNumberRules = false; if (modal.profile.phoneNumberId === null) $bvModal.show('phone-number')"
							:state="v.errors.length > 0 ? false : null"
						>
							<option :value="null">Add a phone number</option>
							<option v-for="phoneNumber in modal.phoneNumbers" v-bind:key="phoneNumber.id" :value="phoneNumber.id">{{ phoneNumber.nationalFormat }}</option>
						</b-form-select>
            <b-form-text :text-variant="v.errors[0] ? 'danger' : 'secondary'" v-show="v.errors[0] || overridePhoneNumberRules">
              Phone Number is usually required.
              <b-form-checkbox inline class="float-right" v-model="overridePhoneNumberRules" @change="(checked) => { if (!checked) $bvModal.show('phone-number') }">Override</b-form-checkbox>
            </b-form-text>
					</b-form-group>
				</validation-provider>

				<validation-provider :rules="addressRules" v-slot="v">
					<b-form-group
						id="input-group-address"
						:label="addressLabel"
						label-for="input-address">
						<b-form-select
							id="input-address"
							v-model="modal.profile.addressId"
							v-on:change="overrideAddressRules = false; if (modal.profile.addressId === null) $bvModal.show('address-modal')"
							:state="v.errors.length > 0 ? false : null"
						>
							<option :value="null">Add an address</option>
							<option v-for="address in modal.addresses" v-bind:key="address.id" :value="address.id">{{ address.primaryLine }} {{ address.secondaryLine }} {{ address.lastLine }}</option>
						</b-form-select>
            <b-form-text :text-variant="v.errors[0] ? 'danger' : 'secondary'" v-show="v.errors[0] || overrideAddressRules">
              Address is usually required.
              <b-form-checkbox inline class="float-right" v-model="overrideAddressRules" @change="(checked) => { if (!checked) $bvModal.show('address-modal') }">Override</b-form-checkbox>
            </b-form-text>
					</b-form-group>
				</validation-provider>
			</b-modal>
		</validation-observer>

		<!-- new phone number model -->
		<validation-observer v-slot="{invalid, pristine}">
			<b-modal
				id="phone-number"
				centered
				hide-header
				v-on:close="modal.profile.phoneNumberId = ''"
				v-on:ok.prevent="addPhoneNumber"
				:ok-disabled="invalid || pristine"
			>
				<validation-provider rules="required" v-slot="v">
					<b-form-group
						id="input-group-modal-phone"
						label="Phone"
						label-for="input-modal-phone"
						description="Including country code">
						<b-form-input type="tel" id="input-modal-phone" v-model="modal.newObject.phoneNumber" :state="v.errors.length > 0 ? false : null"></b-form-input>
						<b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>
				<b-form-text text-variant="danger" v-if="modal.newObject.error">{{ modal.newObject.error }}</b-form-text>
			</b-modal>
		</validation-observer>

		<!-- new address modal -->
		<validation-observer v-slot="{invalid, pristine}">
			<b-modal
				id="address-modal"
				centered
				hide-header
				v-on:close="modal.profile.addressId = ''"
				v-on:ok="addAddress"
				:ok-disabled="invalid || pristine"
			>

				<validation-provider rules="required" v-slot="v">
					<b-form-group
						id="input-group-modal-street-address-1"
						label="Street Address"
						label-for="input-modal-street-address-1"
						description="Line 1">
						<b-form-input id="input-modal-street-address-1" v-model="modal.newObject.streetAddress1" :state="v.errors.length > 0 ? false : null"></b-form-input>
						<b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>
				<b-form-group
					id="input-group-modal-street-address-2"
					description="Line 2">
					<b-form-input id="input-modal-street-address-2" v-model="modal.newObject.streetAddress2"></b-form-input>
				</b-form-group>
				<validation-provider rules="required" v-slot="v">
					<b-form-group
						id="input-group-modal-city"
						label="City"
						label-for="input-modal-city">
						<b-form-input id="input-modal-city" v-model="modal.newObject.city" :state="v.errors.length > 0 ? false : null"></b-form-input>
						<b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>
				<b-form-group
					id="input-group-modal-state"
					label="State or Province"
					label-for="input-modal-state">
					<b-form-input id="input-modal-state" v-model="modal.newObject.state"></b-form-input>
				</b-form-group>
				<b-form-group
					id="input-group-modal-postal-code"
					label="Postal Code"
					label-for="input-modal-state">
					<b-form-input id="input-modal-postal-code" v-model="modal.newObject.postal_code"></b-form-input>
				</b-form-group>
				<validation-provider rules="required" v-slot="v">
					<b-form-group
						id="input-group-modal-country"
						label="Country"
						label-for="input-modal-country">
						<b-form-select value-field="abbreviation" text-field="country" v-model="modal.newObject.country" :options="countries" :state="v.errors.length > 0 ? false : null"></b-form-select>
						<b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>
				<b-form-text text-variant="danger" v-if="modal.newObject.error">{{ modal.newObject.error }}</b-form-text>
			</b-modal>
		</validation-observer>

		<!-- demographics modal -->
		<validation-observer v-slot="{invalid, pristine}">
			<b-modal id="demographics"
				centered
				v-on:show="loadDemographics"
				v-on:hidden="cleanUpModal"
				v-on:ok="saveProfile"
				title="Demographics"
				ok-title="Save"
				:ok-disabled="invalid || pristine"
			>
				<b-form v-on:submit.prevent="saveProfile">
					<validation-provider :rules="birthdayRules" v-slot="v">
						<b-form-group id="input-group-birthday" :label="birthdayLabel" label-for="input-birthday" description="yyyy-mm-dd">
							<b-form-input id="input-birthday" v-model="modal.profile.birthday" :state="v.errors.length > 0 ? false : null" placeholder="yyyy-mm-dd"></b-form-input>
							<b-form-text :text-variant="v.errors[0] ? 'danger' : 'secondary'" v-if="v.errors[0] || overrideBirthdayRules">
                Date of Birth is usually required.
                <b-form-checkbox class="float-right" inline v-model="overrideBirthdayRules">Override</b-form-checkbox>
              </b-form-text>
						</b-form-group>
					</validation-provider>
					<validation-provider>
						<b-form-group id="input-group-shirt-size" label="Shirt Size" label-for="input-address" description="US Mens shirt size">
							<b-form-select id="input-shirt-size" v-model="modal.profile.usMensShirtSize" :options="shirtSizes" value-field="id" text-field="primary_line"></b-form-select>
						</b-form-group>
					</validation-provider>
					<b-form-group
						id="input-group-past-lives"
					>
						<b-row class="mr-0">
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2023" value="1" unchecked-value="0">2023</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2022" value="1" unchecked-value="0">2022</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2019" value="1" unchecked-value="0">2019</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2018" value="1" unchecked-value="0">2018</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2017" value="1" unchecked-value="0">2017</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2016" value="1" unchecked-value="0">2016</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2015" value="1" unchecked-value="0">2015</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2013" value="1" unchecked-value="0">2013</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2011" value="1" unchecked-value="0">2011</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2009" value="1" unchecked-value="0">2009</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2007" value="1" unchecked-value="0">2007</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2004" value="1" unchecked-value="0">2004</b-form-checkbox>
								</validation-provider>
							</b-col>
							<b-col sm="2">
								<validation-provider>
									<b-form-checkbox v-model="modal.profile.alumni2001" value="1" unchecked-value="0">2001</b-form-checkbox>
								</validation-provider>
							</b-col>
						</b-row>
						<template v-slot:label="">
							<label>Which <em>MAGIC Lives</em> have they attended?</label>
						</template>
					</b-form-group>
					<validation-provider>
						<b-form-group id="input-group-birthday" label="Special Accomodations" label-for="input-birthday" description="Wheelchair, hearing impairment, etc.">
							<b-form-input id="input-accomodations" v-model="modal.profile.accomodations"></b-form-input>
						</b-form-group>
					</validation-provider>
				</b-form>
			</b-modal>
		</validation-observer>
	</div>
</template>

<script>
import _ from 'lodash';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import countries from '../../assets/countries.json';
import { differenceInYears } from 'date-fns';

extend('email', {
	...email,
	message: 'Must be a valid email'
})
extend('required', {
	...required,
	message: 'This field is required'
})

export default {
	name: 'Profile',
	components: {
		ValidationProvider, ValidationObserver
	},
	props: {
		globals: Object,
	},
	data: function() {
		return {
			countries: countries,
			modal: {
				addresses: [],
				newObject: {},
				phoneNumbers: [],
				profile: {},
				registration: [],
			},
			profile: {},
			shirtSizes: [
				"XS",
				"S",
				"M",
				"L",
				"XL",
				"2XL",
				"3XL",
				"4XL"
			],
			tags: [],
      tagId: null,
      overrideBirthdayRules: false,
      overrideAddressRules: false,
      overridePhoneNumberRules: false,
		}
	},
	computed: {
		alumniString() {
			const alumniObject = {
				2001: this.profile.alumni2001,
				2004: this.profile.alumni2004,
				2007: this.profile.alumni2007,
				2009: this.profile.alumni2009,
				2011: this.profile.alumni2011,
				2013: this.profile.alumni2013,
				2015: this.profile.alumni2015,
				2016: this.profile.alumni2016,
				2017: this.profile.alumni2017,
				2018: this.profile.alumni2018,
				2019: this.profile.alumni2019,
				2022: this.profile.alumni2022,
				2023: this.profile.alumni2023,
			};

			const alumniArray = [];

			for (const year in alumniObject) {
				if(alumniObject[year] && alumniObject[year] != "0") {
					alumniArray.push(year);
				}
			}

			return alumniArray.length ? alumniArray.join(', ') : 'None';
		},
		nameFull() {
			return _.join([this.profile.nameFirst, this.profile.nameLast], ' ').trim();
		},
		badgeNameFull() {
			return _.join([this.profile.badgeNameFirst, this.profile.badgeNameLast], ' ').trim();
    },
    birthdayRules() {
      return this.overrideBirthdayRules ? '' : 'required';
    },
    birthdayLabel() {
      return this.overrideBirthdayRules ? 'Date of Birth' : 'Date of Birth*';
    },
    addressRules() {
      return this.overrideAddressRules ? '' : 'required';
    },
    addressLabel() {
      return this.overrideAddressRules ? 'Address' : 'Address*';
    },
    phoneNumberRules() {
      return this.overridePhoneNumberRules ? '' : 'required';
    },
    phoneNumberLabel() {
      return this.overridePhoneNumberRules ? 'Phone Number' : 'Phone Number*';
    },
    sortedRegistrations() {
      return _.orderBy(this.profile.registrations, 'group.event.id', 'desc');
    },
    
	},
	methods: {
		tagProfile: async function(bvModalEvt) {
			bvModalEvt.preventDefault();

			// Construct request
			const request = {
				url: '/profile-tags-profiles',
				method: 'POST',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        data: {
          profileId: this.profile.id,
          profileTagId: this.tagId
        }
			};

			// Execute request
			await this.$api3(request);

			// Add tag to profile and close modal
			this.profile.tags.push(_.find(this.tags, { 'id': this.tagId }))

			bvModalEvt.vueTarget.hide();
			
		},
		untagProfile: async function(tagId) {
			// Construct request
			const request = {
        url: `/profile-tags-profiles/${tagId},${this.profile.id}`,
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'delete',
      };

			// Execute request
			await this.$api3(request);

			// Remove tag from profile
			this.profile.tags.splice(_.findIndex(this.profile.tags, { 'id': tagId }), 1);
		},
		resetTag: function() {
			this.tags = [];
			this.tagId = null;
		},
		loadProfileTags: async function(bvModalEvt) {
			bvModalEvt.preventDefault();

			// Get profile tags
			try {
				// Construct request
				const profileRequest = {
					url: '/profile-tags',
					method: 'get',
					headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				}; 
				
				// Execute request
				const response = await this.$api3(profileRequest);
				
				// Filter tags and update data
				const usedTags = _.map(this.profile.tags, 'id');
				this.tags = _.filter(response.data.data, function(obj) {
					return _.indexOf(usedTags, obj.id) < 0;
				})

				// Show the modal
				bvModalEvt.vueTarget.doShow();
			} catch (error) {
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
				bvModalEvt.vueTarget.hide();
			}
		},
		loadContact: async function(bvModalEvt) {
			bvModalEvt.preventDefault();
			
			// Copy values from this.profile
			const keysToKeep = ['nameFirst', 'nameLast', 'badgeNameFirst', 'badgeNameLast', 'email', 'phoneNumberId', 'addressId'];
			this.modal.profile = this.copyFilteredObject(this.profile, keysToKeep);

      // Toggle override if already set
      this.overrideAddressRules = this.profile.address === null;
      this.overridePhoneNumberRules = this.profile.phoneNumber === null;

      // Get addresses
      const addressRequest = {
        url: '/addresses',
        method: 'get',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        params: {
          accountId: this.profile.account.id
        }
      };

      const addressResponse = await this.$api3(addressRequest).catch(error => {
				let message;

				if (error.response) {
					// Server responded
					console.log(error.response);
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					console.log(error);
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
				bvModalEvt.vueTarget.hide();
      });
      this.modal.addresses = addressResponse.data.data;

      const phoneNumberRequest = {
        url: '/phone-numbers',
        method: 'get',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        params: {
          accountId: this.profile.account.id
        }
      };

      // Get phone numbers
      const phoneNumberResponse = await this.$api3(phoneNumberRequest).catch(error => {
				let message;

				if (error.response) {
					// Server responded
					console.log(error.response);
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					console.log(error);
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
				bvModalEvt.vueTarget.hide();
      })
      this.modal.phoneNumbers = phoneNumberResponse.data.data;

      // Show the modal
      bvModalEvt.vueTarget.doShow();
		},		
		loadDemographics: function() {
			// bvModalEvt.preventDefault();
			
			// Copy values from this.profile
			const keysToKeep = ['birthday', 'usMensShirtSize', 'accomodations', 'alumni2001', 'alumni2004', 'alumni2007', 'alumni2009', 'alumni2011', 'alumni2013', 'alumni2015', 'alumni2016', 'alumni2017', 'alumni2018', 'alumni2019', 'alumni2022', 'alumni2023'];
      this.modal.profile = this.copyFilteredObject(this.profile, keysToKeep);

      // Toggle override if already set
      if (this.profile.birthday === null) this.overrideBirthdayRules = true;

			// Show the modal
			// bvModalEvt.vueTarget.doShow();
		},
		addPhoneNumber: async function(bvModalEvt) {
			this.modal.newObject.error = '';

			// Construct request
			const request = {
				url: '/phone-numbers',
				method: 'post',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data: {
					accountId: this.profile.accountId,
					phoneNumber: this.modal.newObject.phoneNumber.replace(/\D/g, "")
				}
			};
			
			try {
				// Execute request
				const response = await this.$api3(request);
				
				// Update data
				this.modal.phoneNumbers.push(response.data);
				this.modal.profile.phoneNumberId = response.data.id;
				
				// Show the modal
				bvModalEvt.vueTarget.hide();
			} catch(error) {
				this.modal.newObject.error = error;
			}
		},
		addAddress: async function(bvModalEvt) {
			this.modal.newObject.error = '';

			// Construct request
			const request = {
				url: '/addresses',
				method: 'POST',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data: {
					accountId: this.profile.accountId,
					addressLine1: this.modal.newObject.streetAddress1,
					addressLine2: this.modal.newObject.streetAddress2,
					city: this.modal.newObject.city,
					state: this.modal.newObject.state,
					postalCode: this.modal.newObject.postal,
					country: this.modal.newObject.country
				}
			};
			
			try {
				// Execute request
				const response = await this.$api3(request);
				
				// Update data
				this.modal.addresses.push(response.data);
				this.modal.profile.addressId = response.data.id;
				
				// Show the modal
				bvModalEvt.vueTarget.hide();
			} catch(error) {
				this.modal.newObject.error = error;
			}
		},
		saveProfile: async function(bvModalEvt) {
      // Don't close the modal automatically
      bvModalEvt.preventDefault();

			// Find changes
			const changes = this.diffObjects(this.modal.profile, this.profile);

			// If there are no changes, emit an alert
			if(Object.keys(changes).length === 0) {
				this.$emit('alert', { error: true, message: 'The profile could not be saved. There were no changes.' });
        bvModalEvt.vueTarget.hide();
				return;
			}
			
      // There are changes, proceed
      // Construct request
      const request = {
        url: `/profiles/${this.profile.id}`,
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'patch',
        data: changes,
      }

      // Execute request
      await this.$api3(request).then(() => {
        // Reload
        this.$emit('status', false);
        this.loadHandler();

        // Emit a success alert and close the modal
        this.$emit('alert', { message: `Profile updated.` });
      }).catch((error) => {
				let message = 'Could not update Profile.';

				if (error.response) {
					// Server responded
					message += ' Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message += ' Request Error: ' + error;
				}

				// Emit error as alert
				this.$emit('alert', { error: true, message: message });
        bvModalEvt.vueTarget.hide();
      }).finally(() => {
        // Close the modal
        bvModalEvt.vueTarget.hide();
      })
		},
		cleanUpModal: function() {
			// Reset reactive components
			this.modal = {
				addresses: [],
				newObject: {},
				phoneNumbers: [],
				profile: {},
				registration: [],
			}
		},
		copyFilteredObject: function(object, keysToKeep) {
			const target = {};
			for (let i in object) {
				if (keysToKeep.indexOf(i) < 0) continue;
				if (!Object.hasOwnProperty.call(object, i)) continue;
				target[i] = object[i];
			}
			return target;
		},
		diffObjects: function(edited, original) {
			// Determines if any of the values in edited differ from those in original
			const changes = {};

			// If there aren't two objects, return the edited object in full
			if (typeof (edited) !== 'object' || typeof (original) !== 'object') return changes;

			for (let i in edited) {
        if (edited[i] == original[i]) continue;
        
        // Set the changed value, converting blank strings to null
				changes[i] = edited[i] === "" ? null : edited[i];
			}

			return changes;
		},
    loadHandler: async function() {
      // Construct request
      const request = {
        url: `/profiles/${this.$route.params.id}`,
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        params: {
          $eager: '[account.primaryProfile, address, phoneNumber, registrations.[group.[account.primaryProfile, event]], tags]'
        }
      }

      // Execute request
      const result = await this.$api3(request);

      // Update relevant data
      this.profile = result.data;
      
      // Emit events
      this.$emit('status', true);
    },
    differenceInYears
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: async function() {
    this.loadHandler();
	}
};
</script>

<style>
</style>
