<template>
	<div>
		<h2>Toolbox</h2>
    <AccountMerge :globals="globals" />
  </div>
</template>

<script>
import AccountMerge from '../tools/AccountMerge.vue';

export default {
	name: 'Shows',
  components: {
    AccountMerge
},
	props: {
		globals: Object,
	},
  created: async function () {
    this.$emit('status', true);
  }
};
</script>

<style>
</style>
