<template>
  <b-modal id="add-registration"
    size="sm"
    title="Add A Registration"
    @show="findProfiles"
    @ok="addRegistration"
    @hidden="resetModal"
    :ok-disabled="typeof newRegistration.profile === 'undefined'"
    :busy="busy"
  >
    <b-overlay :show="busy">
      <b-form v-on:submit.prevent="addRegistration">
        <b-form-group label="Profile" label-for="add-registration-profile">
          <b-form-select
            id="add-registration-profile"
            v-model="newRegistration.profile"
          >
            <template v-slot:first>
              <b-form-select-option value="" disabled />
            </template>
            <b-form-select-option v-for="profile in availableProfiles" v-bind:key="profile.id" :value="profile">{{ profile.nameFull }} (via {{ profile.account.primaryProfile.badgeNameFull }})</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group id="add-registration-cost-group" label="Cost" label-for="add-registration-cost" description="A transaction will be created if the cost is negative.">
          <b-form-input type="number" id="add-registration-cost" v-model.number="newRegistration.cost" />
        </b-form-group>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  name: 'AddRegistrationModal',
  data: function() {
    return {
      availableProfiles: [], 
      newRegistration: {
        cost: 0
      },
      busy: true,
    }
  },
  props: {
    globals: Object,
    group: Object,
  },
  methods: {
    findProfiles: async function (bvModalEvt) {

      let accountIds = [];
      let profileIds = [];

      for (const registration of this.group.registrations) {
        if (!accountIds.includes(registration.profile.account.id)) accountIds.push(registration.profile.account.id);
        if (!profileIds.includes(registration.profile.id)) profileIds.push(registration.profile.id);
      }

      // Get all available tags for the given event and category 2
      const request = {
        url: '/profiles',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          'accountId[$in]': accountIds,
          'id[$nin]': profileIds,
          '$sort[badgeNameFirst]': 1,
          '$sort[badgeNameLast]': 1,
          $eager: 'account.primaryProfile',
          $limit: 100,
        }
      }

      this.$api3(request).catch((error) => {
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

        console.log({ error });

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      }).then((response) => {
        if (response.data.total === 0) {
          this.$emit('alert', { error: true, message: 'All of the Profiles related to this Group already have Registrations.'});
          bvModalEvt.vueTarget.hide();
          return;
        }
        this.availableProfiles = response.data.data;
        this.busy = false;
      });
    },
    addRegistration: async function(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.busy = true;

      // Assemble the registration request
      const regRequest = {
        url: '/registrations',
        method: 'POST',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        data: {
          groupId: this.group.id,
          profileId: this.newRegistration.profile.id,
          active: 1,
        }
      }

      let txResponse;

      // If the cost is a negative number, create a transaction first
      if (this.newRegistration.cost < 0) {
        const txRequest = {
          url: '/transactions',
          method: 'POST',
          headers: { Authorization: 'bearer ' + this.globals.accessToken},
          data: {
            accountId: this.newRegistration.profile.account.id, // Ties the tx to whoever owns this profile
            eventId: this.globals.event.id,
            amount: this.newRegistration.cost,
            transactionTypeId: 10,
            detail: `Registration for ${this.newRegistration.profile.badgeNameFull}`,
          }
        }

        txResponse = await this.$api3(txRequest).catch(error => {
          let message;

          if (error.response) {
            // Server responded
            if(error.response.status === 401) {
              this.$emit('logout');
            }

            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error
          this.$emit('alert', { error: true, message: message });
        })

        regRequest.data.transactionId = txResponse.data.id;
      }

      if (this.newRegistration.cost >= 0 || txResponse.status === 201) { 

        this.$api3(regRequest).catch(error => {
          let message;

          if (error.response) {
            // Server responded
            if(error.response.status === 401) {
              this.$emit('logout');
            }

            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error
          this.$emit('alert', { error: true, message: message });
        }).then(() => {
          this.$emit('reload');
          bvModalEvt.vueTarget.hide();
        })
      }
    },
    resetModal() {
      this.availableProfiles = [];
      this.newRegistration = {
        cost: 0
      };
      this.busy = true;
    }
  },
}
</script>

<style>

</style>