<template>
  <validation-observer v-slot="{invalid, pristine}">
    <b-modal id="group-notes-form"
      v-on:show="copy"
      v-on:ok="save"
      v-on:hidden="reset"
      size="md"
      title="Edit Notes"
      :ok-disabled="invalid || pristine"
    >
      <validation-provider>
        <b-form-textarea autofocus id="group-notes" v-model="notes"></b-form-textarea>
      </validation-provider>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  name: 'GroupNotesformModal',
  components: {
    ValidationProvider, ValidationObserver
  },
  data: function() {
    return {
      notes: '',
      groupId: this.$route.params.id,
    }
  },
  props: {
    globals: Object,
    value: String,
  },
  methods: {
    copy() {
      this.notes = this.value;
    },
    save: async function(bvModalEvt) {
      const request = {
        url: `/groups/${this.groupId}`,
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'patch',
        data: { notes: this.notes },
      }

      try {
        // Execute request
				const result = await this.$api3(request).catch((error) => {
          let message;

          if (error.response) {
            // Server responded
            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error
          this.$emit('alert', { error: true, message: message });
        });

        // Update relevant data
        this.$emit('input', result.data.notes);
      } catch (error) {
        let message;

        if (error.response) {
          // Server responded
          message = 'Server Error: ' + error.response.data.message;
        } else {
          // No response or Error in request
          message = 'Axios: ' + error;
        }

        // Emit the error
        this.$emit('alert', { error: true, message: message });
      } finally {
        // Either way, close the model
        bvModalEvt.vueTarget.hide();
      }
    },
    reset() {
      this.hotelReservationForm = {};
    },
    diffObjects: function(edited, original) {
			// Determines if any of the values in edited differ from those in original
			const changes = {};

			// If there aren't two objects, return the edited object in full
			if (typeof (edited) !== 'object' || typeof (original) !== 'object') return changes;

			for (let i in edited) {
        if (edited[i] == original[i]) continue;
        
        // Set the changed value, converting blank strings to null
				changes[i] = edited[i] === "" ? null : edited[i];
			}

			return changes;
		}
  },
}
</script>

<style>

</style>