<template>
	<div v-if="group.id">
		<b-row class="mb-3">
			<b-col cols="8">
				<h2>{{ group.account.primaryProfile.badgeNameFull }} Group
					<small class="text-muted"> ID {{ group.id }}</small>
				</h2>
				<p>{{ group.event.name }}
          <template v-if="group.purchased"> | Purchased on {{ $dateHelper(group.purchased) }}</template>
          <template v-if="!group.purchased"> | Created but not purchased on {{ $dateHelper(group.created) }}</template>
					<template v-if="group.priority && group.purchased != group.priority"><br />Priority set to {{ $dateHelper(group.priority) }}</template>
				</p>
			</b-col>
			<b-col cols="4">
				<div class="text-right" v-if="group.event.id === globals.event.id">
          <print-button class="mr-2" :globals="globals" :registrations="group.registrationsActive" @alert="(event) => { this.$emit('alert', event); }" v-b-tooltip.hover title="Print Registrations" />
          <b-dropdown variant="secondary"  class="mr-2" right v-b-tooltip.hover title="Send Emails">
            <template #button-content>
              <b-icon icon="envelope-fill"></b-icon>
            </template>
            <b-dropdown-group header="Automatic">
              <b-dropdown-item @click="resendConfirmation">Group Confirmation</b-dropdown-item>
              <b-dropdown-item @click="resendSchedule">Schedule Confirmation</b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-divider />
            <b-dropdown-group header="Manual">
              <b-dropdown-item v-b-modal.plain-text-schedule>Schedule Confirmation</b-dropdown-item>
              <b-dropdown-item v-b-modal.plain-text-transfer>Transfer Confirmation</b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
          <b-button-group>
            <b-button variant="primary" v-b-modal.change-priority v-b-tooltip.hover title="Change Group Priority">
              <b-icon icon="calendar-plus-fill"></b-icon>
            </b-button>
            <b-button variant="danger" v-b-modal.move-group-to-account v-b-tooltip.hover title="Move Group to Account">
              <b-iconstack>
                <b-icon stacked icon="folder-fill" variant="white"></b-icon>
                <b-icon stacked icon="person-fill" scale="0.55" shift-v="-0.4" variant="danger"></b-icon>
                <b-icon stacked icon="caret-up-fill" variant="danger" shift-v="-4"></b-icon>
                <b-icon stacked icon="caret-up-fill" shift-v="-6"></b-icon>
              </b-iconstack>
            </b-button>
            <b-button variant="danger" v-b-modal.transfer v-b-tooltip.hover title="Transfer Registrations">
              <b-iconstack>
                <b-icon stacked icon="people-fill" shift-v="2"></b-icon>
                <b-icon stacked icon="caret-up-fill" variant="danger" shift-v="-4"></b-icon>
                <b-icon stacked icon="caret-up-fill" shift-v="-6"></b-icon>
              </b-iconstack>
            </b-button>
          </b-button-group>
				</div>
			</b-col>
		</b-row>

		<h4 class="mb-3">
      Registrations
      <b-button v-if="group.event.id === globals.event.id" v-b-modal.add-registration variant="link" v-b-tooltip.hover title="Add Registration"><b-icon-plus-circle variant="dark"></b-icon-plus-circle></b-button>
    </h4>
    <registration-list :group="group" />

    <h4 class="mt-4">
      Invitations
      <b-button v-if="group.event.id === globals.event.id" @click="openInvitationModal()" variant="link" v-b-tooltip.hover title="Add Invitation"><b-icon-plus-circle variant="dark"></b-icon-plus-circle></b-button>
    </h4>
    <template v-if="group.invitations.length">
    <b-row>
      <b-col cols="2">
        <strong>Name</strong>
      </b-col>
      <b-col cols="4">
        <strong>Email</strong>
      </b-col>
      <b-col cols="2">
        <strong>Expiration</strong>
      </b-col>
      <b-col cols="2">
        <strong>Responded</strong>
      </b-col>
    </b-row>
    <b-row v-for="invitation in group.invitations" v-bind:key="invitation.id">
      <b-col cols="2">
        {{ invitation.nameFull }}
      </b-col>
      <b-col cols="4">
        {{ invitation.email }}
      </b-col>
      <b-col cols="2">
        {{ $dateHelper(invitation.expiration, '$date') }}
      </b-col>
      <b-col cols="2">
        <template v-if="invitation.responded">
          {{ $dateHelper(invitation.responded, '$date') }}
        </template>
      </b-col>
      <b-col cols="2" class="text-right" v-if="group.event.id === globals.event.id">
        <a :href="`https://app.magicconvention.com/register/invitation/${invitation.token}`" target="_blank" class="mr-2"><b-icon icon="check-square-fill" variant="dark" /></a>
        <a :href="`https://app.magicconvention.com/decline/${invitation.token}`" target="_blank"><b-icon icon="x-square-fill" variant="dark" /></a>
        <b-button @click="openInvitationModal(invitation)" class="mr-3" variant="link"><b-icon icon="pencil-square" variant="dark"></b-icon></b-button>
      </b-col>
    </b-row>
    </template>

    <h4 class="mt-4">
      Schedule <small v-if="group.schedulingSent">Sent on {{ $dateHelper(group.schedulingSent, '$date') }}</small><small v-if="group.schedulingReminderSent"> | Reminded on {{ $dateHelper(group.schedulingReminderSent, '$date') }}</small><small v-if="group.schedulingCompleted"> | Completed on {{ $dateHelper(group.schedulingCompleted, '$date') }}</small>
      <b-button v-if="group.event.id === globals.event.id" v-b-modal.add-ticket-modal variant="link" v-b-tooltip.hover title="Add Ticket"><b-icon-plus-circle variant="dark"></b-icon-plus-circle></b-button>
    </h4>
    <ticket-list :globals="globals" :registrations="group.registrationsActiveFull" :group="group" :editable="group.event.id === globals.event.id" :lg="4" @reload="loadHandler($route.params.id)" @alert="(event) => { this.$emit('alert', event); }" @status="(status) => { this.$emit('status', status);}" />
    <template v-if="oddSchedules.length > 0">
      <p><b-icon icon="exclamation-triangle-fill" class="mr-2" variant="warning" />The following Registrations have schedules that do not match the Primary Registration:</p>

      <div v-for="registration in oddSchedules" :key="`odd-${registration.id}`">
        <h5>
          {{ registration.profile.badgeNameFull }}
          <b-button v-if="group.event.id === globals.event.id" @click="replaceSchedule(registration)" variant="link" title="Replace Schedule"><b-icon icon="arrow-up-circle" variant="dark" /></b-button>
        </h5>
        <ticket-list :globals="globals" :registrations="[registration]" :lg="4" @reload="loadHandler($route.params.id)" />
      </div>
    </template>

    <h4 class="mt-3">
      Dealer Booth
      <template v-if="group.event.id === globals.event.id">
        <b-button v-if="group.dealerBooth" @click="$bvModal.show('edit-dealer-booth')" variant="link" v-b-tooltip.hover title="Edit Dealer Booth"><b-icon-pencil-square variant="dark"></b-icon-pencil-square></b-button>
        <b-button v-else v-b-modal.add-dealer-booth variant="link" v-b-tooltip.hover title="Add Dealer Booth"><b-icon-plus-circle variant="dark"></b-icon-plus-circle></b-button>
      </template>
    </h4>
    <template v-if="group.dealerBooth">
    <b-row>
      <b-col cols="3">
        <strong>Company</strong>
      </b-col>
      <b-col cols="4">
        <strong>Size</strong>
      </b-col>
      <b-col cols="2">
        <strong>Amount</strong>
      </b-col>
    </b-row>
    <b-row class="pb-3">
      <b-col cols="3">
        {{ group.dealerBooth.company }}
      </b-col>
      <b-col cols="4">
        {{ group.dealerBooth.size }}
      </b-col>
      <b-col cols="2">
        {{ dealerBoothAmount | currency }}
      </b-col>
    </b-row>
    </template>

    <h4 class="mt-4">
      Notes
      <b-button v-b-modal.group-notes-form variant="link" v-b-tooltip.hover title="Edit Notes"><b-icon-pencil-square variant="dark"></b-icon-pencil-square></b-button>
    </h4>
    <p>{{ group.notes }}</p>

		<b-modal id="change-priority"
			v-on:ok="changePriority"
			size="sm"
			title="Change Group Priority"
		>
			<b-form v-on:submit.prevent="changePriority">
				<b-form-group id="input-group-change-priority-date" aria-label="New Priority Date" label-for="input-change-priority-date">
					<b-form-datepicker id="input-change-priority-date" v-model="newPriorityDate" :reset-value="$dateHelper(group.priority || group.purchased, 'yyyy-MM-dd')" reset-button></b-form-datepicker>
				</b-form-group>
				<b-form-group id="input-group-change-priority-time" aria-label="New Priority Time" label-for="input-change-priority-time" description="At what date and time should they be put in order for seating priority?">
					<b-form-timepicker id="input-change-priority-time" v-model="newPriorityTime" :reset-value="$dateHelper(group.priority || group.purchased, 'HH:mm:ss')" reset-button></b-form-timepicker>
				</b-form-group>
			</b-form>
		</b-modal>

    <transfer-registrations-modal
      :globals="globals"
      :group="group"
      @reload="loadHandler($route.params.id)"
      @alert="(event) => { this.$emit('alert', event); }"
    />

    <add-registration-modal :globals="globals" :group="group" @reload="loadHandler($route.params.id)" @alert="params => { $emit('alert', params) }" />
    <b-modal
      id="plain-text-schedule"
      size="md"
      title="Resend Email Manually"
      @show="renderPlaintextScheduleEmail()"
      @hidden="plainTextSchedule = ''"
      ok-only
      ok-title="Close"
      ok-variant="secondary"
    >
      <b-textarea v-model="plainTextSchedule" rows="20" @focus="$event.target.select(); $event.target.scrollTo(0,0)" autofocus></b-textarea>
    </b-modal>

    <b-modal
      id="plain-text-transfer"
      size="md"
      title="Resend Email Manually"
      @show="renderPlaintextTransfer()"
      @hidden="plainTextTransfer = ''"
      ok-only
      ok-title="Close"
      ok-variant="secondary"
    >
      <b-textarea v-model="plainTextTransfer" rows="20" @focus="$event.target.select(); $event.target.scrollTo(0,0)" autofocus></b-textarea>
    </b-modal>

    <validation-observer v-slot="{invalid, pristine}">
      <b-modal id="edit-dealer-booth"
        v-on:show="loadDealerBooth"
        v-on:ok="saveDealerBooth"
        v-on:hidden="resetDealerBooth"
        size="sm"
        title="Edit Dealer Booth"
        :ok-disabled="invalid || pristine"
      >
        <validation-provider rules="required" v-slot="v">
          <b-form-group
            label="Company"
            label-for="edit-dealer-booth-company">
            <b-form-input type="text" id="edit-dealer-booth-company" v-model="modalDealerBooth.company" :state="v.errors.length > 0 ? false : null"></b-form-input>
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider rules="required" v-slot="v">
          <b-form-group
            label="Size"
            label-for="edit-dealer-booth-size">
            <b-form-select
              id="edit-dealer-booth-size"
              v-model="modalDealerBooth.size"
              :state="v.errors.length > 0 ? false : null"
              :options="dealerBoothSizes"
            ></b-form-select>
          </b-form-group>
        </validation-provider>

        <p><small>To change the Amount, <b-link :to="`/accounts/${group.account.token}`">go to the Account</b-link> and edit the Transaction.</small></p>
      </b-modal>
    </validation-observer>

    <validation-observer v-slot="{invalid, pristine}">
      <b-modal id="add-dealer-booth"
        v-on:ok="addDealerBooth"
        v-on:hidden="resetDealerBooth"
        size="sm"
        title="Add Dealer Booth"
        :ok-disabled="invalid || pristine"
      >
        <validation-provider rules="required" v-slot="v">
          <b-form-group
            label="Company"
            label-for="add-dealer-booth-company">
            <b-form-input type="text" id="add-dealer-booth-company" v-model="modalDealerBooth.company" :state="v.errors.length > 0 ? false : null"></b-form-input>
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider rules="required" v-slot="v">
          <b-form-group
            label="Size"
            label-for="add-dealer-booth-size">
            <b-form-select
              id="add-dealer-booth-size"
              v-model="modalDealerBooth.size"
              :state="v.errors.length > 0 ? false : null"
              :options="dealerBoothSizes"
            ></b-form-select>
          </b-form-group>
        </validation-provider>

        <validation-provider rules="required" v-slot="v">
          <b-form-group
            label="Amount"
            label-for="add-dealer-booth-amount"
            description="Should be negative.">
            <b-form-input type="number" id="add-dealer-booth-amount" v-model="modalDealerBooth.amount" :state="v.errors.length > 0 ? false : null"></b-form-input>
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-modal>
    </validation-observer>
    <group-notes-form-modal :globals="globals" v-model="group.notes" v-on:alert="(event) => { this.$emit('alert', event); }"></group-notes-form-modal>
    <invitation-form-modal :globals="globals" :invitation="currentInvitation" :groupId="this.group.id" v-on:reload="loadHandler($route.params.id)" @alert="(event) => { this.$emit('alert', event); }" @hidden="currentInvitation = {}" />
    <add-ticket-modal :globals="globals" :registrations="group.registrationsActiveFull" v-on:refresh="loadHandler($route.params.id)" />
    <move-group-to-account-modal :globals="globals" :group="group" @alert="(event) => { this.$emit('alert', event); }" @reload="loadHandler($route.params.id)" />
	</div>
</template>

<script>
import _ from 'lodash';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { plaintextTerms } from '../../common/js/terms';

import GroupNotesFormModal from '../modals/GroupNotesFormModal';
import InvitationFormModal from '../modals/InvitationFormModal';
import TicketList from '../TicketList';
import AddTicketModal from '../modals/AddTicketModal';
import PrintButton from '../PrintButton.vue';
import AddRegistrationModal from '../modals/AddRegistrationModal.vue';
import MoveGroupToAccountModal from '../modals/MoveGroupToAccountModal.vue';
import RegistrationList from '../RegistrationList.vue';
import TransferRegistrationsModal from '../modals/TransferRegistrationsModal.vue';

extend('required', {
	...required,
	message: 'This field is required'
})

export default {
	name: 'Group',
  components: {
    ValidationProvider,
    ValidationObserver,
    GroupNotesFormModal,
    InvitationFormModal,
    TicketList,
    AddTicketModal,
    PrintButton,
    AddRegistrationModal,
    MoveGroupToAccountModal,
    RegistrationList,
    TransferRegistrationsModal
},
	props: {
		globals: Object,
  },
	data: function() {
		return {
			group: {},
			newPriorityDate: null,
      newPriorityTime: null,
      newRegistration: {
        cost: 0
      },
      modalDealerBooth: {
        size: 'Single',
      },
      dealerBoothSizes: [
        'Single', 'Double', 'Special'
      ],
			transferGroupId: null,
			transferGroup: {},
      currentInvitation: undefined,
      plainTextSchedule: '',
      plainTextTransfer: '',
		}
	},
  computed: {
    dealerBoothAmount() {
      return typeof (this.group.dealerBooth.transaction) === 'undefined' || this.group.dealerBooth.transaction === null ? 0 : this.group.dealerBooth.transaction.amount;
    },
    primaryScheduleArray() {
      if(typeof this.group.registrationPrimary?.id === 'undefined') {
        return [];
      }

      return this.mapShowtimes(this.group.registrationPrimary);
    },
    oddSchedules() {
      // Filters out registrations with schedules that DO NOT match the registrationPrimary

      const oddSchedules = this.group.registrationsActiveFullNonPrimary.filter((registration) => {
        return !_.isEqual(this.primaryScheduleArray, this.mapShowtimes(registration));
      });

      return oddSchedules;
    },
    primarySchedules() {
      // Filters out registrations with schedules that DO match the registrationPrimary
      const oddSchedules = this.group.registrationsActiveFullNonPrimary.filter((registration) => {
        return _.isEqual(this.primaryScheduleArray, this.mapShowtimes(registration));
      });

      return oddSchedules;
    },
  },
	methods: {
		changePriority: async function() {
			try {
				// Construct request
				const request = {
					url: '/groups/' + this.$route.params.id,
					method: 'patch',
					headers: {'Authorization': 'bearer ' + this.globals.accessToken},
					data: { priority: this.newPriorityDate + ' ' + this.newPriorityTime },
				}
			
				// Execute request
				await this.$api3(request);

				// Update relevant data
				this.group.priority = this.newPriorityDate + ' ' + this.newPriorityTime;
			} catch (error) {
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
			}
		},
    loadDealerBooth() {
      this.modalDealerBooth = { ...this.group.dealerBooth };
    },
    saveDealerBooth: async function(bvModalEvt) {
      bvModalEvt.preventDefault();
			try {
        // Gather changes
        const changes = this.diffObjects(this.modalDealerBooth, this.group.dealerBooth);

				// Construct request
				const request = {
					url: '/dealer-booths/' + this.group.dealerBooth.id,
          headers: { Authorization: 'bearer ' + this.globals.accessToken},
					method: 'patch',
					data: changes
				}
			
				// Execute request
				const result = await this.$api3(request);

				// Update relevant data
				this.group.dealerBooth = result.data;

        // Hide the modal
        bvModalEvt.vueTarget.hide();
			} catch (error) {
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      }
    },
    addDealerBooth: async function(bvModalEvt) {
      bvModalEvt.preventDefault();
			try {
        // Gather dat
        const body = {
          groupId: this.group.id,
          ...this.modalDealerBooth
        }
				// Construct request
				const request = {
					url: '/dealer-booths',
          headers: { Authorization: 'bearer ' + this.globals.accessToken},
					method: 'post',
					data: body,
				}
			
				// Execute request
				const result = await this.$api3(request);

				// Update relevant data
				this.group.dealerBooth = result.data;

        // Hide the modal
        bvModalEvt.vueTarget.hide();
			} catch (error) {
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      }
    },
    resetDealerBooth() {
      this.modalDealerBooth = {};
    },
    openInvitationModal: async function(invitation) {
      this.currentInvitation = invitation;

      this.$bvModal.show('invitation-form');
    },
    async resendConfirmation() {
      // Construct request
      const request = {
        url: '/emails',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'post',
        data: {
          to: this.group.account.primaryProfile.email,
          template: 'e1b',
          id: this.group.token
        },
      }
    
      // Execute request
      await this.$api3(request).catch((error) => {
				let message = 'Email could not be sent.';

				if (error.response) {
					// Server responded
					message += ' Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message += ' Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      });

      this.$emit('alert', { message: `Group Confirmation successfully resent to ${this.group.account.primaryProfile.email}.` });
    },
    async resendSchedule() {
      // Send email confirmation
      // Create batch request
      let emailBatchRequest = {
        url: '/batch',
        method: 'post',
        data: {
          calls: []
        }
      }

      // Add email c13a to primary
      emailBatchRequest.data.calls.push([
        'create',
        'emails',
        {
          to: this.group.registrationPrimary.profile.email,
          template: 'e13a',
          id: this.group.token
        }
      ]);

      // Add email c13b to all dependents and guests
      for( const registration of this.group.registrationsActiveFullNonPrimary ) {
        if( registration.profile.email !== null ) {
          emailBatchRequest.data.calls.push([
            'create',
            'emails',
            {
              to: registration.profile.email,
              template: 'e13b',
              id: registration.token
            }
          ]);
        }
      }

      // Run batch
      const emailBatchResponse = await this.$api3(emailBatchRequest).catch((error) => {
        // Handle error in executing request
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      });

      // Check status of batch
      const emailErrors = _.filter(emailBatchResponse.data, (o) => {
        return o.status !== 'fulfilled'
      })

      if(emailErrors.length === 0) {
        this.$emit('alert', { error: false, message: 'Schedule sent to members of this Group' });
      }
    },
    renderPlaintextSchedule(tickets) {
      // Start with the static portions at the top of the schedule
      let schedule =
        `Sunday, August 4th: 10:00 AM - 10:00 PM\r\n` +
        `Registration\r\n\r\n` +
        `Sunday, August 4th: 7:30 - 11:00 PM\r\n` +
        `Welcome Party and The Magic Shop\r\n\r\n`;

      // Now start with the dynamic portions of the schedule;
      // Find the general session
      const generalSession = _.find(tickets, { 'showId': 14})

      // Create an alternating focus session since focus sessions don't exist in the database
      let startTime, endTime;
      if( generalSession.capacitySegmentId === 78 ) {
        // Late focus:
        startTime = new Date('2024-08-05T21:15:00.000Z');
        endTime = new Date('2024-08-05T23:30:00.000Z');
      } else {
        // Early focus:
        startTime = new Date('2024-08-05T17:00:00.000Z');
        endTime = new Date('2024-08-05T19:15:00.000Z');
      }

      const focusSession = {
        showId: 9,
        capacitySegment: {
          showtime: {
            show: {
              title: 'Focus Sessions'
            },
            startTime,
            endTime,
          }
        }
      };

      // Add the focus session to the tickets array
      tickets.push(focusSession);

      // Sort the tickets by start time
      tickets = _.sortBy(tickets, (t) => {
        return new Date(t.capacitySegment.showtime.startTime);
      });

      // Iterate over the tickets
      for (const ticket of tickets) {
        // Convert input to date objects
        const startTime = new Date(ticket.capacitySegment.showtime.startTime);
        const endTime = new Date(ticket.capacitySegment.showtime.endTime);

        // Set format for endTime
        const endTimeFormat = 'h:mm aa';

        // Get periods for startTime and endTime
        const startTimePeriod = this.$dateHelper(startTime, 'aa');
        const endTimePeriod = this.$dateHelper(endTime, 'aa');

        // Generate startTimeFormat
        // Show or hide day based on withDay
        const day = 'EEEE, MMMM do: ';
        // Add period based on whether the start and end match
        const period = startTimePeriod === endTimePeriod ? '' : ' aa';
        // Concatenate
        const startTimeFormat = ticket.showId === 9 ? `'Monday, Tuesday and Wednesday:' h:mm${period}` : `${day}h:mm${period}`;
        
        schedule +=
          ticket.capacitySegment.showtime.timeDisplayOverride ? `${ticket.capacitySegment.showtime.timeDisplayOverride}\r\n` : `${this.$dateHelper(startTime, startTimeFormat)} - ${this.$dateHelper(endTime, endTimeFormat)}\r\n`;
        
        schedule += `${ticket.capacitySegment.showtime.show.title}\r\n\r\n`;
      }

      schedule += 
        `Wednesday, August 7th: 10:00 PM - 1:00 AM\r\n` +
        `Farewell Party`;

      // Return the schedule
      return schedule;
    },
    renderPlaintextScheduleEmail() {
      // Build a schedule using the primary registrant's tickets
      const schedule = this.renderPlaintextSchedule(this.group.registrationPrimary.tickets);
      
      this.plainTextSchedule = 
        `Dear ${this.group.registrationPrimary.profile.badgeNameFirst},\r\n\r\n` +
        `Thank you for making your selections for MAGIC Live. The details of your schedule are below.\r\n\r\n` +
        `This schedule applies to all members of your group:\r\n\r\n`;
      
      this.plainTextSchedule += schedule;
      this.plainTextSchedule += '\r\n\r\n';
      
      this.plainTextSchedule += 
        `If you have any questions or need to make any changes, please reply to this message.\r\n\r\n` +
        `Thank you.`;

    },
    renderPlaintextTransfer() {
      // Build a schedule using the primary registrant's tickets
      const schedule = this.group.registrationPrimary.tickets > 0 ? this.renderPlaintextSchedule(this.group.registrationPrimary.tickets) : false;
      
      this.plainTextTransfer = 
        `Dear ${this.group.registrationPrimary.profile.badgeNameFirst},\r\n\r\n` +
        `This email confirms that you are registered for MAGIC Live, August 4-7, 2024. A registration has been transferred to you by NAME.\r\n\r\n`;

      if (schedule) {
        this.plainTextTransfer +=
          `Below is your show schedule:\r\n\r\n` +
          schedule +
          '\r\n\r\n';
      }

      this.plainTextTransfer +=
        `All attendees of MAGIC Live must agree to our Terms & Conditions. A copy of them is included at the bottom of this email.\r\n\r\n` +
        `If you have any questions, please reply to this message.\r\n\r\n` +
        `Thank you.\r\n\r\n` +
        '\r\n';

      this.plainTextTransfer += plaintextTerms;


    },
    async replaceSchedule(registration) {
      const confirmString = `You are about to remove all selections for ${registration.profile.badgeNameFull} and replace them with those of ${this.group.registrationPrimary.profile.badgeNameFull}.`;
      const confirmation = await this.$bvModal.msgBoxConfirm(confirmString, {
        title: 'Replace Schedule',
        okVariant: 'danger',
        okTitle: 'Replace',
        centered: true,
      });

      if (confirmation !== true) {
        return;
      }

      if( registration.tickets.length > 0) {
        // Create remove batch request
        let removeBatch = {
          url: '/batch',
          method: 'post',
          headers: {'Authorization': 'bearer ' + this.globals.accessToken},
          data: {
            calls: [],
          }
        };

        // Remove existing tickets
        for (const ticket of registration.tickets) {
          removeBatch.data.calls.push([
            'remove',
            'tickets',
            `${ticket.capacitySegment.showtime.show.id},${registration.id}`
          ])
        }

        await this.$api3(removeBatch);
      }

      // Create remove batch request
      let createBatch = {
        url: '/batch',
        method: 'post',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        data: {
          calls: [],
        }
      };

      // Create matching tickets
      for (const ticket of this.group.registrationPrimary.tickets) {
        createBatch.data.calls.push([
          'create',
          'tickets',
          {
            showId: ticket.capacitySegment.showtime.showId,
            capacitySegmentId: ticket.capacitySegment.id,
            registrationId: registration.id
          }
        ])
      }

      await this.$api3(createBatch);


      this.loadHandler(this.$route.params.id);
      
    },
		diffObjects: function(edited, original) {
			// Determines if any of the values in edited differ from those in original
			const changes = {};

			// If there aren't two objects, return the edited object in full
			if (typeof (edited) !== 'object' || typeof (original) !== 'object') return changes;

			for (let i in edited) {
        if (edited[i] == original[i]) continue;
        
        // Set the changed value, converting blank strings to null
				changes[i] = edited[i] === "" ? null : edited[i];
			}

			return changes;
		},
    mapShowtimes: (registration) => {
      return registration.tickets.map(ticket => ticket.capacitySegment.showtime.id );
    },
		loadHandler: async function(id) {
			// Construct request
			const request = {
				url: '/groups/' + id,
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
				params: { $eager: '[event,account.[primaryProfile,profiles],dealerBooth.transaction,invitations,registrations.[profile.account,tickets.capacitySegment.showtime.show,types]]' }
			}

      try {
        // Execute request
        const result = await this.$api3(request);

        // Update relevant data
        this.group = result.data;
        this.newPriorityDate = this.$dateHelper(result.data.priority || result.data.purchased, 'yyyy-MM-dd');
        this.newPriorityTime = this.$dateHelper(result.data.priority || result.data.purchased, 'HH:mm:ss');

        this.$emit('status', true);
      } catch (error) {
        let message;

				if (error.response) {
					// Server responded
          // if(error.response.status === 401) {
          //   this.$emit('logout');
          // }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      }
		},
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: async function() {
		this.loadHandler(this.$route.params.id);
	},
	beforeRouteUpdate(to, from, next) {
		this.loadHandler(to.params.id)
		next();
	},
};
</script>

<style>
</style>
