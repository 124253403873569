<template>
  <b-modal
    id="search-options"
    title="Search Options"
    @ok="$emit('update', {...pendingSearchOptions, configured: true})"
    @show="pendingSearchOptions = {...selectedSearchOptions}"
    size="lg"
    ok-title="Search"
    ok-only
  >
    <b-row>
      <b-col md="6" lg="4">
        <b-form-group
          label="Search For"
        >
          <b-form-checkbox-group
            id="recordTypes"
            :options="recordTypesArray"
            stacked
            v-model="pendingSearchOptions.recordTypes"
            :disabled="pendingSearchOptions.groupsWithNotes"
          />
        </b-form-group>
      </b-col>
      <b-col md="6"  lg="4">
        <b-form-group
          label="In Fields"
        >
          <b-form-checkbox-group
            id="fields"
            stacked
            v-model="pendingSearchOptions.fields"
            :disabled="pendingSearchOptions.groupsWithNotes"
          >
            <b-form-checkbox
              v-for="field in searchOptions.fields"
              :key="field.value"
              :value="field.value"
              :disabled="!relevantFields.includes(field.value)"
            >
              {{ field.text }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col md="12"  lg="4">
        <b-form-group
          label="Limit To"
          :disabled="!pendingSearchOptions.recordTypes.includes('groups') && !pendingSearchOptions.recordTypes.includes('invitations') && !pendingSearchOptions.recordTypes.includes('dealerBooths')"
        >
          <b-form-radio-group
            id="events"
            :options="searchOptions.events"
            stacked
            v-model="pendingSearchOptions.eventId"
          />
        </b-form-group>
        <b-form-checkbox
          id="with-notes"
          v-model="pendingSearchOptions.groupsWithNotes"
          :disabled="!pendingSearchOptions.recordTypes.includes('groups')"
        >
          Groups with Notes
        </b-form-checkbox>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: 'SearchOptionsModal',
  props: {
    searchOptions: Object,
    selectedSearchOptions: Object
  },
  data() {
    return {
      pendingSearchOptions: {
        recordTypes: [],
        eventId: null,
        groupsWithNotes: false,
        fields: [],
        configured: false,
      }
    }
  },
  computed: {
    recordTypesArray() {
      return Object.keys(this.searchOptions.recordTypes).map(key => {
        const { value, text } = this.searchOptions.recordTypes[key];
        return { value, text };
      })
      

    },
    relevantFields() {
      let relevantFields = [];
      for (const recordType of this.pendingSearchOptions.recordTypes) {
        relevantFields = [...relevantFields, ...this.searchOptions.recordTypes[recordType].relevantFields];
      }
      return relevantFields;
    },
  },
}
</script>