<template>
	<div>
		<h2>Groups <small class="text-muted">Found {{ groups.length }}</small></h2>

		<div v-for="group in groups" :key="group.id">
			<hr />
			<b-row>
				<b-col cols="10">
					<h4>{{ group.account.primaryProfile.badgeNameFull }} Group <small class="text-muted">{{ group.event.name }}</small></h4>
				</b-col>
				<b-col cols="2">
					<b-button variant="link" :to="'/groups/'+group.token" v-b-tooltip.hover title="View Group"><b-icon-people-fill variant="dark"></b-icon-people-fill></b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="3">
					<strong>Legal Name</strong>
				</b-col>
				<b-col cols="3">
					<strong>Badge Name</strong>
				</b-col>
				<b-col cols="4">
					<strong>Email</strong>
				</b-col>
			</b-row>
			<b-row v-for="registration in group.registrations" :key="registration.id">
				<b-col cols="3">
					{{ registration.profile.nameFull }}
				</b-col>
				<b-col cols="3">
					{{ registration.profile.badgeNameFull }}
				</b-col>
				<b-col cols="3">
					{{ registration.profile.email }}
				</b-col>
				<b-col cols="1">
					<b-badge :variant="registration.active ? 'success' : 'danger'">
						{{ registration.active ? 'Active' :' Inactive' }}
					</b-badge>
				</b-col>
				<b-col cols="2">
          <b-button variant="link" :to="'/registrations/'+registration.token" v-b-tooltip.hover title="View Registration"><b-icon-person-square variant="dark"></b-icon-person-square></b-button>
					<b-button variant="link" :to="'/profiles/'+registration.profile.id" v-b-tooltip.hover title="View Profile"><b-icon-person-lines-fill variant="dark"></b-icon-person-lines-fill></b-button>
					<b-button variant="link" :to="'/accounts/'+registration.profile.account.token" v-b-tooltip.hover title="View Account">
						<b-iconstack class="mr-3" variant="dark">
							<b-icon stacked icon="folder-fill"></b-icon>
							<b-icon stacked icon="person-fill" scale="0.55" shift-v="-0.4" variant="white"></b-icon>
						</b-iconstack>					
					</b-button>
				</b-col>
			</b-row>
		</div>




		<!-- <b-pagination
			v-if="profileTotal > 20"
			v-model="currentPage"
			:total-rows="profileTotal"
			per-page="20"
			aria-controls="profiles-table"
		></b-pagination> -->
	</div>
</template>

<script>
export default {
	name: 'Groups',
	props: {
		globals: Object,
	},
	data: function() {
		return {
			groups: [],
		}
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
</style>
