<template>
  <b-modal
  id="registration-cost"
  centered
  title="Registration Cost?"
  v-on:hidden="amount = 0.00"
  v-on:ok="$emit('next', amount)"
  ok-title="Create Group"
>
  <b-form-group
    label="How much should the Primary Registration cost?"
    label-for="input-modal-transasction-amount"
    description="Should be a negative number. Leave as zero for a comp Registration.">
    <b-form-input type="number" id="input-modal-transaction-amount" v-model.number="amount" max="0" />
  </b-form-group>
</b-modal>
</template>

<script>

export default {
  name: 'RegistrationCostModal',
  data() {
    return {
			amount: 0.00
    }
  },
}
</script>