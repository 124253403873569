<template>
  <div>
    Logout
  </div>
</template>

<script>
export default {
  name: 'Logout',
  components: {},
	props: {
		globals: Object,
	},
	beforeCreate() {
		// this.$emit('status', false);
	},
  created() {
    this.$emit('logout');
    this.$router.push('/');
	},
};
</script>