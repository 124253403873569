<template>
	<div>
		<h2>Registrations <small class="text-muted">Found {{ registrations.length }}</small></h2>

		<b-table
			id="registrations-table"
			striped
			:items="registrations"
			:fields="fields" 
			per-page="20"
			:current-page="currentPage"
		>
			<template v-slot:cell(name)="data">
				{{ data.item.nameFirst }} {{ data.item.nameLast ? ' ' + data.item.nameLast : '' }}
			</template>
			<template v-slot:cell(badgeName)="data">
				{{ data.item.badgeNameFirst }} {{ data.item.badgeNameLast ? ' ' + data.item.badgeNameLast : '' }}
			</template>
			<template v-slot:cell(actions)="data">
				<b-button size="sm" variant="link" :to="'/registrations/'+data.item.token" v-b-tooltip.hover title="View Registration"><b-icon-person-square variant="dark"></b-icon-person-square></b-button>
				<b-button size="sm" variant="link" :to="'/profiles/'+data.item.profile.id" v-b-tooltip.hover title="View Profile"><b-icon-person-lines-fill variant="dark"></b-icon-person-lines-fill></b-button>
			</template>
		</b-table>

    <b-pagination
			v-if="registrations.length > 20"
			v-model="currentPage"
			:total-rows="registrations.length"
			per-page="20"
			aria-controls="registrations-table"
		></b-pagination>
	</div>
</template>

<script>
export default {
	name: 'Registrations',
	props: {
		globals: Object,
	},
	methods: {
    async loadHandler() {
      if (this.$route.query.tag) {
        // Construct request
        const request = {
          url: '/registration-tags',
          headers: { Authorization: 'bearer ' + this.globals.accessToken},
          params: { slug: this.$route.query.tag, $eager: 'registrations.profile', eventId: this.globals.event.id }
        }

        // Execute request
        const result = await this.$api3(request).catch((error) => {
          console.log(error);

          if(error.response.status === 401) {
            this.$emit('logout');
          }
        });
        console.log(result);

        // Update relevant data
        this.registrations = result.data.data[0].registrations;
        this.$emit('status', true);
        // this.profileTotal = result.data.total;
      }
    }
	},
	watch: {},
	data: function() {
		return {
			registrations: [],
      fields: [
				{
					key: 'profile.nameFull',
					label: 'Legal Name',
				},
				{
					key: 'profile.badgeNameFull',
					label: 'Badge Name',
				},
				{
					key: 'profile.email',
          label: 'Email',
				},
				{
					key: 'actions',
					label: ''
				}
			],
      currentPage: 1,
		}
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created () {
		this.loadHandler();
	},
	mounted: function() {

	},
};
</script>

<style>
</style>
