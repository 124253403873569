<template>
  <b-modal
    id="transaction"
    centered
    :title="modalTitle"
    v-on:hidden="resetTransaction"
    v-on:ok.prevent="createOrPatchTransaction"
    :ok-disabled="transaction.transactionTypeId === 0 || transaction.amount === 0"
    :busy="busy"
  >
    <b-overlay :show="busy">
      <b-form-group label="Date" label-for="input-change-priority-date">
        <b-form-datepicker id="input-change-priority-date" v-model="transaction.created" placeholder="Today"></b-form-datepicker>
      </b-form-group>
      <b-form-group
        label="Type"
        label-for="input-modal-transaction-type">
        <b-form-select
          id="input-modal-transaction-type"
          v-model="transaction.transactionTypeId"
          :options="transactionTypes"
          value-field="id"
          text-field="name"
        >
          <template v-slot:first>
            <b-form-select-option value="" disabled />
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Amount"
        label-for="input-modal-transasction-amount"
        :description="amountHelpText">
        <b-form-input type="number" id="input-modal-transaction-amount" v-model.number="transaction.amount" />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-if="transaction.transactionTypeId === 31 && transaction.created > new Date().toISOString()" v-model="transaction.omitFee" value="1" unchecked-value="0">
          Omit Transaction fee?
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Detail"
        label-for="input-modal-transasction-detail"
      >
        <b-form-input id="input-modal-transaction-detail" v-model="transaction.detail" />
      </b-form-group>
      <b-form-group
        v-if="transaction.transactionTypeId === 31 && transaction.created < new Date().toISOString()"
        label="Stripe Payment ID"
        label-for="stripe-charge-id"
      >
        <b-form-input id="stripe-charge-id" v-model="transaction.stripeChargeId" placeholder="pi_" />
      </b-form-group>
      <b-form-text v-if="chargeText.text || error" :text-variant="chargeText.variant || 'danger'" class="mt-4 text-center warning">{{ error ? error : chargeText.text }}</b-form-text>
    </b-overlay>
  </b-modal>
</template>

<script>
const { cloneDeep: _cloneDeep } = require('lodash');

export default {
  name: 'TransactionModal',
  data() {
    return {
			transaction: {
				created: null,
				transactionTypeId: 0,
				amount: 0.00,
				detail: null,
        stripeChargeId: null,
        omitFee: 0
			},
      transactionTypes: [],
      error: null,
      busy: false,
    }
  },
  computed: {
		amountHelpText() {
			let helpText;
			if (this.transaction.transactionTypeId > 0) {
				if (this.transaction.transactionTypeId < 20) {
					helpText = 'Usually negative for this transaction type.';
				} else if (this.transaction.transactionTypeId < 30) {
					helpText = null;
				} else {
					helpText = 'Usually positive for this transaction type.';
				}
			}
			return helpText;
    },
    chargeText() {
      const chargeText = {};
      if (!this.transaction.id) {
        if (this.transaction.transactionTypeId === 31) {
          if (this.transaction.created === null) {
            chargeText.text = 'The payment method on file will be charged immediately.';
            chargeText.variant = 'success';
            
          } else if (this.transaction.created > new Date().toISOString()) {
            chargeText.text = 'The payment method on file will be charged when the date is reached.';
            chargeText.variant = 'secondary';
          } else {
            chargeText.text = 'Card payments in the past must include the Stripe Charge ID.'
            chargeText.variant = 'danger';
          }
        } else if (this.transaction.transactionTypeId === 13) {
          chargeText.text = 'This refund will need to be processed manually in Stripe.';
          chargeText.variant = 'danger';
        }
      }

      return chargeText;
    },
    modalTitle() {
      return this.transaction.id ? 'Edit Transaction' : 'Add Transaction';
    }
  },
  methods: {
    async createOrPatchTransaction(bvModalEvt) {
      this.busy = true;
      const action = this.transaction.id ? this.patchTransaction : this.createTransaction;

      await action().catch((error) => {
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Show the error
        this.busy = false;
				this.error = message;
      });

      if(!this.error) {
        this.$emit('refresh');
        bvModalEvt.vueTarget.hide();
      }
    },
		createTransaction() {
			// Construct request
			const request = {
				url: '/transactions',
				method: 'POST',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data: {
					accountId: this.account.id,
					eventId: this.globals.event.id,
					created: this.transaction.created,
					amount: this.transaction.amount,
					transactionTypeId: this.transaction.transactionTypeId,
					detail: this.transaction.detail,
          stripeChargeId: this.transaction.stripeChargeId,
          omitFee: this.transaction.omitFee,
				}

			};

      // Return promise
      return this.$api3(request);
    },
    patchTransaction() {
      // Determine changes
      const changes = this.diffObjects(this.transaction, this.existingTransaction);
      
      // When editing a transaction's created date, lastReminded and reminderCount to reset reminders
      if ('created' in changes) {
        changes.lastReminded = null;
        changes.reminderCount = 0;
      }
      
			// Construct request
			const request = {
				url: `/transactions/${this.transaction.id}`,
				method: 'PATCH',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data: changes
			};

      // Return promise
      return this.$api3(request);
    },
    resetTransaction() {
			this.transaction = {
				created: null,
				transactionTypeId: 0,
				amount: 0.00,
				detail: null,
        stripeChargeId: null,
        omitFee: 0,
			}
      this.error = null;
      this.busy = false;
      this.$emit('hidden');
		},
    diffObjects: function(edited, original) {
			// Determines if any of the values in edited differ from those in original
			const changes = {};

			// If there aren't two objects, return the edited object in full
			if (typeof (edited) !== 'object' || typeof (original) !== 'object') return changes;

			for (let i in edited) {
        if (edited[i] == original[i]) continue;
        
        // Set the changed value, converting blank strings to null
				changes[i] = edited[i] === "" ? null : edited[i];
			}

			return changes;
		},
    async findTransactionTypes() {
      // Create request
      const request = {
        url: '/transaction-types',
        method: 'get',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
      }; 
      
      // Execute request
      const response = await this.$api3(request).catch((error) => {
        // Handle error in executing request
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      });

      // Update data
			this.transactionTypes = response.data.data;
    }
  },
  props: {
    globals: Object,
    account: Object,
    existingTransaction: Object,
  },
  watch: {
    existingTransaction(newValue) {
      // Populate existing transaction
      if( newValue.id ) {
        this.transaction = _cloneDeep(newValue);
      }
    }
  },
  async created() {
    // Load transaction types
    this.findTransactionTypes();
  }
}
</script>