<template>
  <b-modal
    :id="`capacity-segment-modal-${capacitySegment.id}`"
    centered
    size="sm"
    title="Edit Capacity"
    v-on:hidden="resetModal"
    v-on:ok.prevent="patchCapacitySegment"
  >
    <p>{{ show.title }}<br />
    {{ $dateHelper(showtime.startTime, '$showTime') }}<br />
    {{ capacitySegment.capacityRemaining }} Remaining</p>

    <p v-if="capacitySegment.limitedToCapacitySegmentId">Limited to<br />
      {{ capacitySegment.limitedToCapacitySegment.showtime.show.title }}<br />
      {{ $dateHelper(capacitySegment.limitedToCapacitySegment.showtime.startTime, '$showTime') }}</p>

    <b-form>
      <b-form-checkbox name="available-check" class="mb-3" v-model="localCapacitySegment.isAvailable" value="1" unchecked-value="0" switch>
        Available
      </b-form-checkbox>
      <b-form-group
        label="Capacity"
        label-for="input-modal-capacity"
        description="Raise (+) or lower (-) the capacity for this showtime. Remaining capacity will be recalculated automatically."
      >
        <b-form-spinbutton type="number" min="-5000" max="5000" id="input-modal-capacity" v-model.number="localCapacitySegment.capacity" />
      </b-form-group>
    </b-form>
    <p class="text-danger" v-if="error">{{ error }}</p>
  </b-modal>
</template>

<script>
const { cloneDeep: _cloneDeep } = require('lodash');

export default {
  name: 'ShowtimeModal',
  props: {
    globals: Object,
    show: Object,
    showtime: Object,
    capacitySegment: Object
  },
  data() {
    return {
			localCapacitySegment: {},
      error: null,
    }
  },
  computed: {},
  methods: {
    async patchCapacitySegment() {
      // Determine changes
      const changes = this.diffObjects(this.localCapacitySegment, this.capacitySegment);

      if (changes.length === 0) {
        return;
      }
      
			// Construct request
			const request = {
				url: `/capacity-segments/${this.capacitySegment.id}`,
				method: 'PATCH',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data: changes
			};

      await this.$api3(request).catch((error) => {
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Show the error
				this.error = message;
      });

      // this.bvModalEvt.vueTarget.hide();
      this.$router.go();
    },
    resetModal() {
			this.localCapacitySegment = _cloneDeep(this.capacitySegment);
      this.error = null;
      this.$emit('hidden');
		},
    diffObjects: function(edited, original) {
			// Determines if any of the values in edited differ from those in original
			const changes = {};

			// If there aren't two objects, return the edited object in full
			if (typeof (edited) !== 'object' || typeof (original) !== 'object') return changes;

			for (let i in edited) {
        if (edited[i] == original[i]) continue;
        
        // Set the changed value, converting blank strings to null
				changes[i] = edited[i] === "" ? null : edited[i];
			}

			return changes;
		},
  },
  created() {
    this.localCapacitySegment = _cloneDeep(this.capacitySegment);
  }
}
</script>