<template>
  <b-modal
  id="new-waitlist-group"
  centered
  title="New Waitlist Group"
  v-on:hidden="resetModal"
  v-on:ok.prevent="createWaitlistGroup"
  :ok-disabled="newWaitlistGroup.nameFirst === null || newWaitlistGroup.nameLast === null || newWaitlistGroup.email === null || newWaitlistGroup.guestNames.length !== newWaitlistGroup.size - 1"
>
  <b-form-group
    label="Size"
    label-for="input-modal-size">
    <b-form-radio-group
      id="input-modal-size"
      v-model="newWaitlistGroup.size"
      :options="sizes"
      buttons
    />
  </b-form-group>
  <b-form-group
    label="Primary First Name"
    label-for="input-modal-first-name"
  >
    <b-form-input id="input-modal-first-name" v-model="newWaitlistGroup.nameFirst" />
  </b-form-group>
  <b-form-group
    label="Primary Last Name"
    label-for="input-modal-last-name"
  >
    <b-form-input id="input-modal-last-name" v-model="newWaitlistGroup.nameLast" />
  </b-form-group>
  <b-form-group
    label="Email"
    label-for="input-modal-email"
  >
    <b-form-input id="input-modal-email" v-model="newWaitlistGroup.email" />
  </b-form-group>
  <b-form-group
    v-for="index in newWaitlistGroup.size-1"
    :key="index"
    :label="index === 1 ? 'Guest Names' : ''"
    :label-for="`input-modal-guest-${index-1}`"
  >
    <b-form-input :id="`input-modal-guest-${index-1}`" v-model="newWaitlistGroup.guestNames[index-1]" />
  </b-form-group>

</b-modal>
</template>

<script>
export default {
  name: 'NewWaitlistGroupModal',
  data() {
    return {
			newWaitlistGroup: {
				nameFirst: null,
				nameLast: null,
				email: null,
        guestNames: [],
        size: 1
			},
      sizes: [ 1, 2, 3, 4, 5, 6, 7, 8 ]
    }
  },
  computed: {

  },
  methods: {
    async createWaitlistGroup(bvModalEvt) {
      const request = {
				url: '/waitlist-groups',
				method: 'POST',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data: {
          size: this.newWaitlistGroup.size,
          nameFirst: this.newWaitlistGroup.nameFirst,
          nameLast: this.newWaitlistGroup.nameLast,
          email: this.newWaitlistGroup.email,
				}
      }

      if( this.newWaitlistGroup.guestNames.length > 0 ) {
        request.data.guestNames = this.newWaitlistGroup.guestNames;
      }

      await this.$api3(request).catch((error) => {
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

        console.log(message);

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
				bvModalEvt.vueTarget.hide();
      });

      this.$emit('refresh');
      bvModalEvt.vueTarget.hide();
    },
    resetModal() {
			this.newWaitlistGroup = {
				nameFirst: null,
				nameLast: null,
				email: null,
        guestNames: [],
        size: 1
			}
		},
  },
  props: {
    globals: Object,
  },
}
</script>