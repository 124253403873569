<template>
	<div>
    <b-row>
			<b-col cols="9">
        <h2>{{ dynamicTitle}} <small v-if="dynamicGroupCount" class="text-muted">{{ dynamicRegistrationCount }} Registrations in {{ dynamicGroupCount }} Groups</small></h2>
      </b-col>
			<b-col cols="3">
        <div class="text-right">
          <b-button variant="success" v-b-modal.new-waitlist-group>
            <b-icon icon="plus"></b-icon>
          </b-button>
          <b-form-checkbox class="mt-2 mb-2" v-model="showArchived" name="Show Archived" switch>
            <b-icon icon="trash-fill" variant="secondary"></b-icon>
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
		<b-table
      id="waitlist-groups-table"
			striped
			:items="waitlistGroupsModified"
			:fields="fields" 
			per-page="20"
			:current-page="currentPage"
      empty-text="There are no Waitlist Groups to show here."
      v-show="!showArchived"
		>
      <template v-slot:cell(email)="data">
        <a :href="`mailto:${data.item.email}`">{{ data.item.email }}</a>
      </template>
      <template v-slot:cell(guestNames)="data">
        {{ data.item.guestNames !== null ? data.item.guestNames.join(', ') : '' }}
      </template>
      <template v-slot:cell(created)="data">
        {{ $dateHelper(data.item.created) }}
      </template>
			<template v-slot:cell(actions)="data">
				<b-button size="sm" variant="link" v-b-tooltip.hover :title="data.item.prioritized ? 'Remove Priority' : 'Give Priority'">
            <b-icon :icon="data.item.prioritized ? 'calendar-minus-fill' : 'calendar-plus-fill'" variant="dark" v-on:click="togglePriority(data.item)"></b-icon>
        </b-button>
        <b-button size="sm" variant="link" v-b-tooltip.hover title="Archive">
            <b-icon icon="trash-fill" variant="dark" v-on:click="toggleArchive(data.item)"></b-icon>
        </b-button>
			</template>
		</b-table>

    <b-pagination
			v-if="waitlistGroupsModified.length > 20"
			v-model="currentPage"
			:total-rows="waitlistGroupsModified.length"
			per-page="20"
			aria-controls="waitlist-groups-table"
      v-show="!showArchived"
		></b-pagination>

    <b-table
      id="archived-waitlist-groups-table"
			striped
			:items="archivedWaitlistGroups"
			:fields="fields" 
			per-page="20"
			:current-page="currentPageArchived"
      empty-text="There are no Waitlist Groups to show here."
      v-show="showArchived"
		>
      <template v-slot:cell(email)="data">
        <a :href="`mailto:${data.item.email}`">{{ data.item.email }}</a>
      </template>
      <template v-slot:cell(guestNames)="data">
        {{ data.item.guestNames !== null ? data.item.guestNames.join(', ') : '' }}
      </template>
      <template v-slot:cell(created)="data">
        {{ $dateHelper(data.item.created) }}
      </template>
			<template v-slot:cell(actions)="data">
        <b-button size="sm" variant="link" v-b-tooltip.hover title="Unarchive">
            <b-icon icon="reply-fill" flip-h variant="dark" v-on:click="toggleArchive(data.item)"></b-icon>
        </b-button>
			</template>
		</b-table>

    <b-pagination
			v-if="archivedWaitlistGroups.length > 20"
			v-model="currentPageArchived"
			:total-rows="archivedWaitlistGroups.length"
			per-page="20"
			aria-controls="archived-waitlist-groups-table"
      v-show="showArchived"
		></b-pagination>

    <new-waitlist-group-modal :globals="globals" @refresh="loadHandler()" />
	</div>
</template>

<script>
import { partition as _partition, sumBy as _sumBy } from 'lodash';
import NewWaitlistGroupModal from '../modals/NewWaitlistGroupModal.vue';

export default {
	name: 'WaitlistGroups',
  components: { NewWaitlistGroupModal },
	props: {
		globals: Object,
	},
	data: function() {
		return {
			fields: [
        {
          key: 'nameFull',
          label: 'Name'
        },
        {
          key: 'email'
        },
        {
          key: 'guestNames',
          label: 'Guest Names'
        },
        {
          key: 'created'
        },
				{
					key: 'actions',
					label: ''
				}
			],
			waitlistGroups: [],
      archivedWaitlistGroups: [],
      showArchived: false,
      currentPage: 1,
      currentPageArchived: 1,
		}
	},
  computed: {
    waitlistGroupsModified() {
      if( typeof this.waitlistGroups !== 'object' ) return;
      return this.waitlistGroups.map((row) => {
        let tmp = row;
        if( row.prioritized === 1 ) tmp._rowVariant = 'info';
        return tmp;
      })
    },
    dynamicTitle() {
      return this.showArchived ? 'Archived Waitlist Groups' : 'Waitlist Groups';
    },
    dynamicGroupCount() {
      return this.showArchived ? this.archivedWaitlistGroups.length : this.waitlistGroups.length;
    },
    dynamicRegistrationCount() {
      return _sumBy( this.showArchived ? this.archivedWaitlistGroups : this.waitlistGroups, 'size' )
    }
  },
  methods: {
    async loadHandler() {
      // Construct request
      const request = {
        url: '/waitlist-groups/',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          '$sort[prioritized]': -1,
          '$sort[created]': 1,
          '$limit': 1000,
        }
      }

      // Execute request
      const result = await this.$api3(request).catch((error) => {
        let message;

        if (error.response) {
          // Server responded
          message = 'Server Error: ' + error.response.data.message;
        } else {
          // No response or Error in request
          message = 'Axios: ' + error;
        }

        // Emit the error and close the modal
        this.$emit('alert', { error: true, message: message });
      });

      // Split out results
      const partitionedArray = _partition(result.data.data, ['archived', 0]);

      // Update relevant data
      this.waitlistGroups = partitionedArray[0];
      this.archivedWaitlistGroups = partitionedArray[1];
    },
    async patchTransaction(id, data) {
      const request = {
        url: `/waitlist-groups/${id}`,
        method: 'PATCH',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        data,
      }

      await this.$api3(request).catch((error) => {
        let message;

        if (error.response) {
          // Server responded
          message = 'Server Error: ' + error.response.data.message;
        } else {
          // No response or Error in request
          message = 'Axios: ' + error;
        }

        // Emit the error and close the modal
        this.$emit('alert', { error: true, message: message });
      });
      
      this.loadHandler();
    },
    togglePriority(waitlistGroup) {
      this.patchTransaction(waitlistGroup.id, {
        prioritized: !waitlistGroup.prioritized
      })
    },
    toggleArchive(waitlistGroup) {
      this.patchTransaction(waitlistGroup.id, {
        archived: !waitlistGroup.archived
      })
    }
  },
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: async function() {
    this.loadHandler();
  },
	mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
</style>
