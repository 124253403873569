<template>
  <b-modal id="add-registration-tags"
    v-on:show="load"
    v-on:ok="save"
    v-on:hidden="reset"
    size="md"
    title="Add Registration Types"
    :ok-disabled="tagIdsToAdd.length === 0"
  >
    <b-form-checkbox-group stacked buttons button-variant="primary" v-model="tagIdsToAdd" name="tags-to-add" :options="availableTags" text-field="name" value-field="id" />
  </b-modal>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AddRegistrationTagsModal',
  data: function() {
    return {
      availableTags: [],
      tagIdsToAdd: [],
    }
  },
  props: {
    globals: Object,
    registration: Object,
  },
  computed: {
    currentTags() {
      return _.map(this.registration.types, 'id');
    },
    tagsToAdd() {
      return _.filter(this.availableTags, (tag) => {
        return this.tagIdsToAdd.includes(tag.id);
      })
    }
  },
  methods: {
    load: async function (){
      // Get all available tags for the given event and category 2
      const request = {
        url: `/registration-tags/?category_id=2&event_id=${this.registration.group.eventId}`,
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          $limit: 100,
        }
      }
      
      try {
        // Execute request
				const result = await this.$api3(request);

        // Filter tags to only show those that aren't already part of this registration
        this.availableTags = _.filter(result.data.data, (tag) => {
          return !this.currentTags.includes(tag.id)
        });
      } catch (error) {
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      }
    },
    save: async function(bvModalEvt) {
      bvModalEvt.preventDefault();

      try {
        let request = {};

        for await (const tagId of this.tagIdsToAdd) {
          request = {
            url: `/registration-tags-registrations`,
            headers: { Authorization: 'bearer ' + this.globals.accessToken},
            method: 'post',
            data: {
              registrationId: this.registration.id,
              registrationTagId: tagId,
            },
          }

          // Execute request
          await this.$api3(request);
        }

        // Emit an event to refresh the parent
        this.$emit('add-tags', this.tagsToAdd);
        bvModalEvt.vueTarget.hide();
      } catch (error) {
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      }
    },
    reset() {
      this.availableTags = [];
      this.tagIdsToAdd = [];
    }
  },
}
</script>

<style>

</style>