<template>
  <div>
    <registration-list-item :registration="group.registrationPrimary" />
    <registration-list-item v-for="registration in group.registrationPrimary.dependents" :key="registration.id" :registration="registration" :dependent="true"/>
    <template v-for="guest in group.registrationsGuest">
      <registration-list-item :registration="guest" :key="guest.id" :guest="true" />
      <registration-list-item v-for="registration in guest.dependents" :key="registration.id" :registration="registration" :guest="true" :dependent="true"/>
    </template>
  </div>
</template>

<script>
import RegistrationListItem from './RegistrationListItem.vue'
export default {
  name: 'RegistrationList',
  components: {RegistrationListItem},
  props: {
    group: Object,
    serp: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>

</style>