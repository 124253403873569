<template>
	<div>
    <b-row class="mb-3">
      <b-col cols="10">
        <h1>
          Search Results 
          <small v-if="results" class="text-muted">
            {{ searchSummary }}
          </small>
        </h1>
      </b-col>
      <b-col cols="2">
        <div class="text-right">
          <b-button v-b-modal.search-options>
            <b-icon icon="ui-checks-grid"></b-icon>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <template v-if="results.query">
      <b-row v-if="results.query.recordTypes.length > 1" class="mb-4">
        <b-col v-for="recordType in results.query.recordTypes" :key="recordType">
          <b-card @click="$refs[recordType].scrollIntoView()" style="cursor: pointer">
            <div class="font-weight-bold dash-title">
              {{ capitalize(recordType) }}
            </div>
            Found {{ results[recordType].total }}
            <b-icon icon="arrow-down" class="float-right"></b-icon>
          </b-card>
        </b-col>
      </b-row>

      <template v-if="results.groups">
        <div class="mb-5" ref="groups">
          <h3 class="mb-3">Groups <small>Found {{ results.groups.total }}</small></h3>
          <div v-for="group in results.groups.data" :key="group.id" class="mb-4">
            <b-row>
              <b-col cols="10">
                <h4>{{ group.account.primaryProfile.badgeNameFull }} Group <small class="text-muted">{{ group.event.name }}</small></h4>
              </b-col>
              <b-col cols="2" class="text-right pr-4">
                <b-button variant="link" :to="'/groups/'+group.token" v-b-tooltip.hover title="View Group"><b-icon-people-fill variant="dark"></b-icon-people-fill></b-button>
                <b-button variant="link" disabled><b-icon /></b-button>
                <b-button variant="link" disabled><b-icon /></b-button>
              </b-col> 
            </b-row>
            <p v-if="results.query.groupsWithNotes "><b-icon icon="sticky" class="mr-2" />{{ group.notes }}</p>  
            <registration-list :group="group" />
            <h6 v-if="group.dealerBooth">Dealer: {{ group.dealerBooth.company }}</h6>
          </div>
        </div>
      </template>

      <template v-if="results.profiles">
        <div class="mb-5" ref="profiles">
          <h4>Profiles <small>Found {{ results.profiles.total }}</small></h4>
          <columnar-table v-for="profile in results.profiles.data" :key="profile.id">
            <template v-slot:first>
              {{ profile.badgeNameFull }}<br />
              <small class="text-secondary" v-if="profile.nameFull !== profile.badgeNameFull">{{ profile.namefull }}</small>
            </template>
            <template v-slot:second>
              <a :href="`mailto:${profile.email}`" v-if="profile.email" v-b-tooltip.hover :title="profile.email">{{ profile.email }}</a>
            </template>
            <template v-slot:third>
              <template v-if="profile.phoneNumber">{{ profile.phoneNumber.nationalFormat }}</template>
            </template>
            <template v-slot:fourth>
              <b-button variant="link" :to="'/profiles/'+profile.id" v-b-tooltip.hover title="View Profile"><b-icon-person-lines-fill variant="dark"></b-icon-person-lines-fill></b-button>
            </template>
          </columnar-table>
        </div>
      </template>

      <template v-if="results.invitations">
        <div class="mb-5" ref="invitations">
          <h4>Invitations <small>Found {{ results.invitations.total }}</small></h4>
          <columnar-table v-for="invitation in results.invitations.data" :key="invitation.id">
            <template v-slot:first>
              {{ invitation.nameFull }}<br />
              <small class="text-secondary" v-if="invitation.groupId">{{ invitation.group.account.primaryProfile.badgeNameFull }} Group</small>
            </template>
            <template v-slot:second>
              <a :href="invitation.email">{{ invitation.email }}</a>
            </template>
            <template v-slot:third>
              {{ $dateHelper(invitation.expiration, '$date') }}
            </template>
            <template v-slot:fourth>
              <b-button variant="link" :to="'/groups/'+invitation.group.token" v-b-tooltip.hover title="View Group" v-if="invitation.groupId"><b-icon-people-fill variant="dark"></b-icon-people-fill></b-button>
            </template>
          </columnar-table>
        </div>
      </template>

      <template v-if="results.dealerBooths">
        <div class="mb-5" ref="dealerBooths">
          <h4>Dealer Booths <small>Found {{ results.dealerBooths.total }}</small></h4>
          <columnar-table v-for="dealerBooth in results.dealerBooths.data" :key="dealerBooth.id">
            <template v-slot:first>
              {{ dealerBooth.company }}
            </template>
            <template v-slot:second>
              {{ dealerBooth.group.account.primaryProfile.badgeNameFull }} Group
            </template>
            <template v-slot:third>
              {{ dealerBooth.size }}
            </template>
            <template v-slot:fourth>
              <b-button variant="link" :to="'/groups/'+dealerBooth.group.token" v-b-tooltip.hover title="View Group"><b-icon-people-fill variant="dark"></b-icon-people-fill></b-button>
            </template>
          </columnar-table>
        </div>
      </template>
    </template>


    <search-options-modal :searchOptions="searchOptions" :selectedSearchOptions="selectedSearchOptions" @update="updateAndSearch" />


	</div>
</template>

<script>
import SearchOptionsModal from '../modals/SearchOptionsModal.vue';
import RegistrationList from '../RegistrationList.vue';
import ColumnarTable from './ColumnarTable.vue';

import { capitalize, some as _some } from 'lodash';
export default {
  components: { SearchOptionsModal, RegistrationList, ColumnarTable },
	name: 'SearchResults',
	props: {
		globals: Object,
    searchTerm: String,
	},
	data: function() {
		return {
      searchOptions: {
        recordTypes: {
          groups: { value: 'groups', text: 'Groups', relevantFields: ['badgeNameFirst', 'badgeNameLast', 'nameFirst', 'nameLast', 'company', 'email', 'phone']},
          profiles: { value: 'profiles', text: 'Profiles', relevantFields: ['badgeNameFirst', 'badgeNameLast', 'nameFirst', 'nameLast', 'email', 'phone']},
          invitations: { value: 'invitations', text: 'Invitations', relevantFields: ['nameFirst', 'email']},
          dealerBooths: { value: 'dealerBooths', text: 'Dealer Booths', relevantFields: ['company']}
        },
        events: [
          { value: 5, text: 'MAGIC Live 2024' },
          { value: 4, text: 'MAGIC Live 2023' },
          { value: 2, text: 'MAGIC Live 2022' },
          { value: 1, text: 'MAGIC Live 2020 (Cancelled)' }
        ],
        fields: [
          { value: 'badgeNameFirst', text: 'Badge Name First'},
          { value: 'badgeNameLast', text: 'Badge Name Last'},
          { value: 'nameFirst', text: 'Name First'},
          { value: 'nameLast', text: 'Name Last'},
          { value: 'company', text: 'Company Name'},
          { value: 'email', text: 'Email'},
          { value: 'phone', text: 'Phone'},
        ]
      },
      selectedSearchOptions: {
        recordTypes: ['groups', 'profiles', 'invitations'],
        eventId: 2,
        groupsWithNotes: false,
        fields: ['badgeNameFirst', 'badgeNameLast', 'nameFirst', 'nameLast'],
        configured: false,
      },
      results: {},
		}
	},
  computed: {
    terms() {
      if( !this.searchTerm ) return [];
      const filteredTerm = this.searchTerm.trim().toLowerCase().replace(/[^a-z0-9\s]/g, '');

      if(/^\d+$/.test(filteredTerm.replace(' ', ''))) {
        return [filteredTerm.replace(' ', '')];
      }

      return this.searchTerm.trim().toLowerCase().split(/\s+/).filter(x => x.length > 2);
    },
    relevantFields() {
      let relevantFields = [];
      for (const recordType of this.selectedSearchOptions.recordTypes) {
        relevantFields = [...relevantFields, ...this.searchOptions.recordTypes[recordType].relevantFields];
      }
      return relevantFields;
    },
    filteredFields() {
      return this.selectedSearchOptions.fields.filter(x => this.relevantFields.includes(x));
    },
    searchSummary() {
      if (typeof this.results.query === 'undefined') return '';

      if (this.results.query.groupsWithNotes) return 'Groups with Notes';

      let searchSummary = 'for ' + this.results.query.terms.map(x => `"${x}"`).join(', ');

      return searchSummary;
    }
  },
  watch: {
    terms() {
      this.performSearch();
    }
  },
  methods: {
    capitalize,
    async performSearch() {
      this.$emit('status', false);
      this.results = {};

      // Handle groupsWithNotes
      if(this.selectedSearchOptions.groupsWithNotes) {
        const groupRequest = {
          headers: { Authorization: 'bearer ' + this.globals.accessToken},
          url: '/groups',
          params: {
            $eager: '[registrations.[profile.account,types],account.primaryProfile,dealerBooth,event]',
            eventId: this.selectedSearchOptions.eventId,
            'notes[$ne]': 'null'
          }
        };

        const result = await this.$api3(groupRequest);

        this.results = {
          query: {
            eventId: this.selectedSearchOptions.eventId,
            groupsWithNotes: true,
            recordTypes: ['groups']
          },
          groups: result.data,
        };
        this.$emit('status', true);
        return;
      }

      if(!this.selectedSearchOptions.configured) {
        // Search hasn't been configured, so we might need to override the default recordTypes and fields
        if(_some(this.terms, x => x.includes('@'))) {
          // email
          this.selectedSearchOptions.fields = ['email'];
          this.selectedSearchOptions.recordTypes = ['groups', 'profiles', 'invitations'];
        } else if(_some(this.terms, x => /^[0-9]+$/.test(x))) {
          // phone
          this.selectedSearchOptions.fields = ['phone'];
          this.selectedSearchOptions.recordTypes = ['groups', 'profiles'];
        } else {
          // reset the defaults
          this.selectedSearchOptions.fields = ['badgeNameFirst', 'badgeNameLast', 'nameFirst', 'nameLast'];
          this.selectedSearchOptions.recordTypes = ['groups', 'profiles', 'invitations'];
        }
      }

      const request = {
        url: '/search',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          terms: this.terms,
          fields: this.filteredFields,
          recordTypes: this.selectedSearchOptions.recordTypes,
          eventId: this.selectedSearchOptions.eventId,
        }
      }

      const result = await this.$api3(request);
      this.results = result.data;
      this.$emit('status', true);
    },
    updateAndSearch(newValue) {
      this.selectedSearchOptions = {...newValue};
      if( !this.searchTerm && !this.selectedSearchOptions.groupsWithNotes ) return [];
      this.performSearch();
    },
  },
	beforeCreate: function() {
		this.$emit('status', false);
	},
  created() {
    this.selectedSearchOptions.eventId = this.globals.event.id;

    if (this.terms.length) {
      this.performSearch();
    }
  },
	mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
</style>
