<template>
	<div>
		<h2>Hotel Reservations <small v-if="hotelReservations.total" class="text-muted">Found {{ hotelReservations.total }}</small></h2>
		<b-table
      id="hotel-reservations-table"
			striped
			:items="hotelReservations.data"
			:fields="fields" 
			per-page="20"
			:current-page="currentPage"
		>
      <template v-slot:cell(checkIn)="data">
        {{ $dateHelper(data.item.checkIn, '$date') }}
      </template>
      <template v-slot:cell(checkOut)="data">
        {{ $dateHelper(data.item.checkOut, '$date') }}
      </template>
			<template v-slot:cell(actions)="data">
				<b-button size="sm" variant="link" :to="'/registrations/'+data.item.registration.token" v-b-tooltip.hover title="View Registration">
            <b-icon icon="person-square" variant="dark"></b-icon>
        </b-button>
			</template>
		</b-table>

		<b-pagination
			v-if="hotelReservations.total > 20"
			v-model="currentPage"
			:total-rows="hotelReservations.total"
			per-page="20"
			aria-controls="hotel-reservations-table"
		></b-pagination>
	</div>
</template>

<script>
export default {
	name: 'HotelReservations',
	props: {
		globals: Object,
	},
	data: function() {
		return {
			fields: [
				{
					key: 'registration.profile.nameFull',
          label: 'Legal Name'
				},
        {
					key: 'registration.profile.badgeNameFull',
          label: 'Badge Name'
				},
        {
					key: 'checkIn',
          label: 'Check-In'
				},
        {
					key: 'checkOut',
          label: 'Check-Out'
				},
        {
          key: 'roomType',
        },
        {
					key: 'extraNights',
				},
        {
					key: 'notes',
				},
				{
					key: 'actions',
					label: ''
				}
			],
			hotelReservations: [],
      currentPage: 1,
		}
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: async function() {
    // Construct request
    const request = {
      url: '/hotel-reservations',
      headers: { Authorization: 'bearer ' + this.globals.accessToken},
      params: { $eager: '[registration.profile]', $limit: 1000 }
    }

    try {
      // Execute request
      const result = await this.$api3(request);

      // Update relevant data
      this.hotelReservations = result.data;
    } catch (error) {
        let message;

        if (error.response) {
          // Server responded
          message = 'Server Error: ' + error.response.data.message;
        } else {
          // No response or Error in request
          message = 'Axios: ' + error;
        }

        // Emit the error
        this.$emit('alert', { error: true, message: message });
      }
  },
	mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
</style>
