<template>
	<div>
		<h2>Dashboard</h2>
		<b-row>
			<b-col cols="6">
				<b-card
					:title="`Attendees: ${ dashboard.attendees.total.toLocaleString() }`"
				>
					<b-progress class="mb-1">
						<b-progress-bar :value="dashboard.attendees.paid" variant="success" />
						<b-progress-bar :value="dashboard.attendees.comp" variant="primary" />
					</b-progress>
					<p class="float-right mb-0">
						Comp: {{ dashboard.attendees.comp.toLocaleString() }}
					</p>
					<p class="mb-0">
						Paid: {{ dashboard.attendees.paid.toLocaleString() }}
					</p>
				</b-card>
			</b-col>
			<b-col cols="6">
				<b-card
					:title="`Dealers: ${ dashboard.dealers.total.toLocaleString() }`"
				>
					<b-progress class="mb-1">
						<b-progress-bar :value="dashboard.dealers.partial" variant="primary" />
						<b-progress-bar :value="dashboard.dealers.attendee" variant="success" />
					</b-progress>
					<p class="float-right mb-0">
						Attendee: {{ dashboard.dealers.attendee.toLocaleString() }}
					</p>
					<p class="mb-0">
						Non-Attendee: {{ dashboard.dealers.partial.toLocaleString() }}
					</p>
				</b-card>
			</b-col>
		</b-row>
		<p class="text-muted mt-2">Updated {{ updatedString }} ago.</p>
	</div>
</template>

<script>
import { formatDistance } from 'date-fns';
import { useTimestamp } from '@vueuse/core';

export default {
	name: 'Dashboard',
	components: {},
	props: {
		globals: Object
	},
	methods: {
		loadHandler: async function() {
			// Construct request
			const request = {
				url: '/dashboard',
				headers: { Authorization: 'bearer ' + this.globals.accessToken},
			}

			// Execute request
			const result = await this.$api3(request).catch((error) => {
				console.log(error);

				if(error.response.status === 401) {
				this.$emit('logout');
				}
			});

			// Update relevant data
			this.dashboard = result.data;

			this.updated = Date.now();
			this.$emit('status', true);
		},
		useTimestamp,
		formatDistance
	},
	data() {
		return {
			dashboard: {},
			updated: null,
		};
	},
	computed: {
		updatedString() {
			return formatDistance(new Date(useTimestamp().value), new Date(this.updated));
		}
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: async function() {
		this.loadHandler();
	},
	activated: async function() {
		const ageInMinutes = ( Date.now() - this.updated ) / 1000 / 60;

		if (ageInMinutes < 10) return;

		this.$emit('status', false);
		this.loadHandler();
	}
};
</script>

<style>
</style>
