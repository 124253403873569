<template>
  <div>
    <b-card class="mb-3 mr-3" style="width: 250px">
      <b-card-title>
        {{ showtime.timeDisplayOverride || $dateHelper(showtime.startTime, '$showTime') }}
      </b-card-title>
      <capacity-segment-row v-for="capacitySegment in showtime.capacitySegments" :key="capacitySegment.id" :globals="globals" :show="show" :showtime="showtime" :capacitySegment="capacitySegment" />
    </b-card>
  </div>
</template>

<script>

import CapacitySegmentRow from "./CapacitySegmentRow.vue"

export default {
  name: 'ShowtimeCard',
  components: {
    CapacitySegmentRow
  },
  props: {
    globals: Object,
    show: Object,
    showtime: Object,
    capacity: Number,
  },
  computed: {
    adjustedCapacity() {
      return this.capacity + this.showtime.capacityAdjustment;
    },
    usedCapacity() {
      return this.adjustedCapacity - this.showtime.capacityRemaining;
    },
  }
}
</script>