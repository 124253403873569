<template>
  <b-modal id="link-stripe-customer"
    v-on:ok="save"
    v-on:hidden="reset"
    size="md"
    title="Link to Stripe"
    :ok-disabled="stripeCustomerId === null || stripeCustomerId === ''"
  >
    <b-form-group
      label="Stripe Customer ID"
      label-for="stripe-customer-id"
    >
      <b-form-input id="stripe-customer-id" v-model="stripeCustomerId" placeholder="cus_" />
    </b-form-group>
    <b-form-text v-if="error" text-variant="danger" class="text-center warning">{{ error }}</b-form-text>
  </b-modal>
</template>

<script>
export default {
  name: 'AddRegistrationTagsModal',
  data: function() {
    return {
      stripeCustomerId: null,
      error: null
    }
  },
  props: {
    globals: Object,
    account: Object,
  },
  methods: {
    save: async function(bvModalEvt) {
      bvModalEvt.preventDefault();

      try {
        let request = {
          url: `/accounts/${this.account.token}`,
          headers: { Authorization: 'bearer ' + this.globals.accessToken},
          method: 'patch',
          data: {
            stripeCustomerId: this.stripeCustomerId,
          },
        }

        // Execute request
        await this.$api3(request);

        this.$router.go();

      } catch (error) {
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.error = message;
      }
    },
    reset() {
      this.stripeCustomerId = '';
      this.error = '';
    }
  },
}
</script>

<style>

</style>