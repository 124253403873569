<template>
	<div>
		<b-nav vertical pills small class="my-3">
			<b-nav-item exact-active-class="active" to="/">Dashboard</b-nav-item>
			<b-nav-item active-class="active" to="/groups">Groups</b-nav-item>
      <b-nav-item active-class="active" to="/registrations">Registrations</b-nav-item>
			<b-nav-item active-class="active" to="/registrations/tags">Registration Tags</b-nav-item>
			<b-nav-item active-class="active" to="/past-due-transactions">Transactions</b-nav-item>
			<b-nav-item active-class="active" to="/dealer-booths">Booths</b-nav-item>
      <b-nav-item active-class="active" to="/hotel-reservations">Reservations</b-nav-item>
      <b-nav-item active-class="active" to="/waitlist-groups">Waitlist</b-nav-item>
      <b-nav-item active-class="active" to="/waitlist-invitations">Invitations</b-nav-item>
      <b-nav-item active-class="active" to="/scheduling">Scheduling</b-nav-item>
      <b-nav-item active-class="active" to="/shows">Shows</b-nav-item>
		</b-nav>
		<hr />
		<b-nav vertical pills small class="my-3">
			<b-nav-item active-class="active" to="/accounts/new">Accounts</b-nav-item>
			<b-nav-item active-class="active" to="/profiles">Profiles</b-nav-item>
			<b-nav vertical v-if="$route.path.startsWith('/profiles')" class="ml-2">
				<b-nav-item to="/profiles/tags">Tags</b-nav-item>
			</b-nav>
		</b-nav>
	</div>
</template>

<script>
export default {
	name: 'Sidebar'
}
</script>
