<template>
  <div :id="`capacity-segment-${capacitySegment.id}`" class="capacity-segment-row">
    <b-progress class="mb-1" :value="capacityUsed" :max="capacitySegment.capacity" :variant="variant" />
    <p class="text-muted">
      {{ capacitySegment.capacityRemaining }} / {{ capacitySegment.capacity }} Remaining
      <b-icon icon="pencil-square" class="float-right hover-icon mt-1" @click="$bvModal.show(`capacity-segment-modal-${capacitySegment.id}`)" />
    </p>

            <!-- <b-icon icon="pencil-square" class="hover-icon float-right" v-on:click="$bvModal.show(`showtime-modal-${showtime.id}`)"></b-icon> -->
    <b-tooltip v-if="capacitySegment.limitedToCapacitySegmentId" :target="`capacity-segment-${capacitySegment.id}`" :delay="{ show: 1000, hide: 0 }">
      Limited to<br />
      {{ capacitySegment.limitedToCapacitySegment.showtime.show.title }}<br />
      {{ $dateHelper(capacitySegment.limitedToCapacitySegment.showtime.startTime, '$showTime') }}
    </b-tooltip>

    <capacity-segment-modal :globals="globals" :show="show" :showtime="showtime" :capacitySegment="capacitySegment" />
  </div>
</template>

<script>

import CapacitySegmentModal from "./modals/CapacitySegmentModal.vue";

export default {
  name: 'CapacitySegmentRow',
  components: {
    CapacitySegmentModal,
  },
  data() {
    return {}
  },
  props: {
    globals: Object,
    show: Object,
    showtime: Object,
    capacitySegment: Object,
  },
  computed: {
    capacityUsed() {
      const { capacity, capacityRemaining } = this.capacitySegment;
      return capacity - capacityRemaining;
    },
    constrained() {
      const { capacity, capacityRemaining } = this.capacitySegment;
      return ( capacityRemaining / capacity ) <= 0.2;
    },
    variant() {
      if (this.capacitySegment.isAvailable === 0) {
        return 'danger';
      }

      if (this.constrained) {
        return 'warning';
      }

      return 'secondary';
    },
  }
}
</script>

<style scoped>
.capacity-segment-row:last-child p {
  margin-bottom: 0 !important;
}
.hover-icon {
  visibility: hidden;
}

.capacity-segment-row:hover .hover-icon {
  visibility: visible;
}

</style>