<template>
	<div>
    <b-row>
			<b-col cols="9">
        <h2>{{ dynamicTitle}} <small v-if="dynamicGroupCount" class="text-muted">{{ dynamicRegistrationCount }} People in {{ dynamicGroupCount }} Invitations</small></h2>
      </b-col>
			<b-col cols="3">
        <div class="text-right">
          <b-button variant="success" @click="loadModal()">
            <b-icon icon="plus"></b-icon>
          </b-button>
          <b-form-checkbox class="mt-2 mb-2" v-model="showInactive" name="Show Inactive" switch>
            <b-icon icon="calendar-x" variant="secondary"></b-icon>
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
		<b-table
      id="waitlist-invitations-table"
			striped
			:items="waitlistInvitations"
			:fields="fields" 
			per-page="20"
			:current-page="currentPage"
      empty-text="There are no Waitlist Invitations to show here."
      v-show="!showInactive"
		>
      <template v-slot:cell(email)="data">
        <a :href="`mailto:${data.item.email}`">{{ data.item.email }}</a>
      </template>
      <template v-slot:cell(created)="data">
        {{ $dateHelper(data.item.created) }}
      </template>
      <template v-slot:cell(expiration)="data">
        {{ $dateHelper(data.item.expiration, '$date') }}
      </template>
			<template v-slot:cell(actions)="data">
				<b-button size="sm" variant="link" v-b-tooltip.hover title="Edit Invitation">
            <b-icon icon="pencil-square" variant="dark" v-on:click="loadModal(data.item)"></b-icon>
        </b-button>
        <b-button size="sm" variant="link" v-b-tooltip.hover title="Use Invitation" :href="`https://app.magicconvention.com/register/invitation/${data.item.token}`" target="_blank">
            <b-icon icon="link" variant="dark"></b-icon>
        </b-button>
        <!-- http://localhost:8080/register/invitation/a78932b5587056ea3c39cd4a63b330a0 -->
			</template>
		</b-table>

    <b-pagination
			v-if="waitlistInvitations.length > 20"
			v-model="currentPage"
			:total-rows="waitlistInvitations.length"
			per-page="20"
			aria-controls="waitlist-invitations-table"
      v-show="!showInactive"
		></b-pagination>

    <b-table
      id="inactive-waitlist-invitations-table"
			striped
			:items="inactiveWaitlistInvitations"
			:fields="inactiveFields" 
			per-page="20"
			:current-page="currentInactivePage"
      empty-text="There are no Inactive Waitlist Invitations to show here."
      v-show="showInactive"
		>
      <template v-slot:cell(email)="data">
        <a :href="`mailto:${data.item.email}`">{{ data.item.email }}</a>
      </template>
      <template v-slot:cell(created)="data">
        {{ $dateHelper(data.item.created) }}
      </template>
      <template v-slot:cell(expiration)="data">
        {{ $dateHelper(data.item.expiration, '$date') }}
      </template>
      <template v-slot:cell(responded)="data">
        <template v-if="data.item.responded">{{ $dateHelper(data.item.responded) }}</template>
      </template>
			<template v-slot:cell(actions)="data">
				<b-button v-if="data.item.responded === null" size="sm" variant="link" v-b-tooltip.hover title="Edit Invitation">
            <b-icon icon="pencil-square" variant="dark" v-on:click="loadModal(data.item)"></b-icon>
        </b-button>
			</template>
		</b-table>

    <b-pagination
			v-if="inactiveWaitlistInvitations.length > 20"
			v-model="currentInactivePage"
			:total-rows="inactiveWaitlistInvitations.length"
			per-page="20"
			aria-controls="waitlist-invitations-table"
      v-show="showInactive"
		></b-pagination>

    <invitation-form-modal :globals="globals" :invitation="currentInvitation" :groupId="null" v-on:reload="loadHandler()" @alert="(event) => { this.$emit('alert', event); }" @hidden="currentInvitation = {}" />
	</div>
</template>

<script>
import { partition as _partition, sumBy as _sumBy } from 'lodash';
import InvitationFormModal from '../modals/InvitationFormModal.vue';

export default {
	name: 'WaitlistInvitations',
  components: { InvitationFormModal },
	props: {
		globals: Object,
	},
	data: function() {
		return {
			fields: [
        {
          key: 'nameFull',
          label: 'Name'
        },
        {
          key: 'email'
        },
        {
          key: 'size'
        },
        {
          key: 'created'
        },
        {
          key: 'expiration'
        },
				{
					key: 'actions',
					label: ''
				}
			],
      inactiveFields: [
        {
          key: 'nameFull',
          label: 'Name'
        },
        {
          key: 'email'
        },
        {
          key: 'size'
        },
        {
          key: 'created'
        },
        {
          key: 'responded'
        },
        {
          key: 'expiration'
        },
        {
					key: 'actions',
					label: ''
				}
      ],
			waitlistInvitations: [],
      inactiveWaitlistInvitations: [],
      showInactive: false,
      currentInvitation: undefined,
      currentPage: 1,
      currentInactivePage: 1,
		}
	},
  computed: {
    dynamicTitle() {
      return this.showInactive ? 'Inactive Waitlist Invitations' : 'Waitlist Invitations';
    },
    dynamicGroupCount() {
      return this.showInactive ? this.inactiveWaitlistInvitations.length : this.waitlistInvitations.length;
    },
    dynamicRegistrationCount() {
      return _sumBy( this.showInactive ? this.inactiveWaitlistInvitations : this.waitlistInvitations, 'size' )
    }
  },
  methods: {
    async loadHandler() {
      // Construct request
      const request = {
        url: '/invitations',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          '$sort[created]': 1,
          '$limit': 300,
          'groupId': 'null',
          eventId: this.globals.event.id,
        }
      }

      // Execute request
      const result = await this.$api3(request).catch((error) => {
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      });

      // Split out results
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      
      const partitionedArray = _partition(result.data.data, (o) => {
        return !(o.responded !== null || new Date(o.expiration) <= yesterday)
      });

      // Update relevant data
      this.waitlistInvitations = partitionedArray[0];
      this.inactiveWaitlistInvitations = partitionedArray[1];
    },
    loadModal(invitation) {
      this.currentInvitation = invitation;
      this.$bvModal.show('invitation-form')
    }
  },
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: async function() {
    this.loadHandler();
  },
	mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
</style>
