<template>
  <validation-observer v-slot="{invalid, pristine}">
    <b-modal id="comp-reason-form"
      v-on:show="copy"
      v-on:ok="save"
      v-on:hidden="reset"
      size="md"
      title="Comp Reason"
      :ok-disabled="invalid || pristine"
    >
      <validation-provider>
        <b-form-input autofocus id="comp-reason" v-model="compReason"></b-form-input>
      </validation-provider>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  name: 'CompReasonFormModal',
  components: {
    ValidationProvider, ValidationObserver
  },
  data: function() {
    return {
      compReason: '',
    }
  },
  props: {
    globals: Object,
    value: String,
    registrationId: Number,
  },
  methods: {
    copy() {
      this.compReason = this.value;
    },
    save: async function(bvModalEvt) {
      const request = {
        url: `/registrations/${this.$route.params.id}`,
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'patch',
        data: { compReason: this.compReason },
      }

      try {
        // Execute request
				const result = await this.$api3(request);

        // Update relevant data
        this.$emit('input', result.data.compReason);
      } catch (error) {
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      } finally {
        // Either way, close the model
        bvModalEvt.vueTarget.hide();
      }
    },
    reset() {
      this.compReason = '';
    }
  },
}
</script>

<style>

</style>