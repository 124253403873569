const plaintextTerms =
'Terms & Conditions\r\n' + 
'\r\n'+ 
'All attendees of MAGIC Live in 2024 must agree to the following:\r\n' +
'\r\n'+ 
'1. Conduct:\r\n' +
'We want MAGIC Live to be a safe, supportive environment for everyone. Guests are expected to respect common courtesy and refrain from abusive or harmful language or behavior.\r\n' + 
'\r\n'+ 
'2. Health and Safety.\r\n' +
'All attendees will be required to follow public health policies set by the State of Nevada, Clark County, and The Orleans Hotel.\r\n' +
'\r\n'+ 
'3. Identification.\r\n' +
'Photo ID is required when checking in at the convention.\r\n' +
'\r\n'+ 
'4. Badges.\r\n' +
'To attend any part of MAGIC Live, you must be wearing your badge with name/photo side facing out. Only you are authorized to use your badge, meaning no sharing, giving, or selling it to anyone else. We reserve the right to revoke, without refund, any badges that we determine are being used by someone other than the registrant whose name appears on it.\r\n' +
'\r\n'+ 
'5. Refunds.\r\n' +
'Your registration is 90% refundable through June 3, 2024. Refunds are not available after June 3, 2024. Unfortunately, we are unable to make any exceptions, including medical emergencies. We will, however, facilitate the transfer of your registration to a person of your choice prior to the start of the event.\r\n' +
'\r\n'+ 
'6. Transfers.\r\n' +
'Transfers of registrations, from the Original Registrant to a Replacement Registrant, must be made through the MAGIC Live office, prior to the start of the event (7:30 p.m., Sunday, August 4, 2024).\r\n' +
'\r\n'+ 
'7. Email.\r\n' +
'We will contact you using the email address listed on your registration for all matters regarding this convention. Please be sure you can receive messages from Live@MagicConvention.com.\r\n' +
'\r\n'+ 
'8. Photography and Video.\r\n' +
'Non-flash photography is allowed during sessions, but not during shows. There is no video recording, audio recording, or live streaming at any of our sessions or shows.\r\n' +
'\r\n'+ 
'9. Sales.\r\n' +
'Only authorized MAGIC Live dealers are allowed to transact sales of any kind at the convention. The unauthorized sale of products or services, or circulation of promotional literature, anywhere on The Orleans property is prohibited by both the hotel and the event.\r\n' +
'\r\n'+ 
'10. Parties.\r\n' +
'The Orleans Hotel does not allow hospitality suites or business-related parties of any kind in hotel rooms or suites.\r\n' +
'\r\n'+ 
'11. Cancellations.\r\n' +
'We reserve the right to cancel, without refund, the registration of any attendee who does not follow these terms and conditions.';

export { plaintextTerms };