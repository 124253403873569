<template>
    <b-card class="mb-3 mr-3">
        <b-card-title>
            Account Merge
        </b-card-title>
        <b-form-group
            label="Merge From Account ID"
            label-for="merge-from-account-id">
            <b-form-input type="number" id="merge-from-account-id" v-model.number="mergeFromAccountId" @change="retrieveFromAccount"></b-form-input>
            <b-form-text>{{ mergeFromAccount?.primaryProfile?.badgeNameFull }}</b-form-text>
        </b-form-group>
        <b-form-group
            label="Merge To Account ID"
            label-for="merge-to-account-id">
            <b-form-input type="number" id="merge-to-account-id" v-model.number="mergeToAccountId" @change="retrieveToAccount"></b-form-input>
            <b-form-text>{{ mergeToAccount?.primaryProfile?.badgeNameFull }}</b-form-text>
        </b-form-group>
        <b-button @click="mergeAccounts">Merge Accounts</b-button>
    </b-card>
</template>

<script>
export default {
  name: 'AccountMergeTool',
  components: {
    
  },
  data: function() {
    return {
      mergeFromAccountId: 0,
      mergeFromAccount: {},
      mergeToAccountId: 0,
      mergeToAccount: {}
    }
  },
  props: {
    globals: Object,
  },
  computed: {

  },
  methods: {
    retrieveFromAccount: async function() {
        this.mergeFromAccount = await this.retrieveAccount(this.mergeFromAccountId);
    },
    retrieveToAccount: async function() {
        this.mergeToAccount = await this.retrieveAccount(this.mergeToAccountId);
    },
    retrieveAccount: async function(accountId) {
        const request = {
            url: '/accounts',
            headers: { Authorization: 'bearer ' + this.globals.accessToken},
            params: {
                'id': accountId,
                '$eager': '[primaryProfile]',
            }
        }

        const response = await this.$api3(request).catch(error => {
          let message;

          if (error.response) {
            // Server responded
            if(error.response.status === 401) {
              this.$emit('logout');
            }

            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error
          this.$emit('alert', { error: true, message: message });
        });
        return response.data.data[0];
    },
    mergeAccounts: async function() {
        if (!this.mergeFromAccountId || !this.mergeToAccountId) {
            return false;
        }

        const confirmation = await this.$bvModal.msgBoxConfirm(`Merging Account ${this.mergeFromAccountId} into Account ${this.mergeToAccountId} cannot be undone.`, {
            title: 'Are you sure?',
            okVariant: 'danger',
            okTitle: 'Merge',
            centered: true,
        });

        if(!confirmation) {
            return false;
        }

        console.log('Do it');
    },
    resetTool: async function() {
        this.mergeFromAccountId = 0;
        this.mergeFromAccount = {};
        this.mergeToAccountId = 0;
        this.mergeToAccount = {};
    }
  }
}
</script>