<template>
  <b-modal
  id="add-ticket-modal"
  centered
  :title="modalTitle"
  v-on:ok.prevent="createTicket"
  :ok-disabled="capacitySegment === null"
>
  <p>For {{ registrationNames}}</p>
  <b-form-group
    label="Show"
    label-for="input-show-id">
    <b-form-select
      id="input-show-id"
      v-model="showtimes"
      :options="shows"
      value-field="showtimes"
      text-field="title"
      @change="capacitySegment = null"
    >
      <template v-slot:first>
        <b-form-select-option :value="null" disabled />
      </template>
    </b-form-select>
  </b-form-group>
  <b-form-group
    label="Showtime"
    label-for="input-show-id">
    <b-form-select
      id="input-show-id"
      :disabled="showtimes.length === 0"
      v-model="capacitySegment"
    >
      <template v-for="showtime in showtimes">
        <b-form-select-option v-for="capacitySegment in showtime.capacitySegments" :key="capacitySegment.id" :value="capacitySegment">{{ showtime.timeDisplayOverride || $dateHelper(showtime.startTime, '$showTime') }}: {{ capacitySegment.capacityRemaining }} Remaining <template v-if="capacitySegment.limitedToCapacitySegmentId">[Limited to {{ capacitySegment.limitedToCapacitySegment.showtime.show.title }} {{ $dateHelper(capacitySegment.limitedToCapacitySegment.showtime.startTime, '$showTime') }}]</template><template v-if="!capacitySegment.isAvailable">[Unavailable]</template></b-form-select-option>
      </template>
      <template v-slot:first>
        <b-form-select-option value="" disabled />
      </template>
    </b-form-select>
  </b-form-group>
</b-modal>
</template>

<script>

export default {
  name: 'AddTicketModal',
  data() {
    return {
			shows: [],
      showtimes: [],
      capacitySegment: null,
      error: null,
    }
  },
  computed: {
    modalTitle() {
      return 'Add Ticket' + ( this.registrations.length > 1 ? 's' : '' );
    },
    registrationNames() {
			let result = "";

			this.registrations.forEach((registration, index) => {
				result += registration.profile.badgeNameFull;

				if (index < this.registrations.length -1 && this.registrations.length > 2) {
					result += ',';
				}

				if (index == this.registrations.length -2) {
					result += ' and ';
				} else if (index < this.registrations.length -1) {
					result += ' ';
				}
			})

			return result;
    },
  },
  methods: {
    async findShows() {
      // Create request
      const request = {
        url: '/shows',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        params: { $eager: 'showtimes.capacitySegments.limitedToCapacitySegment.showtime.show', eventId: this.globals.event.id }
      }; 
      
      // Execute request
      const response = await this.$api3(request).catch((error) => {
        // Handle error in executing request
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      });

      // Update data
			this.shows = response.data.data;
    },
    async createTicket(bvModalEvt) {
      // Create request
      let batchRequest = {
        url: '/batch',
        method: 'post',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        data: {
          calls: [],
        }
      };

      for (const registration of this.registrations) {
        batchRequest.data.calls.push([
          'create',
          'tickets',
          {
            showId: this.capacitySegment.showId,
            capacitySegmentId: this.capacitySegment.id,
            registrationId: registration.id
          }
        ])
      }
      
      // Execute request
      await this.$api3(batchRequest).catch((error) => {
        // Handle error in executing request
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      });

      // Close model and trigger refresh
      bvModalEvt.vueTarget.hide();
      this.$emit('refresh');
    }
  },
  props: {
    globals: Object,
    registrations: Array,
  },
  async created() {
    // Find shows
    this.findShows();
  }
}
</script>