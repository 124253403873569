<template>
	<div>
		<h2>Registration Tags</h2>
		<b-badge v-for="tag in tags" :key="tag.id" variant="primary" class="mr-1" :to="'/registrations?tag='+tag.slug">{{ tag.name }} ({{ tag.registrationsActive.length }})</b-badge>
		<b-badge variant="secondary" v-b-modal.new-tag href="#">+</b-badge>

		<b-modal id="new-tag"
			v-on:ok="createTag"
			v-on:hidden="newTagName = null"
			size="sm"
			title="New Registration Tag"
		>
			<b-form-input v-model="newTagName"></b-form-input>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'RegistrationTags',
	props: {
		globals: Object,
	},
	data: function() {
		return {
			tags: [],
			tagCount: 0,
			newTagName: null,
		}
	},
	methods: {
		createTag: async function() {
      // Construct request
      const request = {
        url: '/registration-tags',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'post',
        data: {
          name: this.newTagName,
          eventId: this.globals.event.id,
          categoryId: 2, // #ToDo: Fix hardcoded value
        },
      }

      // Execute request
      this.$api3(request).then(() => {
        this.loadHandler();
      }).catch((error) => {
				let message = 'Tag could not be created.';

				if (error.response) {
					// Server responded
					message += ' Server Response: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message += ' Request Response: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      })
		},
    loadHandler: async function() {
      // Construct request
      const request = {
        url: '/registration-tags',
        method: 'get',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        params: {
          eventId: this.globals.event.id,
          $eager: 'registrationsActive',
          $limit: 100,
        }
      }

      // Execute request
      const result = await this.$api3(request);

      // Update relevant data
      this.tags = result.data.data;
      this.tagCount = result.data.total;
    }
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: async function() {
    this.loadHandler();
	},
	mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
</style>
