<template>
  <validation-observer v-slot="{invalid, pristine}">
    <b-modal id="hotel-reservation-form"
      v-on:show="copyToForm"
      v-on:ok="addOrSave"
      v-on:hidden="resetForm"
      size="sm"
      :title="title"
      :ok-disabled="invalid || pristine"
    >
      <validation-provider rules="required" v-slot="v">
        <b-form-group
          label="Check-In"
          label-for="hotel-reservation-check-in">
          <b-form-datepicker id="hotel-reservation-check-in" v-model="hotelReservationForm.checkIn" :state="v.errors.length > 0 ? false : null"></b-form-datepicker>
          <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider rules="required" v-slot="v">
        <b-form-group
          label="Check-Out"
          label-for="hotel-reservation-check-out">
          <b-form-datepicker id="hotel-reservation-check-out" v-model="hotelReservationForm.checkOut" :state="v.errors.length > 0 ? false : null"></b-form-datepicker>
          <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider rules="required" v-slot="v">
        <b-form-group
          label="Room Type"
          label-for="hotel-reservation-room-type">
          <b-form-input type="text" id="hotel-reservation-room-type" v-model="hotelReservationForm.roomType" :state="v.errors.length > 0 ? false : null"></b-form-input>
          <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider v-slot="v">
        <b-form-group
          label="Extra Nights"
          label-for="hotel-reservation-extra-nights">
          <b-form-input type="text" id="hotel-reservation-extra-nights" v-model="hotelReservationForm.extraNights" :state="v.errors.length > 0 ? false : null"></b-form-input>
          <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider v-slot="v">
        <b-form-group
          label="Notes"
          label-for="hotel-reservation-notes">
          <b-form-input type="text" id="hotel-reservation-notes" v-model="hotelReservationForm.notes" :state="v.errors.length > 0 ? false : null"></b-form-input>
          <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
	...required,
	message: 'This field is required'
})

export default {
  name: 'HotelReservationFormModal',
  components: {
    ValidationProvider, ValidationObserver
  },
  data: function() {
    return {
      hotelReservationForm: {},
    }
  },
  props: {
    globals: Object,
    value: Object,
    registrationId: Number,
  },
  computed: {
    title() {
      return (this.value === null ? 'Add' : 'Edit') + ' Hotel Reservation';
    },
  },
  methods: {
    copyToForm() {
      this.hotelReservationForm = { ...this.value };
    },
    addOrSave(bvModalEvt) {
      const action = this.value === null ? this.createHotelReservation : this.patchHotelReservation;
      action(bvModalEvt);
    },
    createHotelReservation: async function(bvModalEvt) {
      const request = {
        url: '/hotel-reservations',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'post',
        data: { registrationId: this.registrationId, ...this.hotelReservationForm },
      }

      try {
        // Execute request
				const result = await this.$api3(request);

        // Update relevant data
				this.$emit('input', result.data);
      } catch (error) {
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      } finally {
        // Either way, close the model
        bvModalEvt.vueTarget.hide();
      }
    },
    patchHotelReservation: async function(bvModalEvt) {
      const changes = this.diffObjects(this.hotelReservationForm, this.value);
      
      const request = {
        url: `/hotel-reservations/${this.value.id}`,
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'patch',
        data: changes,
      }

      try {
        // Execute request
				const result = await this.$api3(request);

        // Update relevant data
        this.$emit('input', result.data);
      } catch (error) {
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      } finally {
        // Either way, close the model
        bvModalEvt.vueTarget.hide();
      }
    },
    resetForm() {
      this.hotelReservationForm = {};
    },
    diffObjects: function(edited, original) {
			// Determines if any of the values in edited differ from those in original
			const changes = {};

			// If there aren't two objects, return the edited object in full
			if (typeof (edited) !== 'object' || typeof (original) !== 'object') return changes;

			for (let i in edited) {
        if (edited[i] == original[i]) continue;
        
        // Set the changed value, converting blank strings to null
				changes[i] = edited[i] === "" ? null : edited[i];
			}

			return changes;
		}
  },
}
</script>

<style>

</style>