<template>
	<div id="app">
    <div id="authenticated" v-if="globals.accessToken">
      <Header v-on:search="search" :globals="globals"></Header>
      <b-container fluid>

        <b-row>
          <b-col cols="2" lg="1" class="mr-3 overflow-hidden">
            <Sidebar></Sidebar>
          </b-col>
          <b-col class="my-3">
            <div v-show="error" class="text-center">
              <h1>Something Went Wrong</h1>
              <p>{{ error }}</p>
            </div>
            <b-alert v-for="alert in alerts" :key="alert.message" :variant="alert.error ? 'danger' : 'success'" show dismissible>
              <span class="h5 mr-3">
                <b-icon :icon="alert.error ? 'exclamation-triangle-fill' : 'check-circle'"></b-icon>
              </span>
              {{ alert.message }}
            </b-alert>
            <div v-show="!error && loading" class="text-center">
              <b-spinner type="grow"></b-spinner>
              <p>Loading</p>
            </div>
            <div v-show="!error && !loading">
              <keep-alive include="Dashboard">
                <router-view :key="$route.fullPath" v-if="globals.event.id" :globals="globals" :searchTerm="searchTerm" v-on:status="updateStatus" v-on:alert="alert" v-on:logout="onLogout"></router-view>
              </keep-alive>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <anonymous-header />
      
      <b-container fluid>
        <b-row align-h="center">
          <b-col   sm="6" md="4" lg="4" xl="3">
            <h3 class="mt-3">Please Login</h3>
            <b-form @submit.prevent="onLogin">
              <b-form-group
                label="Email"
                label-for="auth.email">
                <b-form-input type="email" id="auth.email" v-model="auth.email"></b-form-input>
              </b-form-group>
              <b-form-group
                label="Password"
                label-for="auth.password">
                <b-form-input type="password" id="auth.password" v-model="auth.password"></b-form-input>
              </b-form-group>
              <p v-if="auth.error" class="text-danger">{{ auth.error }}</p>
              <b-button type="submit">Login</b-button>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container fluid class="fixed-bottom mb-2 text-muted" id="app-info">
      <b-row>
        <b-col>
          1.0.0 | 
          <span class="d-block d-sm-none">XS</span>
          <span class="d-none d-sm-inline d-md-none">SM</span>
          <span class="d-none d-md-inline d-lg-none">MD</span>
          <span class="d-none d-lg-inline d-xl-none">LG</span>
          <span class="d-none d-xl-inline">XL</span>
          | {{ env }} | {{ globals.endpoint }}
        </b-col>
      </b-row>
    </b-container>
	</div>
</template>

<script>
import Header from './components/Header';
import AnonymousHeader from './components/AnonymousHeader';
import Sidebar from './components/Sidebar';

import LogRocket from 'logrocket';
import jwt_decode from "jwt-decode";

export default {
	name: 'App',
	components: {
		Header, AnonymousHeader, Sidebar
	},
	created: async function() {
    // Override API endpoint
    if ( process.env.VUE_APP_ENDPOINT ) {
      this.globals.endpoint = process.env.VUE_APP_ENDPOINT;
    }

    // Handle v3 auth
    if (localStorage.accessToken) {
      this.globals.accessToken = localStorage.accessToken;
      this.afterLogin();
    }

    // Intercept requests on the custom axios handler and show logout
    this.$api3.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.onLogout();
        }
        
        return Promise.reject(error);
      }
    )
	},
	props: {
		
	},
	watch: {
		$route() {
			// ToDo Clear alerts
			// this.alerts = [];
		}
	},
	data: function() {
		return {
			globals: {
				endpoint: 'https://api.magicconvention.com', // Default
        accessToken: false,
        account: {},
        event: {}
			},
      auth: {
        email: '',
        password: '',
        error: false,
      },
			loading: true,
			error: false,
			alerts: [],
			searchTerm: null
		}
	},
	methods: {
		search: function(searchTerm) {
			if(this.$route.fullPath != '/search') this.$router.push('/search');
			this.searchTerm = searchTerm;
		},
		updateStatus: function(status) {
			if(status === true) {
				this.loading = false;
				this.error = false;
			} else if(status === false) {
				this.loading = true;
				this.error = false;
			} else {
				this.error = status;
			}
		},
		alert: function(alert) {
			this.alerts.unshift(alert);
		},
    async onLogin() {
      this.auth.error = false;
      const request = {
        url: '/authentication',
        method: 'post',
        data: {
          strategy: 'local',
          email: this.auth.email,
          password: this.auth.password,
        },
      }

      // Execute request
      try {
        const response = await this.$api3(request);

        // Store changes in local data
        this.globals.accessToken = response.data.accessToken;
        this.globals.account = response.data.account;
        this.auth = {
          email: '',
          password: '',
          error: false,
        }

        // Store changes in localStorage
        localStorage.accessToken = response.data.accessToken;

        // Perform additional tasks after authencitation
        this.afterLogin();

      } catch (error) {
        console.log(error);
        if (error.response) {
          this.auth.error = `${error.response.data.message} (${error.response.data.name})`;
        } else if (error.request) {
          this.auth.error = 'Network error. Please refresh the page and try again.';
        } else {
          this.auth.error = 'Unknown error. Please refresh the page and try again.';
        }
      }
    },
    async afterLogin() {
      // Called after the login tasks are completed, so this.globals.accessToken is available

      // If not already present because of a fresh login, fetch the account
      if (!this.globals.account.id) {
        const { sub: accountToken } = jwt_decode(this.globals.accessToken);

        const accountRequest = {
          url: `/accounts/${accountToken}`,
          headers: {'Authorization': 'bearer ' + this.globals.accessToken}
        }

        const accountResponse = await this.$api3(accountRequest).catch((error) => {
          // Handle error in executing request
          let message;

          if (error.response) {
            // Server responded
            message = 'Server Error: ' + error.response.data.message;
            if (error.response.status === 404) {
              // Account doesn't exist. User probably switched environments.
              this.onLogout();
            }
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error and close the modal
          this.$emit('alert', { error: true, message: message });
          console.log(message);
        });

        this.globals.account = accountResponse.data;
      }
      
      // Identify LogRocket session
      LogRocket.identify(this.globals.account.token, {
        email: this.globals.account.email
      })

      // Fetch the current event ID
      const eventIdRequest = {
        url: '/global-settings/current-event-id',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken}
      };

      const eventIdResponse = await this.$api3(eventIdRequest).catch((error) => {
        // Handle error in executing request
        let message;

        if (error.response) {
          // Server responded
          message = 'Server Error: ' + error.response.data.message;
        } else {
          // No response or Error in request
          message = 'Axios: ' + error;
        }

        // Emit the error and close the modal
        this.$emit('alert', { error: true, message: message });
        console.log(message);
      });

      const eventId = eventIdResponse.data.value;

      // Fetch the current event
      const eventRequest = {
        url: `/events/${eventId}`,
        headers: {'Authorization': 'bearer ' + this.globals.accessToken}
      };

      const eventResponse = await this.$api3(eventRequest).catch((error) => {
        // Handle error in executing request
        let message;

        if (error.response) {
          // Server responded
          message = 'Server Error: ' + error.response.data.message;
        } else {
          // No response or Error in request
          message = 'Axios: ' + error;
        }

        // Emit the error and close the modal
        this.$emit('alert', { error: true, message: message });
        console.log(message);
      });

      this.globals.event = eventResponse.data;
    },
    onLogout() {
      this.globals.accessToken = null;
      this.globals.account = {};
      localStorage.clear();
    }
	},
  computed: {
    env() {
      const value = process.env.NODE_ENV;
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
};
</script>

<style>
  #app-info {
    font-size: 0.7em;
  }
</style>
