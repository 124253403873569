<template>
	<div>
		<h2>Dealer Booths <small v-if="dealerBooths.total" class="text-muted">Found {{ dealerBooths.total }}</small></h2>
		<b-table
			id="profiles-table"
			striped
			:items="dealerBooths.data"
			:fields="fields" 
			per-page="20"
			:current-page="currentPage"
		>
			<template v-slot:cell(actions)="data">
				<b-button size="sm" variant="link" :to="'/groups/'+data.item.group.token" v-b-tooltip.hover title="View Group">
            <b-iconstack class="mr-3" variant="dark">
              <b-icon stacked icon="folder-fill"></b-icon>
              <b-icon stacked icon="person-fill" scale="0.55" shift-v="-0.4" variant="white"></b-icon>
						</b-iconstack>	
        </b-button>
			</template>
		</b-table>

		<b-pagination
			v-if="dealerBooths.total > 20"
			v-model="currentPage"
			:total-rows="dealerBooths.total"
			per-page="20"
			aria-controls="profiles-table"
		></b-pagination>
	</div>
</template>

<script>
export default {
	name: 'DealerBooths',
	props: {
		globals: Object,
	},
	data: function() {
		return {
			fields: [
				{
					key: 'company',
				},
				{
					key: 'actions',
					label: ''
				}
			],
			dealerBooths: [],
      currentPage: 1,
		}
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: async function() {
    // Construct request
    const request = {
		url: '/dealer-booths',
		headers: { Authorization: 'bearer ' + this.globals.accessToken},
		params: {
			$eager: 'group',
			$joinRelation: 'group',
			$limit: 100,
			'$sort[company]': 1,
			'group.eventId': this.globals.event.id,
		}
    }

    try {
      // Execute request
      const result = await this.$api3(request);

      // Update relevant data
      this.dealerBooths = result.data;
    } catch (error) {
      let message;

      if (error.response) {
        // Server responded
        if(error.response.status === 401) {
          this.$emit('logout');
        }

        message = 'Server Error: ' + error.response.data.message;
      } else {
        // No response or Error in request
        message = 'Axios: ' + error;
      }

      // Emit the error
      this.$emit('alert', { error: true, message: message });
    }
  },
	mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
</style>
