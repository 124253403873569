<template>
	<div>
		<h2>Profiles <small v-if="profileTotal" class="text-muted">Found {{ profileTotal }} <template v-if="tag.id">Tagged as "{{ tag.name }}"</template></small></h2>
		<b-table
			id="profiles-table"
			striped
			:items="profiles"
			:fields="profileFields" 
			per-page="20"
			:current-page="currentPage"
		>
			<template v-slot:cell(id)="data">
				<a :to="'profiles/' + data.item.id">{{ data.item.id }}</a>
			</template>
			<template v-slot:cell(name)="data">
				{{ data.item.nameFirst }} {{ data.item.nameLast ? ' ' + data.item.nameLast : '' }}
			</template>
			<template v-slot:cell(badgeName)="data">
				{{ data.item.badgeNameFirst }} {{ data.item.badgeNameLast ? ' ' + data.item.badgeNameLast : '' }}
			</template>
			<template v-slot:cell(actions)="data">
				<b-button size="sm" variant="link" :to="'/profiles/'+data.item.id" v-b-tooltip.hover title="View Profile"><b-icon-person-lines-fill variant="dark"></b-icon-person-lines-fill></b-button>
			</template>
		</b-table>

		<b-pagination
			v-if="profileTotal > 20"
			v-model="currentPage"
			:total-rows="profileTotal"
			per-page="20"
			aria-controls="profiles-table"
		></b-pagination>
	</div>
</template>

<script>
export default {
	name: 'Profiles',
	props: {
		globals: Object,
	},
	methods: {},
	data: function() {
		return {
			profileFields: [
				{
					key: 'name',
					label: 'Legal Name',
				},
				{
					key: 'badgeName',
					label: 'Badge Name',
				},
				{
					key: 'email',
				},
				{
					key: 'actions',
					label: ''
				}
			],
			profiles: [],
			profileTotal: 0,
			currentPage: 1,
      tag: {},
		}
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: async function() {
		if (this.$route.query.tag) {
      // Construct request
      const request = {
        url: '/profile-tags',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: { slug: this.$route.query.tag, $eager: 'profiles' }
      }

      // Execute request
      const result = await this.$api3(request).catch((error) => {
        console.log(error);
      });

      // Update relevant data
      this.profiles = result.data.data[0].profiles;
      delete(result.data.data[0].profiles);
      this.tag = result.data.data[0];
      this.$emit('status', true);
      this.profileTotal = this.profiles.length;
		}
	},
	mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
</style>
