<template>
	<div>
		<h2>Scan Tickets</h2>
		<b-row>
			<b-col cols="6">
				<b-form-group label="Select Show" label-for="show-select">
					<b-form-select
						id="show-select"
						v-model="selectedShowId"
						:options="shows"
						value-field="id"
						text-field="title"
					>
						<template #first>
							<b-form-select-option :value="null">Select a show</b-form-select-option>
						</template>
					</b-form-select>
				</b-form-group>

				<b-form-group label="Select Showtime" label-for="showtime-select">
					<b-form-select
						id="showtime-select"
						v-model="selectedShowtimeId"
						:options="showtimeOptions"
						:disabled="!selectedShowId"
					></b-form-select>
				</b-form-group>

				<b-form @submit.prevent="handleScan">
					<b-form-group
						label="Scanned Barcode"
						label-for="scanned-barcode-input"
					>
						<b-form-input
							id="scanned-barcode-input"
							v-model="scannedBarcode"
							type="text"
							required
							placeholder="Scan or enter barcode"
							ref="barcodeInput"
							:disabled="!selectedShowtimeId"
							autofocus
							@focus="onBarcodeInputFocus"
							@blur="onBarcodeInputBlur"
						></b-form-input>
					</b-form-group>
					<b-button type="submit" variant="primary" :disabled="!selectedShowtimeId">Submit</b-button>
				</b-form>
			</b-col>
			
			<b-col cols="6">
				<b-card
					:bg-variant="cardBgVariant"
					text-variant="white"
					class="mb-3"
				>
					<div class="d-flex align-items-center justify-content-center">
						<b-spinner v-if="working" small class="mr-2"></b-spinner>
						<h5 class="mb-0">{{ cardStatusText }}</h5>
					</div>
				</b-card>
				
				<b-card title="Last Scan" bg-variant="light">
					<div v-if="working" class="d-flex justify-content-center align-items-center">
						<b-spinner variant="primary" label="Processing..."></b-spinner>
					</div>
					<template v-else>
						<template v-if="error">
							<h5 class="text-danger">Error:</h5>
							<p>{{ error }}</p>
						</template>
						<template v-else-if="lastScannedTicket">
							<h5>{{ lastScannedTicket.registration.profile.badgeNameFull }}</h5>
							<p v-if="lastScannedTicket.capacitySegment.showtime.id !== selectedShowtimeId">Ticket used as Standby.</p>
						</template>
						<template v-else>
							<p>No scan data available</p>
						</template>
					</template>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { formatDistance } from 'date-fns';

export default {
	name: 'ScanTickets',
	components: {},
	data() {
		return {
			scannedBarcode: '',
			shows: [],
			selectedShowId: null,
			selectedShowtimeId: null,
			working: false,
			error: false,
			isBarcodeInputFocused: false,
			lastScannedTicket: null,
			isWindowFocused: true,
		};
	},
	props: {
		globals: Object
	},
	methods: {
		playSound(frequency = 800, duration = 200, volume = 0.5) {
			try {
				const audioContext = new (window.AudioContext || window.webkitAudioContext)();
				const oscillator = audioContext.createOscillator();
				const gainNode = audioContext.createGain();

				oscillator.type = 'sine';
				
				// Ensure frequency is a finite number
				oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime);
				
				// Ensure volume is a finite number between 0 and 1
				gainNode.gain.setValueAtTime(volume, audioContext.currentTime);

				oscillator.connect(gainNode);
				gainNode.connect(audioContext.destination);

				oscillator.start();
				
				// Ensure duration is a finite, positive number
				setTimeout(() => {
					oscillator.stop();
					audioContext.close();
				}, duration);
			} catch (error) {
				console.error('Error playing sound:', error);
			}
		},

		playSuccessSound() {
			this.playSound(1600, 200, 0.5);
		},

		playErrorSound() {
			this.playSound(300, 800, 0.5);
		},

		handleScan() {
			this.working = true;  // Set working to true when form is submitted
			this.error = false;   // Reset error state
			const barcode = this.scannedBarcode;

			if (barcode[1] === '-') {
				this.handleStub(barcode);
			} else if (barcode[barcode.length - 2] === 'C') {
				this.handleCustomerServiceTicket(barcode);
			} else {
				this.handleTicket(barcode);
			}
		},
		handleStub(barcode) {
				this.resetScanInput();
				this.working = false;  // Set working back to false after processing
				this.$emit('alert', { error: true, message: 'Stub ticket scanning is not implemented yet' });
				// TODO: Implement stub handling logic
				console.log('Handling stub:', barcode);
		},
		async handleTicket(barcode) {
			const [showId, registrationId] = barcode.split('-').map(Number);
			
			try {
				// Check if the scanned showId matches the selected showId
				if (showId !== this.selectedShowId) {
					throw new Error('Scanned ticket is for a different show');
				}

				// Construct the request
				const request = {
					url: '/tickets',
					headers: { Authorization: 'bearer ' + this.globals.accessToken },
					params: {
						showId: showId,
						registrationId: registrationId,
						$limit: 2, // Request 2 to check if there's more than one
						$eager: '[registration.profile, capacitySegment.showtime.show]'
					}
				};

				// Execute the request
				const response = await this.$api3(request);

				// Check if exactly one record is returned
				if (response.data.data.length === 0) {
					throw new Error('No ticket found with that barcode');
				} else if (response.data.data.length > 1) {
					throw new Error('Multiple tickets found with that barcode');
				}

				this.lastScannedTicket = response.data.data[0];

				// Check if the ticket has already been redeemed
				if (this.lastScannedTicket.redeemed) {
					const redeemed = new Date(this.lastScannedTicket.redeemed);
					const timeAgo = formatDistance(redeemed, new Date(), { addSuffix: true, includeSeconds: true });
					throw new Error(`This ticket was already redeemed ${timeAgo}`);
				}

				await this.markTicketRedeemed(`${this.lastScannedTicket.showId},${this.lastScannedTicket.registrationId}`);
				this.playSuccessSound();
				this.error = null; // Clear any previous errors

				// TODO: Implement further ticket handling logic with the found ticket

			} catch (error) {
				this.playErrorSound();
				let errorMessage;
				if (error.response) {
					// Server responded with an error
					errorMessage = 'Server Error: ' + error.response.data.message;
				} else if (error.message) {
					// Custom error message (e.g., not exactly one ticket found)
					errorMessage = error.message;
				} else {
					// Other errors
					errorMessage = 'Error: ' + error;
				}
				
				// Set error message
				this.error = errorMessage;
			} finally {
				this.working = false;  // Set working back to false after processing
				this.resetScanInput();
			}
		},
		async markTicketRedeemed(ticketId) {
			try {
				const request = {
					url: `/tickets/${ticketId}`,
					method: 'patch',
					headers: { Authorization: 'bearer ' + this.globals.accessToken },
					data: {
						redeemed: true,
						redeemedForShowtimeId: this.selectedShowtimeId
					}
				};

				const response = await this.$api3(request);
				return response.data;
			} catch (error) {
				console.error('Error marking ticket as redeemed:', error);
				throw error;
			}
		},
		async handleCustomerServiceTicket(barcode) {
			const barcodeElements = barcode.split('-');
			const showId = parseInt(barcodeElements[0]);
			const showtimeId = parseInt(barcodeElements[1]);
			
			try {
				// Check if the scanned showId matches the selected showId
				if (showId !== this.selectedShowId) {
					throw new Error('Scanned ticket is for a different show');
				}
				
				// Construct the request
				const selectedShowtimeRequest = {
					url: `/showtimes/${this.selectedShowtimeId}`	,
					headers: { Authorization: 'bearer ' + this.globals.accessToken },
				};

				const selectedShowtimeResponse = await this.$api3(selectedShowtimeRequest);
				const selectedShowtime = selectedShowtimeResponse.data;

				console.log({selectedShowtime});

				// Update customerServiceTicketsDetail
				// Parse JSON or set empty object if null
				const customerServiceTicketsDetail =
					selectedShowtime.customerServiceTicketsDetail === null
						? {}
						: JSON.parse(selectedShowtime.customerServiceTicketsDetail);

				// Increment the number of customer service tickets redeemed for the showtime
				if (!(showtimeId in customerServiceTicketsDetail)) {
					customerServiceTicketsDetail[showtimeId] = 1;
				} else {
					customerServiceTicketsDetail[showtimeId] = customerServiceTicketsDetail[showtimeId] + 1;
				}

				const patchShowtimeRequest = {
					url: `/showtimes/${this.selectedShowtimeId}`,
					method: 'patch',
					headers: { Authorization: 'bearer ' + this.globals.accessToken },
					data: {
						customerServiceTicketsRedeemed: selectedShowtime.customerServiceTicketsRedeemed + 1,
						customerServiceTicketsDetail: JSON.stringify(customerServiceTicketsDetail),
					}
				};

				await this.$api3(patchShowtimeRequest);

				this.playSuccessSound();

				this.lastScannedTicket = {
					registration: {
						profile: {
							badgeNameFull: 'Customer Service Ticket',
						}
					},
					capacitySegment: {
						showtime: {
							id: showtimeId,
						}
					}
				};
			} catch (error) {
				console.error('Error marking customer service ticket as redeemed:', error);
				this.playErrorSound();
				throw error;
			} finally {
				this.resetScanInput();
				this.working = false;
			}
		},
		resetScanInput() {
			// Reset the scanned barcode input
			this.scannedBarcode = '';
			// Refocus the input field for the next scan
			this.$refs.barcodeInput.focus();
		},
		async loadHandler() {
			this.working = true;  // Set working to true when loading shows
			this.error = false;   // Reset error state
			try {
				const request = {
					url: '/shows',
					method: 'get',
					headers: { Authorization: 'bearer ' + this.globals.accessToken },
					params: {
						eventId: this.globals.event.id,
						$eager: 'showtimes',
						$limit: 100,
					}
				};

				const response = await this.$api3(request);
				this.shows = response.data.data;
				
				// Emit status true after successfully loading shows
				this.$emit('status', true);
			} catch (error) {
				let errorMessage = 'Failed to load shows.';
				if (error.response) {
					errorMessage += ' Server Response: ' + error.response.data.message;
				} else {
					errorMessage += ' Error: ' + error;
				}
				// Continue to use $emit for errors in loadHandler
				this.$emit('alert', { error: true, message: errorMessage });
			} finally {
				this.working = false;  // Set working back to false after processing
			}
		},
		onBarcodeInputFocus() {
			this.isBarcodeInputFocused = true;
		},
		onBarcodeInputBlur() {
			this.isBarcodeInputFocused = false;
		},
		resetLastScannedTicket() {
			this.lastScannedTicket = null;
			this.error = null;
		},
		handleWindowFocus() {
			this.isWindowFocused = true; // Set to true when window is focused
		},
		handleWindowBlur() {
			this.isWindowFocused = false; // Set to false when window is blurred
		},
	},
	computed: {
		showtimeOptions() {
			if (!this.selectedShowId) return [{ value: null, text: 'Select a show first' }];
			const selectedShow = this.shows.find(show => show.id === this.selectedShowId);
			if (!selectedShow) return [{ value: null, text: 'No showtimes available' }];
			return [
				{ value: null, text: 'Select a showtime' },
				...selectedShow.showtimes.map(showtime => ({
					value: showtime.id,
					text: showtime.timeDisplayOverride || this.$dateHelper(showtime.startTime, '$showTime')
				}))
			];
		},
		cardBgVariant() {
			if (this.working) return 'primary';
			if (!this.selectedShowtimeId) return 'danger';
			return this.isBarcodeInputFocused ? 'success' : 'danger';
		},
		cardStatusText() {
			if (this.working) return 'Processing...';
			if (!this.selectedShowtimeId) return 'Select a show and showtime to continue';
			return this.isBarcodeInputFocused && this.isWindowFocused ? 'Ready to Scan' : 'Select the barcode input to continue';
		},
	},
	watch: {
		selectedShowId() {
			this.resetLastScannedTicket();
			// Reset showtime selection when show changes
			this.selectedShowtimeId = null;
		},
		selectedShowtimeId(newValue) {
			this.resetLastScannedTicket();
			if (newValue) {
				this.$nextTick(() => {
					this.$refs.barcodeInput.focus();
				});
			}
		}
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created() {
		this.loadHandler();
	},
	mounted() {
		// Add event listeners for window focus and blur
		window.addEventListener('focus', this.handleWindowFocus);
		window.addEventListener('blur', this.handleWindowBlur);
	},
	beforeDestroy() {
		// Clean up event listeners
		window.removeEventListener('focus', this.handleWindowFocus);
		window.removeEventListener('blur', this.handleWindowBlur);
	},
};
</script>