<template>
	<div v-if="registration.id">
		<b-row class="mb-3">
			<b-col cols="10">
				<h1 class="h2">{{ registration.profile.badgeNameFull }}
					<small class="text-muted"> Registration {{ registration.id }}</small>
				</h1>
        <h2 class="h3">
          <b-badge id="status" :variant="registration.active ? 'success' : 'danger'" class="mr-1">
                {{ registration.active ? 'Active' : 'Inactive' }}
          </b-badge>
          <b-tooltip target="status" :delay="{ show: 1000, hide: 0 }" :variant="registration.active ? 'danger' : 'success'">
              <b-icon v-if="registration.active" icon="lightning" v-on:click="changeActive(0)"></b-icon>
              <b-icon v-else icon="lightning-fill" v-on:click="changeActive(1)" />
            </b-tooltip>
          <span v-for="type in registration.types" v-bind:key="type.id">
            <b-badge variant="primary" class="mr-1" :id="'type-button-' + type.slug">
              {{ type.name }}
            </b-badge>
            <b-tooltip :target="'type-button-'+type.slug" :delay="{ show: 1000, hide: 0 }" variant="danger">
              <b-icon icon="trash" v-on:click="deleteTag(type.id)"></b-icon>
            </b-tooltip>
          </span>
          <b-badge variant="secondary" v-b-modal.add-registration-tags>+</b-badge>
        </h2>
				<dl>
					<dt>{{ registration.group.event.name }}</dt>
					<dd><b-link :to="`/groups/${registration.group.token}`">{{ registration.group.account.primaryProfile.badgeNameFull }} Group</b-link></dd>
          <template v-if="isComp">
          <dt>Comp Reason</dt>
          <dd>{{ registration.compReason ? registration.compReason : 'None' }} <b-icon :icon="registration.compReason ? 'pencil-square' : 'plus-square'" class="pointer text-muted" v-b-modal.comp-reason-form></b-icon></dd>
          </template>
				</dl>
			</b-col>
			<b-col cols="2">
				<div class="text-right" v-if="registration.group.event.id === globals.event.id">
					<b-button-group>
            <print-button :globals="globals" :registrations="[registration]" @alert="(event) => { this.$emit('alert', event); }" v-b-tooltip.hover title="Print Registration" />
					</b-button-group>
				</div>
			</b-col>
		</b-row>
    <b-card-group deck>
        <b-card>
          <h3>Schedule <b-icon icon="clock" class="float-right"></b-icon></h3>
          <ticket-list :globals="globals" :registrations="[registration]" :editable="registration.group.event.id === globals.event.id" :lg="6" @reload="loadHandler()" />
          <b-link v-if="registration.group.event.id === globals.event.id" v-b-modal.add-ticket-modal v-b-tooltip.hover title="Add Ticket" class="bottom-right">
            <b-icon icon="plus-circle" variant="dark"></b-icon>
          </b-link>
        </b-card>

        <b-card>
        <h3>Hotel Reservation <b-icon icon="key" class="float-right"></b-icon></h3>
        <template v-if="registration.hotelReservation">
        <dl>
          <b-row>
            <b-col cols="6">
              <dl>
                <dt>Check-In</dt>
                <dd>{{ $dateHelper(registration.hotelReservation.checkIn, '$date') }}</dd>
                <dt>Check-Out</dt>
                <dd>{{ $dateHelper(registration.hotelReservation.checkOut, '$date') }}</dd>
              </dl>
            </b-col>
            <b-col>
              <dl>
                <dt>Room Type</dt>
                <dd>{{ registration.hotelReservation.roomType }}</dd>
                <template v-if="registration.hotelReservation.extraNights">
                <dt>Extra Nights</dt>
                <dd>{{ registration.hotelReservation.extraNights }}</dd>
                </template>
              </dl>
            </b-col>
          </b-row>
          <template v-if="registration.hotelReservation.notes">
          <dt>Notes</dt>
          <dd>{{ registration.hotelReservation.notes }}</dd>
          </template>
        </dl>
        <b-icon v-if="registration.group.event.id === globals.event.id" icon="pencil-square" class="bottom-right pointer" v-b-modal.hotel-reservation-form></b-icon>
        </template>
        <b-link v-else-if="registration.group.event.id === globals.event.id" v-b-modal.hotel-reservation-form>Add a Hotel Reservation</b-link>
        </b-card>
    </b-card-group>
    <hotel-reservation-form-modal :globals="globals" v-model="registration.hotelReservation" :registrationId="registration.id" v-on:alert="(event) => { this.$emit('alert', event); }"></hotel-reservation-form-modal>
    <comp-reason-form-modal :globals="globals" v-model="registration.compReason" :registrationId="registration.id" v-on:alert="(event) => { this.$emit('alert', event); }"></comp-reason-form-modal>
    <add-registration-tags-modal :globals="globals" :registration="registration" v-on:add-tags="(tagsToAdd) => { registration.types = [...registration.types, ...tagsToAdd] }" v-on:alert="(event) => { this.$emit('alert', event); }"></add-registration-tags-modal>
    <add-ticket-modal :globals="globals" :registrations="[registration]" v-on:refresh="loadHandler()" />
	</div>
</template>

<script>
import _ from 'lodash';
import AddRegistrationTagsModal from '../modals/AddRegistrationTagsModal';
import CompReasonFormModal from '../modals/CompReasonFormModal';
import HotelReservationFormModal from '../modals/HotelReservationFormModal';
import AddTicketModal from '../modals/AddTicketModal';
import TicketList from '../TicketList';
import PrintButton from '../PrintButton';

export default {
	name: 'Registration',
	components: {
		AddRegistrationTagsModal, CompReasonFormModal, HotelReservationFormModal, AddTicketModal, TicketList, PrintButton
	},
	props: {
		globals: Object,
	},
	data: function() {
		return {
			modal: {
				compReason: '',
        hotelReservation: {},
			},
			registration: {},
		}
	},
  computed: {
    isComp() {
      const compTag = _.find(this.registration.types, { slug: 'comp' });
      return typeof ( compTag ) === 'object';
    },
  },
	methods: {
		diffObjects: function(edited, original) {
			// Determines if any of the values in edited differ from those in original
			const changes = {};

			// If there aren't two objects, return the edited object in full
			if (typeof (edited) !== 'object' || typeof (original) !== 'object') return changes;

			for (let i in edited) {
        if (edited[i] == original[i]) continue;
        
        // Set the changed value, converting blank strings to null
				changes[i] = edited[i] === "" ? null : edited[i];
			}

			return changes;
		},
    deleteTag: async function(tagId) {
      // Construct request
      const request = {
        url: `/registration-tags-registrations/${tagId},${this.registration.id}`,
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'delete',
      };

      try {
        // Execute request
        await this.$api3(request);

        this.registration.types.splice(_.findIndex(this.registration.types, { 'id': tagId }), 1);
      } catch (error) {
        let message;

				if (error.response) {
					// Server responded
          message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      }
    },
    changeActive: async function(active) {
      // Construct request
      const request = {
        url: `/registrations/${this.registration.token}`,
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        method: 'patch',
        data: { active: active }
      };

      try {
        // Execute request
        await this.$api3(request);

        this.registration.active = active;
      } catch (error) {
        let message;

				if (error.response) {
					// Server responded
          message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      }
    },
    async loadHandler() {
      // Construct request
      const request = {
        url: '/registrations/' + this.$route.params.id,
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: { $eager: '[group.[account.primaryProfile,event],hotelReservation,profile,tickets.capacitySegment.showtime.show,types]'}
      }

      try {
        // Execute request
        const result = await this.$api3(request);

        // Update relevant data
        this.registration = result.data;
      } catch (error) {
          let message;

          if (error.response) {
            // Server responded
            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error
          this.$emit('alert', { error: true, message: message });
        }
    },
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: function() {
    this.loadHandler();
	},
  mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
.bottom-right {
  position: absolute;
  bottom: 16px;
  right: 20px;
}

.pointer {
  cursor: pointer;
}
</style>
