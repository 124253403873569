<template>
  <b-modal id="transfer"
    v-on:ok="transferRegistrations"
    v-on:hidden="resetModal"
    size="sm"
    title="Transfer Registrations"
    ok-title="Transfer"
    :busy="busy"
    :ok-disabled="!isValid"
  >
    <b-overlay :show="busy">
      <b-form v-on:submit.prevent="transferRegistrations">
        <b-form-group label-for="source-registrations" label="Which Registrations?">
          <b-form-select id="source-registrations" type="number" v-model="sourceRegistrations" :options="sourceRegistrationOptions">
            <template #first>
              <b-form-select-option :value="null" disabled></b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label-for="to-group" label="To which Group?" description="Enter a numeric Group ID, or leave blank for a new Group">
          <b-form-input id="target-group" type="number" v-model="targetGroupId" placeholder="A New Group"  @change="loadTargetGroup"></b-form-input>
        </b-form-group>
      </b-form>
      <template v-if="isValid">
        <p >Transfer <strong>{{ sourceRegistrationNames }}</strong></p>
        <p>From the <strong>{{ group.account.primaryProfile.badgeNameFull }} Group</strong></p>
        <p>To <span v-html="targetGroupName"></span></p>
      </template>
      <p class="text-danger">{{ error }}</p>
    </b-overlay>
  </b-modal>
</template>

<script>
import { filter as _filter } from 'lodash';

export default {
  name: 'TransferRegistrationsModal',
  props: {
    globals: Object,
    group: Object
  },
  data() {
    return {
      busy: false,
      sourceRegistrations: [],
      targetGroupId: null,
      targetGroup: {},
      error: null,
      sourceRegistrationOptionsTemplate: [
        { text: 'Which Registrations?', value: [], disabled: true },
        { text: 'All Registrations', value: [1,2,3] }
      ]
    }
  },
  computed: {
    sourceRegistrationOptions() {
      const mappedRegistrations = this.group.registrationsGuest.map(branch => {
        const registrations = [branch, ...branch.dependents];
        delete registrations[0].dependents;

        const names = this.namesFromRegistrations(registrations)

        return { text: names, value: registrations };
      });

      return  [{ text: 'All Registrations', value: this.group.registrations }, ...mappedRegistrations];
    },
    sourceRegistrationNames() {
      if (this.sourceRegistrations.length < 1) return;

      if (this.sourceRegistrations.length === this.group.registrations.length) return 'All Registrations';

      return this.namesFromRegistrations(this.sourceRegistrations);
    },
    targetGroupName() {
      if (typeof this.targetGroup.id === 'undefined') {
        return '<strong>A New Group</strong>';
      }

      return 'the <strong>' + this.targetGroup?.account?.primaryProfile.badgeNameFull + ' Group</strong>';
    },
    isValid() {
      // sourceRegistrations needs to contain at least one registration
      if (this.sourceRegistrations.length === 0) return false;

      // targetGroup must exist if this.sourceRegistrations contains every registration in the group
      if (this.sourceRegistrations.length === this.group.registrations.length && typeof this.targetGroup.id === 'undefined') return false;
      
      return true;
    }
  },
  methods: {
    loadTargetGroup() {
      // Called when the targetGroup field loses focus
      this.busy = true;
      this.error = null;
      this.targetGroup = {};

      if (!this.targetGroupId) {
        this.targetGroup = {};
        this.busy = false;
        return;
      }

      const request = {
        url: '/groups',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        params: {
          id: this.targetGroupId,
          $eager: 'account.primaryProfile'
        }
      };

      this.$api3(request).catch((error) => {
        console.log({ error })
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.error = message;
        this.busy = false;
      }).then((response) => {
        if (response.data.data.length !== 1) {
          this.error = 'There was a problem loading that Group. Please try again.';
          this.busy = false;
          return;
        }

        this.targetGroup = response.data.data[0];
        this.busy = false;
      });
    },
		async transferRegistrations(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.busy = true;

      if (typeof this.targetGroup.id === 'undefined') {
        this.targetGroup = await this.createNewGroup();
        if (typeof this.targetGroup.id === 'undefined') return;
      }

      const request = {
        url: '/batch',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        method: 'POST',
        data: {
          calls: [],
        }
      }

      this.sourceRegistrations.forEach(registration => {
        request.data.calls.push([
          'patch',
          'registrations',
          registration.token,
          {
            groupId: this.targetGroup.id
          }
        ])
      });

      this.$api3(request).catch((error) => {
        console.log({ error })
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.error = message;
        this.busy = false;
      }).then((response) => {

        const batchErrors = _filter(response.data, (o) => {
          return o.status !== 'fulfilled'
        })

        if(batchErrors.length !== 0) {
          this.error = 'There was a problem transferring one or more Registrations.';
          this.busy = false;
          return;
        }

        // Redirect to the target
        this.$router.push(`/groups/${this.targetGroup.token}`)
      });
		},
    async createNewGroup() {
      const request = {
        url: '/groups',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        method: 'POST',
        data: {
          eventId: this.globals.event.id,
          accountId: this.sourceRegistrations[0].profile.account.id,
          purchased: true,
        }
      }

      const response = await this.$api3(request).catch((error) => {
        console.log({ error })
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.error = message;
        this.busy = false;
      });

      if( typeof response === 'undefined') {
        // There was no Group created. It probably already exists.
        this.error = 'There was a problem creating a Group for that user. Go to the Account and delete one if it already exists.';
        this.busy = false;
        return null;
      }

      return response.data;
    },
		resetModal() {
			this.sourceRegistrations = [];
      this.targetGroupId = null;
      this.targetGroup = {};
    },
    namesFromRegistrations(registrations) {
        let names = "";

        registrations.forEach((registration, index) => {
          names += registration.profile.badgeNameFull;

          if (index < registrations.length -1 && registrations.length > 2) {
            names += ',';
          }

          if (index == registrations.length -2) {
            names += ' and ';
          } else if (index < registrations.length -1) {
            names += ' ';
          }
        });

        return names;
    }
  }
}
</script>