<template>
	<b-navbar toggleable="lg" type="dark" variant="dark">
		<b-navbar-nav>
		<b-nav-item>
      MAGIC Live
		</b-nav-item>
		</b-navbar-nav>
    
		<b-navbar-nav class="ml-auto">
      <b-nav-item href="mailto:steven@neonpineapple.live">Help</b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
	name: 'AnonymousHeader',
}
</script>
