<template>
	<b-navbar toggleable="lg" type="dark" variant="dark">
		<b-navbar-nav>
		<b-nav-item>
      {{ globals.event.name || "MAGIC Live" }}
      <b-badge variant="danger" v-if="isDev" class="ml-3">Development</b-badge>
		</b-nav-item>
		</b-navbar-nav>

		<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

		<b-collapse id="nav-collapse" is-nav>
			<b-navbar-nav class="ml-auto">
				<!-- <b-nav-item v-if="searchTerm && $route.fullPath != '/groups'" to="/groups"><b-icon icon="box-arrow-in-left"></b-icon></b-nav-item> -->
				<b-nav-form v-on:submit.prevent="$emit('search', formSearchTerm)">
					<b-input-group>
					<b-form-input size="sm" placeholder="Name or email" v-model="formSearchTerm" v-on:focus="formSearchTerm = ''"></b-form-input>
					<b-input-group-append>
						<b-button size="sm" type="submit"><b-icon-search></b-icon-search></b-button>
					</b-input-group-append>
					</b-input-group>
				</b-nav-form>
			</b-navbar-nav>
		</b-collapse>
	</b-navbar>
</template>

<script>
export default {
	name: 'Header',
	props: {
		globals: Object,
	},
	data: function() {
		return {
			formSearchTerm: null
		}
	},
  watch: {
    $route (to) {
      if (to.fullPath !== '/search') {
        this.formSearchTerm = null;
      }
    }
  },
  computed: {
    isDev() {
      const value = process.env.NODE_ENV;
      const string = value.charAt(0) + value.slice(1);
      return string === 'development';
    },
  },
}
</script>
