<template>
	<div>
		<h2>Past Due Transactions <small v-if="pastDueTransactions.total" class="text-muted">Found {{ pastDueTransactions.total }}</small></h2>
		<b-table
			id="past-due-transactions-table"
			striped
			:items="computedPastDueTransactions"
			:fields="fields"
			per-page="20"
			:current-page="currentPage"
      empty-text="There are no Past Due Transactions to show."
      show-empty
		>
      <template v-slot:cell(created)="data">
        {{ $dateHelper(data.item.created, '$date')}}
      </template>
      <template v-slot:cell(amount)="data">
        {{ data.item.amount | currency }}
      </template>
      <template v-slot:cell(lastReminded)="data">
        {{  $dateHelper(data.item.lastReminded, '$date') }} <b-badge :variant="data.item.reminderCount >= maxReminders ? 'danger' : 'secondary'">{{ ordinal(data.item.reminderCount) }}</b-badge>
      </template>
			<template v-slot:cell(actions)="data">
				<b-button size="sm" variant="link" :to="'/accounts/'+data.item.account.token" v-b-tooltip.hover title="View Account">
            <b-iconstack class="mr-3" variant="dark">
              <b-icon stacked icon="folder-fill"></b-icon>
              <b-icon stacked icon="person-fill" scale="0.55" shift-v="-0.4" variant="white"></b-icon>
						</b-iconstack>	
        </b-button>
			</template>
		</b-table>

		<b-pagination
			v-if="pastDueTransactions.total > 20"
			v-model="currentPage"
			:total-rows="pastDueTransactions.total"
			per-page="20"
			aria-controls="profiles-table"
		></b-pagination>
	</div>
</template>

<script>
import { formatDistance, formatDistanceToNowStrict } from "date-fns";
const ordinal = require('ordinal');
import { capitalize } from 'lodash';

export default {
	name: 'PastDueTransactions',
	props: {
		globals: Object,
	},
	data: function() {
		return {
			fields: [
        {
          key: 'created',
          label: 'Date'
        },
				{
					key: 'account.primaryProfile.badgeNameFull',
          label: 'Account'
				},
        {
          key: 'amount'
        },
        {
          key: 'lastReminded',
          label: 'Reminded'
        },
				{
					key: 'actions',
					label: ''
				}
			],
			pastDueTransactions: [],
      currentPage: 1,
      maxReminders: 4,
		}
	},
  computed: {
    computedPastDueTransactions() {
      return this.pastDueTransactions.data.map(transaction => {
        transaction._rowVariant = transaction.reminderCount >= this.maxReminders ? 'danger' : 'standard';
        return transaction;
      })
    }
  },
  methods: {
    formatDistance,
    formatDistanceToNowStrict,
    ordinal,
    capitalize
  },
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: async function() {
    // Construct request
    const request = {
      url: '/transactions',
      headers: { Authorization: 'bearer ' + this.globals.accessToken},
      params: {
        $eager: 'account.primaryProfile',
        $limit: 100, '$sort[created]': 1,
        transactionTypeId: 31,
        'reminderCount[$gt]': 0,
        'stripeChargeId': 'null'
      }
    }

    try {
      // Execute request
      const result = await this.$api3(request);

      // Update relevant data
      this.pastDueTransactions = result.data;
    } catch (error) {
      let message;

      if (error.response) {
        // Server responded
        if(error.response.status === 401) {
          this.$emit('logout');
        }

        message = 'Server Error: ' + error.response.data.message;
      } else {
        // No response or Error in request
        message = 'Axios: ' + error;
      }

      // Emit the error
      this.$emit('alert', { error: true, message: message });
    }
  },
	mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
</style>
