<template>
  <b-modal id="move-group-to-account"
    size="sm"
    title="Move Group to Account"
    @show="findAccounts"
    @ok="moveGroupToAccount"
    @hidden="resetModal"
    :ok-disabled="!newAccount"
    :busy="busy"
  >
    <b-overlay :show="busy">
      <b-form v-on:submit.prevent="moveGroupToAccount">
        <b-form-group label="New Primary" label-for="new-account-id">
          <b-form-select
            id="new-account-id"
            v-model="newAccount"
          >
            <template v-slot:first>
              <b-form-select-option value="" disabled />
            </template>
            <b-form-select-option v-for="account in availableAccounts" v-bind:key="account.id" :value="account">{{ account.primaryProfile.badgeNameFull }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-form>
      <template v-if="newAccount">
        <p>This Group will become the<br /> <strong>{{ newAccount.primaryProfile.badgeNameFull }} Group</strong>.</p>
        <p>If there are any Invitations or Scheduling emails extended for this Group, they will remain valid.</p>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>

const { remove: _remove } = require('lodash');
export default {
  name: 'MoveGroupToAccountModal',
  data: function() {
    return {
      availableAccounts: [], 
      newAccount: false,
      busy: true,
    }
  },
  props: {
    globals: Object,
    group: Object,
  },
  methods: {
    findAccounts: async function (bvModalEvt) {

      let accountIds = [];

      for (const registration of this.group.registrations) {
        if (!accountIds.includes(registration.profile.account.id)) accountIds.push(registration.profile.account.id);
      }

      // Remove existing accountId
      _remove(accountIds, (o) => {
        return o === this.group.accountId;
      });

      const request = {
        url: '/accounts',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          'accounts.id[$in]': accountIds,
          '$sort[primaryProfile.badgeNameFirst]': 1,
          '$sort[primaryProfile.badgeNameLast]': 1,
          $eager: 'primaryProfile',
          $joinRelation: 'primaryProfile',
          $limit: 100,
        }
      }

      this.$api3(request).catch((error) => {
        let message;

				if (error.response) {
					// Server responded
          message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

        console.log({ error });

				// Emit the error
				this.$emit('alert', { error: true, message: message });
      }).then((response) => {
        if (response.data.total === 0) {
          this.$emit('alert', { error: true, message: 'There are no other Accounts associated with this Group.'});
          bvModalEvt.vueTarget.hide();
          return;
        }
        this.availableAccounts = response.data.data;
        this.busy = false;
      });
    },
    moveGroupToAccount(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.busy = true;

      // Assemble the registration request
      const groupRequest = {
        url: `/groups/${this.group.token}`,
        method: 'PATCH',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        data: {
          accountId: this.newAccount.id,
        }
      }

      this.$api3(groupRequest).then((response) => {
        console.log('start of then');
        console.log({response})
        this.$emit('reload');
        bvModalEvt.vueTarget.hide();
      }).catch(error => {
        let message = 'There was a problem transferring this Group. The other Account may already have a Group for this Event.';

        if (error.response) {
          // Server responded
          message += ' Server Error: ' + error.response.data.message;
        } else {
          // No response or Error in request
          message += ' Request: ' + error;
        }

        // Emit the error
        this.$emit('alert', { error: true, message: message });
        bvModalEvt.vueTarget.hide();
      })
    },
    resetModal() {
      this.availableAccounts = [];
      this.newAccount = false;
      this.busy = true;
    }
  },
}
</script>

<style>

</style>