<template>
	<div>
		<h2>Shows <small class="text-muted">Found {{ shows.length }}</small></h2>

		<show-list v-for="show in shows" :key="show.id" :globals="globals" :show="show" />
  </div>
</template>

<script>
import ShowList from '../ShowList.vue';

export default {
	name: 'Shows',
  components: {
    ShowList
  },
	props: {
		globals: Object,
	},
	methods: {
		loadHandler: async function() {
      // Construct request
      const request = {
        url: '/shows',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          $limit: 100,
          $eager: 'showtimes.capacitySegments.limitedToCapacitySegment.showtime.show',
          eventId: this.globals.event.id,
        },
      }

      // Execute request
      const result = await this.$api3(request).catch((error) => {
        console.log(error);

				if(error.response.status === 401) {
          this.$emit('logout');
        }
      });

      // Update relevant data
      this.shows = result.data.data;
		}
	},
	data: function() {
		return {
			shows: [],
		}
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
  created() {
    this.loadHandler();
  },
	mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
</style>
