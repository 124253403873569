<template>
  <div class="mb-3">
    <h3>{{ show.title }}<small v-if="venueDetail" class="text-muted"> {{ venueDetail }}</small></h3>
    <div class="d-flex" style="flex-flow: row wrap">
      <showtime-card v-for="showtime in show.showtimes" :key="showtime.id" :globals="globals" :show="show" :showtime="showtime" :capacity="show.venueCapacity" />
    </div>
  </div>
</template>

<script>
import ShowtimeCard from './ShowtimeCard.vue';

export default {
  name: 'ShowList',
  components: {
    ShowtimeCard
  },
  props: {
    globals: Object,
    show: Object
  },
  computed: {
    venueDetail() {
      return ( this.show.venueName === null ? 'Venue' : this.show.venueName ) + ' Capacity ' + this.show.venueCapacity;
    }
  }
}
</script>

<style>

</style>