<template>
  <b-row class="mb-2" :class="{ 'text-muted': !registration.active }">
    <b-col cols="3" md="5" lg="4">
      <div :class="{ 'ml-4': guest }">
        <div class="float-left mr-2" style="position: relative; top: -8px" v-if="dependent">
          <b-icon icon="arrow90deg-up" />
        </div>
        <h6>
          {{ registration.profile.badgeNameFull}}<br />
          <small class="text-secondary" v-if="registration.profile.nameFull !== registration.profile.badgeNameFull">{{ registration.profile.nameFull }}</small>
        </h6>
      </div>
    </b-col>
    <b-col lg="2" xl="2" style="overflow: hidden; text-overflow: ellipsis;" class="d-none d-lg-block">
      <a :href="`mailto:${registration.profile.email}`" v-if="registration.profile.email" v-b-tooltip.hover :title="registration.profile.email">{{ registration.profile.email }}</a>
    </b-col>
    <b-col cols="3" sm="4" md="3" xl="4">
      <b-badge :variant="registration.active ? 'success' : 'danger'" class="mr-1">
        {{ registration.active ? 'Active' : 'Inactive' }}
      </b-badge>
      <b-badge v-for="type in registration.types" :key="type.id" class="mr-1">
        {{ type.name }}
      </b-badge>
    </b-col>
    <b-col cols="6" sm="5" md="4" lg="3" xl="2" class="text-right pr-4">
      <b-button variant="link" :to="'/registrations/'+registration.token" v-b-tooltip.hover title="View Registration"><b-icon-person-square variant="dark"></b-icon-person-square></b-button>
      <b-button variant="link" :to="'/profiles/'+registration.profile.id" v-b-tooltip.hover title="View Profile"><b-icon-person-lines-fill variant="dark"></b-icon-person-lines-fill></b-button>
      <b-button variant="link" :to="'/accounts/'+registration.profile.account.token" v-b-tooltip.hover title="View Account" v-if="!dependent">
        <b-iconstack variant="dark">
          <b-icon stacked icon="folder-fill"></b-icon>
          <b-icon stacked icon="person-fill" scale="0.55" shift-v="-0.4" variant="white"></b-icon>
        </b-iconstack>					
      </b-button>
      <b-button variant="link" disabled v-else>
        <b-icon />					
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'RegistrationListItem',
  props: {
    registration: Object,
    dependent: {
      default: false,
      type: Boolean
    },
    guest: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>
.tooltip .tooltip-inner{
  max-width: 500px !important;
}
</style>