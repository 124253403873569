<template>
  <b-row class="schedule-row">
    <b-col  v-if="registrations.length === 0 || registrations[0].tickets.length === 0">
      <p>No tickets. <b-link v-if="editable" href="" @click.prevent="populateSuggestdSchedule()">Add a full schedule.</b-link></p>
    </b-col>
    <b-col v-else :lg="lg" v-for="ticket in registrations[0].tickets" :key="ticket.id">
      <dl>
        
        <dt>{{ ticket.capacitySegment.showtime.show.title }} <b-icon v-if="ticket.redeemed !== null" icon="check-square-fill" variant="success" class="ml-1"></b-icon></dt>
        
        <dd>{{ ticket.capacitySegment.showtime.timeDisplayOverride || $dateHelper(ticket.capacitySegment.showtime.startTime, '$showTime') + ' &ndash; ' + $dateHelper(ticket.capacitySegment.showtime.endTime, '$showTime')}} <b-link v-if="editable" class="ticket-edit" @click.prevent="removeTicket(ticket)"><b-icon icon="trash" class="text-secondary" /></b-link></dd>
      </dl>
    </b-col>
  </b-row>
</template>

<script>

export default {
	name: 'TicketList',
  props: {
    globals: Object,
    registrations: Array,
    group: {
      type: [Object, Boolean],
      default: false
    },
    lg: {
      type: Number,
      default: 12,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async removeTicket(ticket) {
      const confirmString = `${ticket.capacitySegment.showtime.show.title}: ${this.$dateHelper(ticket.capacitySegment.showtime.startTime, '$showTime')}`;
      const confirmation = await this.$bvModal.msgBoxConfirm(confirmString, {
        title: 'Remove this ticket?',
        okVariant: 'danger',
        okTitle: 'Remove',
        centered: true,
      });

      if (confirmation !== true) {
        return;
      }

      // Continue with deletion
      // Create request
      let batchRequest = {
        url: '/batch',
        method: 'post',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        data: {
          calls: [],
        }
      };

      for (const registration of this.registrations) {
        batchRequest.data.calls.push([
          'remove',
          'tickets',
          `${ticket.capacitySegment.showtime.show.id},${registration.id}`
        ])
      }
      
      // Execute request
      await this.$api3(batchRequest).catch((error) => {
        // Handle error in executing request
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      });

      // Tigger refresh
      this.$emit('reload');
    },
    async populateSuggestdSchedule() {
      // Check that there are current Registrations
      if (this.registrations.length === 0) return;
      
      // Check that the current Registrations don't have tickets
      const checkForLackOfTickets = this.registrations.every((item) => {
        return item.tickets.length === 0;
      })

      if (!checkForLackOfTickets) {
        // Some tickets exist
        // Show an error and return
        this.$emit('alert', { error: true, message: 'Some tickets already exist. Remove those first, then try again.' });
        return;
      }

      // Show a spinner
      this.$emit('status', false);

      // Try to get a suggested schedule for the number of Registrations
      const suggestedScheduleRequest = {
        url: `suggested-schedules/${this.registrations.length}`,
        method: 'get',
      }

      const suggestedScheduleResponse = await this.$api3(suggestedScheduleRequest).catch(error => {
        console.log(error.response);
        this.$emit('status', false);
        this.$emit('alert', { error: true, message: 'There was a problem getting a Suggested Schedule' });
        return;
      });

      const schedule = suggestedScheduleResponse.data[0];

      // Add General Sessions
      const generalSessionRequest = {
        url: 'capacity-segments',
        method: 'get',
        params: {
          showId: 14, // General Sessions are show 14 in 2024
          'capacityRemaining[$gte]': this.registrations.length,
          isAvailable: 1,
          $eager: '[showtime.show]',
          $limit: 1,
        },
      }

      const generalSessionResponse = await this.$api3(generalSessionRequest).catch(error => {
        console.log(error.response);
        this.$emit('status', false);
        this.$emit('alert', { error: true, message: 'There was a problem getting avaialble General Sesseions' });
        return;
      });

      schedule.push(generalSessionResponse.data.data[0]);

      // Create a batch reuqest to add tickets
      const ticketBatchRequest = {
        url: 'batch',
        method: 'post',
        data: {
          calls: [],
        }
      }

      // Populate the batch request for each registration for each ticket
      for (const registration of this.registrations) {
        for (const capacitySegment of schedule) {
          ticketBatchRequest.data.calls.push([
            'create',
            'tickets',
            {
              showId: capacitySegment.showId,
              capacitySegmentId: capacitySegment.id,
              registrationId: registration.id
            }
          ])
        }
      }

      const ticketBatchResult = await this.$api3(ticketBatchRequest).catch(error => {
        console.log(error.response);
        this.$emit('status', false);
        this.$emit('alert', { error: true, message: 'There was a problem creating tickets' });
        return;
      });

      const ticketBatchErrors = ticketBatchResult.data.filter((response) => {
        return response.status !== 'fulfilled';
      })

      if (ticketBatchErrors.length !== 0) {
        this.$emit('alert', { error: true, message: 'One or more tickets could not be created. Please delete all tickets and try again.' });
      }


      // If being called with a Group, update the Group
      if (this.group !== false && this.group.schedulingCompleted === null) {
        console.log('trying to mark as complete');
        const updateGroupRequest = {
          url: `groups/${this.group.token}`,
          method: 'patch',
          data: {
            schedulingCompleted: true,
            schedulingUsedSuggested: 1
          }
        }

        await this.$api3(updateGroupRequest).catch(error => {
          console.log(error.response);
          this.$emit('status', false);
          this.$emit('alert', { error: true, message: 'There was a problem marking scheduling as complete' });
          return;
        });
      }

      // Trigger a reload of the Group or Registration\
      this.$emit('alert', { error: false, message: 'Tickets have been added.'})
      this.$emit('reload');
    }
  }
}
</script>

<style>
.ticket-edit {
  visibility: hidden;
}
.schedule-row dl:hover .ticket-edit {
  visibility: visible;
}
</style>