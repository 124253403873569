<template>
  <b-dropdown :variant="variant" :disabled="active" right>
    <template #button-content>
      <b-spinner v-if="active" small></b-spinner>
      <b-icon v-else icon="printer-fill" />
    </template>
    <b-dropdown-item @click="print('single')">Single - Legal</b-dropdown-item>
    <b-dropdown-item @click="print('quad')">Quad - Letter</b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'PrintButton',
  props: {
    globals: Object,
    variant: {
      type: String,
      default: 'secondary'
    },
    registrations: Array
  },
  data() {
    return {
      active: false,
    }
  },
  methods: {
    async print(template) {
      this.active = true;

      // Request the file
      // Construct request
      const request = {
        url: '/documents',
        method: 'post',
        data: {
          template,
          records: this.registrations.map(o => {
            return o.token;
          })
        }
      };
      let response = false;

      try {
        // Execute request
        response = await this.$api3(request);
      } catch (error) {
        let message;

				if (error.response) {
					// Server responded
          if(error.response.status === 401) {
            this.$emit('logout');
          }

					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error
				this.$emit('alert', { error: true, message: message });
        this.active = false;
      }

      this.active = false;

      // After the file is retrieved, open it in a new window
      window.open(response.data.fullPath, '_blank');

      // And add the tag "printed" (id 79) to the records
      // Create request
      let batchRequest = {
        url: '/batch',
        method: 'post',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        data: {
          calls: [],
        }
      };

      for (const registration of this.registrations) {
        batchRequest.data.calls.push([
          'create',
          'registration-tags-registrations',
          {
            registrationId: registration.id,
            registrationTagId: 79,
          }
        ])
      }
      
      // Execute request
      await this.$api3(batchRequest).catch((error) => {
        // Handle error in executing request
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      });

    }
  }
}
</script>

<style>

</style>