/* A date formatting helper that is capable of accounting for time zones

   Accepts a date or a string the Date() constructor can parse

   Defaults to America/Los_Angeles so things like showtimes will work no matter where the control
   panel is being viewed from

   Timezone can be overriden, for example to match the user's local time.
*/

import { formatInTimeZone } from "date-fns-tz";

// import { formatInTimeZone } from "date-fns-tz";

export function dateHelper (date = null, format = '$dateTime', timezone = null) {
  // Error if a date or string was not provided
  if (date === null) {
    return '';
    // throw new TypeError('No date provided to dateHelper.');
  }

  // Error if the date can't be parsed into a valid Date
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  if (!(date instanceof Date && !isNaN(date))) {
    throw new TypeError('An invalid date was provided to dateHelper.');
  }

  let localized = true;
  if (timezone === null) {
    timezone = 'America/Los_Angeles';
    localized = false;
  }

  // Determine what format to use
  if (typeof format !== 'string' && format.length === 0) {
    format = '$dateTime';
  }

  switch (format) {
    case '$dateShort':
      // #ToDo: Handle period for abbreviation of month - Could do a comparison of MMM and MMMM or getMonth() and omit 5
      format = 'MMM d, yyyy';
      break;
    case '$date':
      format = 'MMMM d, yyyy';
      break;
    case '$dateTime':
      format = localized ? "MMMM d, yyyy 'at' h:mm aa z" : "MMMM d, yyyy 'at' h:mm aa";
      break;
    case '$showTime':
      format = 'EEEE h:mm aa';
      break;
  }

  return formatInTimeZone(date, timezone, format)
}