<template>
	<div>
		<h2>Profile Tags</h2>
		<b-badge v-for="tag in tags" :key="tag.id" variant="primary" class="mr-1" :to="'/profiles?tag='+tag.slug">{{ tag.name }} ({{ tag.profiles.length }})</b-badge>
		<b-badge variant="secondary" v-b-modal.new-tag href="#">+</b-badge>

		<b-modal id="new-tag"
			v-on:ok="createTag"
			v-on:hidden="newTagName = null"
			size="sm"
			title="New Profile Tag"
		>
			<b-form-input v-model="newTagName"></b-form-input>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'ProfileTags',
	props: {
		globals: Object,
	},
	data: function() {
		return {
			tags: [],
			tagCount: 0,
			newTagName: null,
		}
	},
	methods: {
		createTag: async function() {
			// Construct request
			const request = {
				url: '/profile-tags',
				method: 'POST',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data: { name: this.newTagName },
			}

			// Execute request
			if (await this.$api3(request)) {
				this.$router.go();
			} else {
				this.$emit('alert', { error: true, message: 'There was a problem creating that tag.'});
			}
		}
	},
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created: async function() {
		// Construct request
		const request = {
			url: '/profile-tags',
			method: 'get',
			headers: {'Authorization': 'bearer ' + this.globals.accessToken},
      params: {
        $eager: 'profiles',
        $limit: 100,
      }
		}

		// Execute request
		const result = await this.$api3(request);

		// Update relevant data
		this.tags = result.data.data;
		this.tagCount = result.data.total;
	},
	mounted: function() {
		this.$emit('status', true);
	},
};
</script>

<style>
</style>
