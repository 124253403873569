<template>
  <div>
    <validation-observer v-slot="{invalid, pristine}">
      <b-modal
          id="profile"
          centered
          size="xl"
          title="Add A Profile"
          v-on:hidden="resetProfile"
          v-on:ok.prevent="saveProfile"
          :ok-disabled="invalid || pristine"
        >
          <b-row>
            <b-col cols="6">
              <validation-provider rules="required" v-slot="v">
                <b-form-group
                  label="First Name*"
                  label-for="add-profile-name-first"
                  description="As it appears on their photo id"
                >
                  <b-form-input id="add-profile-name-first" v-model="profile.nameFirst" :state="v.errors.length > 0 ? false : null" @blur="if (typeof profile.badgeNameFirst === 'undefined') profile.badgeNameFirst = profile.nameFirst"></b-form-input>
                  <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group
                  label="Last Name"
                  label-for="add-profile-name-last"
              >
                <b-form-input id="add-profile-name-last" v-model="profile.nameLast" @blur="if (typeof profile.badgeNameLast === 'undefined') profile.badgeNameLast = profile.nameLast"></b-form-input>
              </b-form-group>

              <validation-provider rules="required" v-slot="v">
                <b-form-group
                  label="Badge Name First*"
                  label-for="add-profile-badge-name-first"
                  description="As it should appear on their badge"
                >
                  <b-form-input id="add-profile-badge-name-first" v-model="profile.badgeNameFirst" :state="v.errors.length > 0 ? false : null"></b-form-input>
                  <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group
                  label="Badge Name Last"
                  label-for="add-profile-badge-name-last"
              >
                <b-form-input id="add-profile-badge-name-last" v-model="profile.badgeNameLast"></b-form-input>
              </b-form-group>

              <validation-provider rules="email" v-slot="v">
                <b-form-group
                  label="Email"
                  label-for="add-profile-email">
                  <b-form-input type="email" id="add-profile-email" v-model="profile.email" :state="v.errors.length > 0 ? false : null"></b-form-input>
                  <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="phoneNumberRules" v-slot="v">
                <b-form-group
                  :label="phoneNumberLabel"
                  label-for="add-profile-phone">
                  <b-form-select
                    id="add-profile-phone"
                    v-model="profile.phoneNumberId"
                    v-on:change="overridePhoneNumberRules = false; if (profile.phoneNumberId === null) $bvModal.show('phone-number-modal')"
                    :state="v.errors.length > 0 ? false : null"
                  >
                    <!-- v-on:change="if (modal.profile.phoneNumberId === null) $bvModal.show('phone-number')" -->
                    <option :value="null">Add a phone number</option>
                    <option v-for="phoneNumber in account.phoneNumbers" v-bind:key="phoneNumber.id" :value="phoneNumber.id">{{ phoneNumber.nationalFormat }}</option>
                  </b-form-select>
                  <b-form-text :text-variant="v.errors[0] ? 'danger' : 'secondary'" v-show="v.errors[0] || overridePhoneNumberRules">
                    Phone Number is usually required.
                    <b-form-checkbox inline class="float-right" v-model="overridePhoneNumberRules" @change="(checked) => { if (!checked) $bvModal.show('address-modal') }">Override</b-form-checkbox>
                  </b-form-text>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="addressRules" v-slot="v">
                <b-form-group
                  :label="addressLabel"
                  label-for="add-profile-address">
                  <b-form-select
                    id="add-profile-address"
                    v-model="profile.addressId"
                    v-on:change="overrideAddressRules = false; if (profile.addressId === null) $bvModal.show('address-modal')"
                    :state="v.errors.length > 0 ? false : null"
                  >
                    <!-- v-on:change="if (modal.profile.addressId === null) $bvModal.show('address-modal')" -->
                    <option :value="null">Add an address</option>
                    <option v-for="address in account.addresses" v-bind:key="address.id" :value="address.id">{{ address.primaryLine }} {{ address.secondaryLine }} {{ address.lastLine }}</option>
                  </b-form-select>
                  <b-form-text :text-variant="v.errors[0] ? 'danger' : 'secondary'" v-show="v.errors[0] || overrideAddressRules">
                    Address is usually required.
                    <b-form-checkbox inline class="float-right" v-model="overrideAddressRules" @change="(checked) => { if (!checked) $bvModal.show('address-modal') }">Override</b-form-checkbox>
                  </b-form-text>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider :rules="birthdayRules" v-slot="v">
                <b-form-group :label="birthdayLabel" label-for="add-profile-birthday" description="yyyy-mm-dd">
                  <b-form-input id="add-profile-birthday" v-model="profile.birthday" :state="v.errors.length > 0 ? false : null" placeholder="yyyy-mm-dd"></b-form-input>
                  <b-form-text :text-variant="v.errors[0] ? 'danger' : 'secondary'" v-if="v.errors[0] || overrideBirthdayRules">
                    Date of Birth is usually required.
                    <b-form-checkbox inline class="float-right" v-model="overrideBirthdayRules">Override</b-form-checkbox>
                  </b-form-text>
                </b-form-group>
              </validation-provider>
              <validation-provider>
                <b-form-group label="Shirt Size" label-for="add-profile-shirt-size" description="US Mens shirt size">
                  <b-form-select id="add-profile-shirt-size" v-model="profile.usMensShirtSize" :options="shirtSizes" value-field="id" text-field="primary_line"></b-form-select>
                </b-form-group>
              </validation-provider>
              <b-form-group>
                <b-row class="mr-0">
                  <b-col sm="2">
                    <validation-provider>
                      <b-form-checkbox v-model="profile.alumni2019" value="1" unchecked-value="0">2019</b-form-checkbox>
                    </validation-provider>
                  </b-col>
                  <b-col sm="2">
                    <validation-provider>
                      <b-form-checkbox v-model="profile.alumni2018" value="1" unchecked-value="0">2018</b-form-checkbox>
                    </validation-provider>
                  </b-col>
                  <b-col sm="2">
                    <validation-provider>
                      <b-form-checkbox v-model="profile.alumni2017" value="1" unchecked-value="0">2017</b-form-checkbox>
                    </validation-provider>
                  </b-col>
                  <b-col sm="2">
                    <validation-provider>
                      <b-form-checkbox v-model="profile.alumni2016" value="1" unchecked-value="0">2016</b-form-checkbox>
                    </validation-provider>
                  </b-col>
                  <b-col sm="2">
                    <validation-provider>
                      <b-form-checkbox v-model="profile.alumni2015" value="1" unchecked-value="0">2015</b-form-checkbox>
                    </validation-provider>
                  </b-col>
                  <b-col sm="2">
                    <validation-provider>
                      <b-form-checkbox v-model="profile.alumni2013" value="1" unchecked-value="0">2013</b-form-checkbox>
                    </validation-provider>
                  </b-col>
                  <b-col sm="2">
                    <validation-provider>
                      <b-form-checkbox v-model="profile.alumni2011" value="1" unchecked-value="0">2011</b-form-checkbox>
                    </validation-provider>
                  </b-col>
                  <b-col sm="2">
                    <validation-provider>
                      <b-form-checkbox v-model="profile.alumni2009" value="1" unchecked-value="0">2009</b-form-checkbox>
                    </validation-provider>
                  </b-col>
                  <b-col sm="2">
                    <validation-provider>
                      <b-form-checkbox v-model="profile.alumni2007" value="1" unchecked-value="0">2007</b-form-checkbox>
                    </validation-provider>
                  </b-col>
                  <b-col sm="2">
                    <validation-provider>
                      <b-form-checkbox v-model="profile.alumni2004" value="1" unchecked-value="0">2004</b-form-checkbox>
                    </validation-provider>
                  </b-col>
                  <b-col sm="2">
                    <validation-provider>
                      <b-form-checkbox v-model="profile.alumni2001" value="1" unchecked-value="0">2001</b-form-checkbox>
                    </validation-provider>
                  </b-col>
                </b-row>
                <template v-slot:label>
                  <label>Which <em>MAGIC Lives</em> have they attended?</label>
                </template>
              </b-form-group>
              <validation-provider>
                <b-form-group label="Special Accomodations" label-for="add-registration-accomodations" description="Mobility, vision, hearing, etc.">
                  <b-form-input id="add-registration-accomodations" v-model="profile.accomodations"></b-form-input>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-modal>
    </validation-observer>

    <validation-observer v-slot="{invalid, pristine}">
			<b-modal id="phone-number-modal" centered hide-header v-on:cancel='phoneNumberId = ""' v-on:ok.prevent="addPhoneNumber" no-close-on-backdrop :ok-disabled="invalid || pristine">
				<validation-provider rules="required" v-slot="v">
					<b-form-group
						id="input-group-modal-phone"
						label="Phone"
						label-for="input-modal-phone"
						description="Enter a new phone number, including country code.">
						<b-form-input
              type="tel"
              id="input-modal-phone"
              v-model="phoneNumber"
              :state="v.errors.length > 0 ? false : null"
            >
            </b-form-input>
						<b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>
				<b-form-text text-variant="danger" v-if="phoneNumberError">{{ phoneNumberError }}</b-form-text>
			</b-modal>
		</validation-observer>

    <validation-observer v-slot="{invalid, pristine}">
			<b-modal id="address-modal" centered hide-header v-on:cancel='address = "{}"' v-on:ok="addAddress" no-close-on-backdrop :ok-disabled="invalid || pristine">

				<validation-provider rules="required" v-slot="v">
					<b-form-group
						id="input-group-modal-street-address-1"
						label="Street Address"
						label-for="input-modal-street-address-1"
						description="Line 1">
						<b-form-input id="input-modal-street-address-1" v-model="address.addressLine1" :state="v.errors.length > 0 ? false : null"></b-form-input>
						<b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>
				<b-form-group
					id="input-group-modal-street-address-2"
					description="Line 2">
					<b-form-input id="input-modal-street-address-2" v-model="address.addressLine2"></b-form-input>
				</b-form-group>
				<validation-provider rules="required" v-slot="v">
					<b-form-group
						id="input-group-modal-city"
						label="City"
						label-for="input-modal-city">
						<b-form-input id="input-modal-city" v-model="address.city" :state="v.errors.length > 0 ? false : null"></b-form-input>
						<b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>
				<b-form-group
					id="input-group-modal-state"
					label="State or Province"
					label-for="input-modal-state">
					<b-form-input id="input-modal-state" v-model="address.state"></b-form-input>
				</b-form-group>
				<b-form-group
					id="input-group-modal-postal-code"
					label="Postal Code"
					label-for="input-modal-state">
					<b-form-input id="input-modal-postal-code" v-model="address.postalCode"></b-form-input>
				</b-form-group>
				<validation-provider rules="required" v-slot="v">
					<b-form-group
						id="input-group-modal-country"
						label="Country"
						label-for="input-modal-country">
						<b-form-select value-field="abbreviation" text-field="country" v-model="address.country" :options="countries" :state="v.errors.length > 0 ? false : null"></b-form-select>
						<b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>
				<b-form-text text-variant="danger" v-if="addressError">{{ addressError }}</b-form-text>
			</b-modal>
		</validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import countries from '../../assets/countries.json';

extend('email', {
	...email,
	message: 'Must be a valid email'
})
extend('required', {
	...required,
	message: 'This field is required'
})

export default {
  name: 'ProfileModal',
  components: { ValidationProvider, ValidationObserver},
  props: {
    globals: Object,
    account: Object,
  },
  data: function() {
    return {
      profile: {},
      shirtSizes: [
				"XS",
				"S",
				"M",
				"L",
				"XL",
				"2XL",
				"3XL",
				"4XL"
      ],
      overrideBirthdayRules: false,
      overrideAddressRules: false,
      overridePhoneNumberRules: false,
      phoneNumber: '',
      phoneNumberError: null,
      address: {},
      addressError: null,
      countries
    }
  },
  computed: {
    birthdayRules() {
      return this.overrideBirthdayRules ? '' : 'required';
    },
    birthdayLabel() {
      return this.overrideBirthdayRules ? 'Date of Birth' : 'Date of Birth*';
    },
    addressRules() {
      return this.overrideAddressRules ? '' : 'required';
    },
    addressLabel() {
      return this.overrideAddressRules ? 'Address' : 'Address*';
    },
    phoneNumberRules() {
      return this.overridePhoneNumberRules ? '' : 'required';
    },
    phoneNumberLabel() {
      return this.overridePhoneNumberRules ? 'Phone Number' : 'Phone Number*';
    }
	},
  methods: {
    resetProfile() {
      this.profile = {};
      this.overrideBirthdayRules = false;
      this.overrideAddressRules = false;
      this.overridePhoneNumberRules = false;
    },
    async saveProfile(bvModalEvt) {
      const data = this.profile;
      this.profile.accountId = this.account.id;

      const request = {
				url: '/profiles',
				method: 'POST',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data
			};

			try {
				// Execute request
				await this.$api3(request);
				
        // Update data
        this.$emit('reload');
				
				// Hide the modal
				bvModalEvt.vueTarget.hide();
			} catch(error) {
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
				bvModalEvt.vueTarget.hide();
			}
    },
    addPhoneNumber: async function(bvModalEvt) {
			this.phoneNumberError = '';

			// Construct request
			const request = {
				url: '/phone-numbers',
				method: 'post',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data: {
					accountId: this.account.id,
					phoneNumber: this.phoneNumber.replace(/\D/g, "")
				}
			};
			
			try {
				// Execute request
				const response = await this.$api3(request);
				
				// Update data
				this.account.phoneNumbers.push(response.data);
				this.profile.phoneNumberId = response.data.id;
				
				// Show the modal
				bvModalEvt.vueTarget.hide();
			} catch(error) {
				this.phoneNumberError = error;
			}
		},
		addAddress: async function(bvModalEvt) {
			this.addressError = '';

			// Construct request
			const request = {
				url: '/addresses',
				method: 'POST',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data: {
					accountId: this.account.id,
					addressLine1: this.address.addressLine1,
					addressLine2: this.address.addressLine2,
					city: this.address.city,
					state: this.address.state,
					postcalCode: this.address.postalCode,
					country: this.address.country
				}
			};
			
			try {
				// Execute request
				const response = await this.$api3(request);
				
				// Update data
				this.account.addresses.push({ id: response.data.id, primaryLine: this.address.addressLine1 });
				this.profile.addressId = response.data.id;
				
				// Show the modal
				bvModalEvt.vueTarget.hide();
			} catch(error) {
        console.log(error.response);
				this.addressError = error;
			}
		},
  }
}
</script>