<template>
  <div>
    <div v-if="account.id && (typeof account.profiles[0] !== 'undefined')">
      <h2>{{ account.primaryProfile ? account.primaryProfile.badgeNameFull : 'New' }}<small class="text-muted"> Account {{ account.id}}</small></h2>

      <b-row>
        <b-col md="8">
          <b-card-group deck class="mb-4">
            <b-card>
              <template v-slot:header>
                <h6 class="mt-1 mb-0 font-weight-normal">
                  Profiles
                  <b-icon icon="plus" class="float-right" style="cursor: pointer" scale="1.4" v-b-modal.profile></b-icon>
                </h6>
              </template>
              <p v-for="profile in account.profiles" v-bind:key="profile.id">
                <b-link :to="'/profiles/'+ profile.id ">{{ profile.badgeNameFull }}</b-link>
                <template v-if="profile.nameFull !== profile.badgeNameFull"><br />({{ profile.nameFull }})</template>
              </p>
            </b-card>
            <b-card>
              <template v-slot:header>
                <h6 class="mt-1 mb-0 font-weight-normal">
                  Groups
                    <b-icon v-if="!_some(account.groups, ['eventId', globals.event.id])" icon="plus" class="float-right" style="cursor: pointer" scale="1.4" v-b-modal.registration-cost></b-icon>
                </h6>
              </template>
              <p v-for="group in account.groups" v-bind:key="group.id">
                <b-link :to="'/groups/'+ group.token ">{{ group.event.name }}</b-link><br />
                <ul>
                  <li v-if="group.registrationsActive.length === 0">
                    No active Registrations
                    <b-button variant="link" v-if="group.purchased === null" @click="deleteGroup(group)"><b-icon icon="trash-fill" variant="dark" /></b-button>
                  </li>
                  <li v-else v-for="registration in group.registrationsActive" v-bind:key="registration.id">{{ registration.profile.badgeNameFull }}</li>
                </ul>
              </p>
            </b-card>
          </b-card-group>
          <b-card-group deck>
            <b-card header="Addresses">
              <dl v-for="address in account.addresses" v-bind:key="address.id">
                <dt v-if="address.profiles.length">{{ namesFromEagerProfiles(address.profiles) }}</dt>
                <dd>{{ address.primaryLine}}<br />
                <template v-if="address.secondaryLine">{{ address.secondaryLine }}<br /></template>
                {{ address.lastLine }}<br />
                {{ address.countryCode }}</dd>
              </dl>
            </b-card>
            <b-card header="Phone Numbers">
              <dl v-for="phoneNumber in account.phoneNumbers" v-bind:key="phoneNumber.id">
                <dt v-if="phoneNumber.profiles.length">{{ namesFromEagerProfiles(phoneNumber.profiles) }}</dt>
                <dd>{{ phoneNumber.nationalFormat || phoneNumber.phoneNumber }} <b-badge v-if="phoneNumber.carrierType">{{ phoneNumber.carrierType }}</b-badge></dd>
              </dl>
            </b-card>
          </b-card-group>
        </b-col>
        <b-col md="4">
          <b-card no-body class="mb-4">
            <template v-slot:header>
              <h6 class="mt-1 mb-0 font-weight-normal">
                Transactions
                <b-icon icon="plus" class="float-right" style="cursor: pointer" scale="1.4" v-b-modal.transaction></b-icon>
                <small class="float-right mr-3">
                  <a v-if="account.stripeCustomerId" target="_blank" :href="`https://dashboard.stripe.com/customers/${ account.stripeCustomerId }`">View in Stripe</a>
                  <b-link v-else href="" v-b-modal.link-stripe-customer>Link to Stripe</b-link>
                </small>
              </h6>
            </template>
            <b-card-body v-if="account.stripeCustomerId" class="py-1 text-center" body-bg-variant="light">
              <b-overlay :show="!currentPaymentMethod.status" spinner-small>
                <small v-if="currentPaymentMethod.error" class="text-danger">{{ currentPaymentMethod.error }}</small>
                <small v-else-if="!currentPaymentMethod.card.last4"><b-link @click="loadCurrentPaymentMethod()">View Payment Method</b-link> | <a :href="`https://app.magicconvention.com/update-payment-method/${account.token}`" target="_blank">Update Payment Method</a></small>
                <small v-else>{{ capitalize(currentPaymentMethod.card.brand) }} {{ currentPaymentMethod.card.funding }} card ending {{ currentPaymentMethod.card.last4 }}, exp. {{ currentPaymentMethod.card.exp_month }}/{{ currentPaymentMethod.card.exp_year }}</small>
              </b-overlay>
            </b-card-body>
            <template v-if="account.pendingTransactions.length > 0">
              <b-card-footer>
                <div class="float-left">Pending Balance</div>
                <div :class="'text-right ' + ( account.pendingBalance < 0 ? 'text-danger' : 'text-success' )">{{ account.pendingBalance | currency }}</div>
              </b-card-footer>
              <b-table
                :items="pendingTransactionsComputed"
                :fields="['toggle', 'created', 'type.name', {
                  key: 'amount', tdClass: 'text-right'
                },]"
                thead-class="d-none"
                class="mb-0"
              >
                <template v-slot:cell(created)="data">
                  {{ $dateHelper(data.item.created, '$dateShort') }}
                </template>
                <template v-slot:cell(amount)="data">
                  {{ data.item.amount | currency }}
                </template>
                <template v-slot:cell(toggle)="data">
                  <b-button size="sm" class="p-0" variant="link" v-on:click="data.toggleDetails"><b-icon :icon="data.detailsShowing ? 'caret-down-fill' : 'caret-right-fill'" variant="dark" /></b-button>
                </template>
                <template v-slot:row-details="table">
                  <dl class="ml-2">
                    <b-button @click="deleteTransaction(table.item)" variant="link" v-b-tooltip.hover title="Delete Transaction" class="float-right"><b-icon-trash variant="danger"></b-icon-trash></b-button>
                    <b-button @click="editTransaction(table.item)" variant="link" v-b-tooltip.hover title="Edit Transaction" class="float-right"><b-icon-pencil-square variant="dark"></b-icon-pencil-square></b-button>
                    <b-button v-if="new Date().toISOString() > table.item.created" variant="link" v-b-tooltip.hover title="View Payment Link" class="float-right" :href="`https://app.magicconvention.com/update-payment-method/${account.token}?txid=${table.item.id}`" target="_blank"><b-icon-link45deg variant="dark"></b-icon-link45deg></b-button>
                    <dt>Detail</dt>
                    <dd>{{ table.item.detail, }}</dd>
                    <dt>To Be Created</dt>
                    <dd>{{ $dateHelper(table.item.created, '$date') }}</dd>
                    <template v-if="table.item.stripeChargeId"><dt>Stripe Charge ID</dt>
                    <dd><a :href="'https://dashboard.stripe.com/charges/' + table.item.stripeChargeId" target="_blank">{{ table.item.stripeChargeId }}</a></dd></template>
                    <template v-if="table.item.reminderCount > 0"><dt>Reminded</dt>
                    <dd>{{ capitalize(formatDistance(new Date(), new Date(table.item.lastReminded))) }} ago <b-badge :variant="table.item.reminderCount >= 4 ? 'danger' : 'secondary'">{{ ordinal(table.item.reminderCount) }}</b-badge></dd></template>
                    <template v-if="table.item.omitFee">
                      <p class="text-center"><em>The fee will be omitted on this Transaction.</em></p>
                    </template>
                  </dl>
                </template>
                <template v-slot:top-row>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td>Pending Fees</b-td>
                  <b-td class="text-right"><span :class="account.pendingFees < 0 ? 'text-danger' : 'text-success'">{{ account.pendingFees | currency }}</span></b-td>
                </template>
              </b-table>
            </template>
            <b-card-footer>
              <div class="float-left">Current Balance</div>
              <div :class="'text-right ' + ( account.currentBalance < 0 ? 'text-danger' : 'text-success' )">{{ account.currentBalance | currency }}</div>
            </b-card-footer>
            <b-table
              :items="account.transactions"
              :fields="['toggle', 'created', 'type.name', {
                key: 'amount', tdClass: 'text-right'
              },]"
              thead-class="d-none"
              class="mb-0"
            >
              <template v-slot:cell(created)="data">
                {{ $dateHelper(data.item.created, '$dateShort') }}
              </template>
              <template v-slot:cell(amount)="data">
                <span :class="data.item.amount < 0 ? 'text-danger' : 'text-success'">{{ data.item.amount | currency }}</span>
              </template>
              <template v-slot:cell(toggle)="data">
                <b-button size="sm" class="p-0" variant="link" v-on:click="data.toggleDetails"><b-icon :icon="data.detailsShowing ? 'caret-down-fill' : 'caret-right-fill'" variant="dark" /></b-button>
              </template>
              <template v-slot:row-details="table">
                <dl class="ml-2">
                  <b-button v-if="!table.item.registration && !table.item.dealerBooth && table.item.eventId === globals.event.id" @click="editTransaction(table.item)" variant="link" v-b-tooltip.hover title="Edit Transaction" class="float-right"><b-icon-pencil-square variant="dark"></b-icon-pencil-square></b-button>
                  <dt>Detail</dt>
                  <dd>{{ table.item.detail }}</dd>
                  <dt>Created</dt>
                  <dd>{{ $dateHelper(table.item.created) }}</dd>
                  <template v-if="table.item.stripeChargeId"><dt>Stripe Payment ID</dt>
                  <dd><a :href="'https://dashboard.stripe.com/payments/' + table.item.stripeChargeId" target="_blank">{{ table.item.stripeChargeId }}</a></dd></template>
                </dl>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <transaction-modal :globals="globals" :account="account" :existing-transaction="currentTransaction" @refresh="loadHandler()" @hidden="currentTransaction = {}" />
    
    <profile-modal :globals="globals" :account="account" @reload="loadHandler()" @alert="params => { $emit('alert', params) }" @propagateAccount="account => { this.account = account }" />

    <link-stripe-customer-modal :globals="globals" :account="account" />
    <registration-cost-modal @next="createGroup" />
  </div>
</template>

<script>
import TransactionModal from '../modals/TransactionModal.vue';
import ProfileModal from '../modals/ProfileModal.vue';
import LinkStripeCustomerModal from '../modals/LinkStripeCustomerModal.vue';
import { capitalize, orderBy, some as _some } from 'lodash';
import { formatDistance } from 'date-fns';
import RegistrationCostModal from '../modals/RegistrationCostModal.vue';
const ordinal = require('ordinal');

export default {
  name: 'Account',
  components: {
    LinkStripeCustomerModal,
    ProfileModal,
    TransactionModal,
    RegistrationCostModal
},
	props: {
		globals: Object,
	},
	data: function() {
		return {
			account: {},
      currentTransaction: {},
      currentPaymentMethod: {
        status: true,
        error: false,
        card: {}
      },
		}
	},
  computed: {
    pendingTransactionsComputed() {
      return this.account.pendingTransactions.map(transaction => {
        transaction._rowVariant = transaction.reminderCount > 0 ? transaction.reminderCount >= 4 ? 'danger' : 'warning' : '';
        return transaction;
      });
    }
  },
	methods: {
		namesFromEagerProfiles(profiles) {
			let result = "";
      

			profiles.forEach((profile, index) => {
				result += profile.badgeNameFull;

				if (index < profiles.length -1 && profiles.length > 2) {
					result += ',';
				}

				if (index == profiles.length -2) {
					result += ' and ';
				} else if (index < profiles.length -1) {
					result += ' ';
				}
			})

			return result;
		},
    editTransaction(transaction) {
      // Set current transaction
      this.currentTransaction = transaction;
      this.$bvModal.show('transaction');
    },
    async deleteTransaction(transaction) {
      const amount = this.$options.filters.currency(transaction.amount);
      const created = this.$dateHelper(transaction.created, '$date');
      const confirmString = `You are about to delete a ${amount} ${transaction.type.name} transaction dated ${created}.`;
      const result = await this.$bvModal.msgBoxConfirm(confirmString, {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete'
      });

      if( result !== true ) return;

      const request = {
        url: `/transactions/${transaction.id}`,
        method: 'DELETE',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
      };

      await this.$api3(request).catch((error) => {
        // Handle error in executing request
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      });

      this.loadHandler();
    },
    async createGroup(amount) {
      console.log(amount);
      // Create a Group for the default Event
      const groupRequest = {
				url: '/groups',
				method: 'POST',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data: {
          accountId: this.account.id,
          eventId: this.globals.event.id,
          purchased: true,
				}
      }

      const groupResponse = await this.$api3(groupRequest).catch((error) => {
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

        console.log(message);

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      });

      // Create a Registration request - this will get used after a transaction is created, if necessary
      const registrationRequest = {
				url: '/registrations',
				method: 'POST',
				headers: {'Authorization': 'bearer ' + this.globals.accessToken},
				data: {
          groupId: groupResponse.data.id,
          profileId: this.account.primaryProfile.id,
          active: 1,
				}
      }

      // Add a transaction
      if(amount < 0 ) {
        const transactionRequest = {
          url: '/transactions',
          method: 'POST',
          headers: { Authorization: 'bearer ' + this.globals.accessToken},
          data: {
            accountId: this.account.id,
            eventId: this.globals.event.id,
            amount,
            transactionTypeId: 10,
            detail: `Registration for ${this.account.primaryProfile.badgeNameFull}`,
          }
        };

        const transactionResponse = await this.$api3(transactionRequest).catch((error) => {
          let message;

          if (error.response) {
            // Server responded
            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          console.log(message);

          // Emit the error and close the modal
          this.$emit('alert', { error: true, message: message });
        });
        console.log(transactionResponse.message);

        registrationRequest.data.transactionId = transactionResponse.data.id;
      }

      // Proceed with creating the Registration
      await this.$api3(registrationRequest).catch((error) => {
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

        console.log(message);

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
      });

      // Redirect to the Group
      this.$router.push(`/groups/${groupResponse.data.token}`)

    },
    async loadHandler() {
      // Construct request
      const request = {
        url: `/accounts/${this.$route.params.id}`,
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        params: {
          $eager: '[addresses.profiles,groups.[event,registrations.profile.account,invitations],phoneNumbers.profiles,primaryProfile,profiles,transactions.[type,dealerBooth,registration.profile]]',
        }
      }

      // Execute request
      const result = await this.$api3(request).catch((error) => {
        // Handle error in executing request
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
        console.log(message);
      });

      // Update relevant data
      this.account = result.data;

      // Sort Groups
      if( this.account.groups.length > 0 ) {
        this.account.groups = orderBy(this.account.groups, 'eventId', 'desc');
      }

      if( this.account.profiles.length === 0 ) {
        this.$bvModal.show('profile');
      }

      this.$emit('status', true);
    },
    async deleteGroup(group) {
      // Delete registrations and invitations
      let batchRequest = {
        url: '/batch',
        method: 'post',
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        data: {
          calls: [],
        }
      };

      for (const registration of group.registrations) {
        batchRequest.data.calls.push([
          'remove',
          'registrations',
          registration.token
        ])
      }

      for (const invitation of group.invitations) {
        batchRequest.data.calls.push([
          'remove',
          'invitations',
          invitation.token
        ])
      }

      await this.$api3(batchRequest);

      // Finally, delete the group
      const request = {
        url: `/groups/${group.token}`,
        headers: {'Authorization': 'bearer ' + this.globals.accessToken},
        method: 'DELETE'
      }

      this.$api3(request).catch((error) => {
        // Handle error in executing request
				let message;

				if (error.response) {
					// Server responded
					message = 'Server Error: ' + error.response.data.message;
				} else {
					// No response or Error in request
					message = 'Axios: ' + error;
				}

				// Emit the error and close the modal
				this.$emit('alert', { error: true, message: message });
        console.log(message);
      }).then(() => {
        this.loadHandler();
      })
    },
    async loadCurrentPaymentMethod() {
      this.currentPaymentMethod.status = false;
      this.currentPaymentMethod.error = false;
      const request = {
        url: '/stripe/payment-methods',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          customer: this.account.stripeCustomerId,
          type: 'card'
        }
      }

      const response = await this.$api3(request).catch(error => {
        this.currentPaymentMethod.error = error;
        this.currentPaymentMethod.status = true;
        return;
      });

      if (response.data.data.length !== 1) { 
        this.currentPaymentMethod.error = 'Error loading payment methods.';
        this.currentPaymentMethod.status = true;
        return;
      }
      this.currentPaymentMethod.card = response.data.data[0].card;
      this.currentPaymentMethod.status = true;
    },
    capitalize,
    formatDistance,
    ordinal,
    _some
	},
	beforeCreate() {
		this.$emit('status', false);
	},
  async created() {
    if( this.$route.params.id !== 'new' ) {
      this.loadHandler();
      
      return;
    }

    // Create an account so we have somewhere to put phoneNumbers, addresses, and profiles
    const request = {
      url: '/accounts',
      method: 'POST',
      headers: {'Authorization': 'bearer ' + this.globals.accessToken}
    };

    const response = await this.$api3(request).catch((error) => {
      let message;

      if (error.response) {
        // Server responded
        message = 'Server Error: ' + error.response.data.message;
      } else {
        // No response or Error in request
        message = 'Axios: ' + error;
      }

      // Emit the error and close the modal
      this.$emit('alert', { error: true, message: message });
    })

    // this.account = response.data.account;

    this.$router.push(`/accounts/${response.data.account.token}`);
    this.loadHandler();

	},
};
</script>