<template>
	<div>
		<h2>Groups <small>Scheduling</small></h2>
    <b-tabs justified v-if="groups.ready.limit && groups.sent.limit && groups.reminderSent.limit && groups.finalReminderSent.limit && groups.completed.limit">
      <b-tab>
        <template #title>
          <strong>Ready</strong> {{ groups.ready.total }}
        </template>
        <b-row class="mt-2">
          <b-col cols="3">
            <b-button-toolbar>
              <b-button size="sm" class="mr-2" @click="$refs.groupsReadyTable.selectAllRows()">All</b-button>
              <b-button size="sm" @click="$refs.groupsReadyTable.clearSelected()">None</b-button>
            </b-button-toolbar>
          </b-col>
          <b-col>
            <p class="text-center">Showing {{groups.ready.data.length}} of {{groups.ready.total}}</p>
          </b-col>
          <b-col>
            <p class="text-center">{{selected.length}} Selected</p>
          </b-col>
          <b-col cols="3">
            <b-button size="sm" class="float-right" @click="sendSchedulingToSelected()">Send Email</b-button>
          </b-col>
        </b-row>
        <b-table
          id="groups-ready-table"
          ref="groupsReadyTable"
          striped
          selectable
          selected-variant="info"
          @row-selected="onRowSelected"
          :items="groups.ready.data"
          :fields="[
            {
              key: 'account.primaryProfile.badgeNameFull',
              label: 'Primary Registrant',
            },
            {
              key: 'account.primaryProfile.nameFull',
              label: '',
            },
            {
              key: 'account.primaryProfile.email',
              label: 'Email',
            },
            {
              key: 'priority',
            },
            {
              key: 'actions',
              label: '',
            }
          ]" 
        >
          <template v-slot:cell(priority)="data">
            <span :style="{ fontStyle: data.item.priority === data.item.purchased ? 'normal' : 'italic' }">{{ $dateHelper(data.item.priority || data.item.purchased) }}</span>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button size="sm" variant="link" :to="'/groups/'+data.item.token" v-b-tooltip.hover title="View Group"><b-icon-people-fill variant="dark"></b-icon-people-fill></b-button>
          </template>
        </b-table>
      </b-tab>
      <b-tab>
        <template #title>
          <strong>Sent</strong> {{ groups.sent.total }}
        </template>
        <b-row class="mt-2">
          <b-col cols="3">
            <b-button-toolbar>
              <b-button size="sm" class="mr-2" @click="$refs.groupsReadyTable.selectAllRows()">All</b-button>
              <b-button size="sm" @click="$refs.groupsReadyTable.clearSelected()">None</b-button>
            </b-button-toolbar>
          </b-col>
          <b-col>
            <p class="text-center">Showing {{groups.ready.data.length}} of {{groups.ready.total}}</p>
          </b-col>
          <b-col>
            <p class="text-center">{{selected.length}} Selected</p>
          </b-col>
          <b-col cols="3">
            <b-button size="sm" class="float-right" @click="sendReminderToSelected()">Send Email</b-button>
          </b-col>
        </b-row>
        <b-table
          id="groups-sent-table"
          ref="groupsSentTable"
          striped
          selectable
          selected-variant="info"
          @row-selected="onRowSelected"
          :items="groups.sent.data"
          :fields="[
            {
              key: 'account.primaryProfile.badgeNameFull',
              label: 'Primary Registrant',
            },
            {
              key: 'account.primaryProfile.nameFull',
              label: '',
            },
            {
              key: 'account.primaryProfile.email',
              label: 'Email',
            },
            {
              key: 'priority',
            },
            {
              key: 'schedulingSent',
            },
            {
              key: 'actions',
              label: '',
            }
          ]" 
        >
          <template v-slot:cell(priority)="data">
            {{ $dateHelper(data.item.priority || data.item.purchased) }}
          </template>
          <template v-slot:cell(schedulingSent)="data">
            {{ $dateHelper(data.item.schedulingSent, '$date') }}
          </template>
          <template v-slot:cell(actions)="data">
            <b-button size="sm" variant="link" :to="'/groups/'+data.item.token" v-b-tooltip.hover title="View Group"><b-icon-people-fill variant="dark"></b-icon-people-fill></b-button>
          </template>
        </b-table>
      </b-tab>
      <b-tab>
        <template #title>
          <strong>Reminder Sent</strong> {{ groups.reminderSent.total }}
        </template>
        <b-row class="mt-2">
          <b-col cols="3">
            <b-button-toolbar>
              <b-button size="sm" class="mr-2" @click="$refs.groupsReadyTable.selectAllRows()">All</b-button>
              <b-button size="sm" @click="$refs.groupsReadyTable.clearSelected()">None</b-button>
            </b-button-toolbar>
          </b-col>
          <b-col>
            <p class="text-center">Showing {{groups.ready.data.length}} of {{groups.ready.total}}</p>
          </b-col>
          <b-col>
            <p class="text-center">{{selected.length}} Selected</p>
          </b-col>
          <b-col cols="3">
            <b-button size="sm" class="float-right" @click="sendFinalReminderToSelected()">Send Email</b-button>
          </b-col>
        </b-row>
        <b-table
          id="groups-reminded-table"
          striped
          selectable
          selected-variant="info"
          @row-selected="onRowSelected"
          :items="groups.reminderSent.data"
          :fields="[
            {
              key: 'account.primaryProfile.badgeNameFull',
              label: 'Primary Registrant',
            },
            {
              key: 'account.primaryProfile.nameFull',
              label: '',
            },
            {
              key: 'account.primaryProfile.email',
              label: 'Email',
            },
            {
              key: 'priority',
            },
            {
              key: 'schedulingSent',
            },
            {
              key: 'schedulingReminderSent',
              label: 'Reminder Sent'
            },
            {
              key: 'actions',
              label: '',
            }
          ]" 
        >
          <template v-slot:cell(priority)="data">
            {{ $dateHelper(data.item.priority || data.item.purchased) }}
          </template>
          <template v-slot:cell(schedulingSent)="data">
            {{ $dateHelper(data.item.schedulingSent, '$date') }}
          </template>
          <template v-slot:cell(schedulingReminderSent)="data">
            {{ $dateHelper(data.item.schedulingReminderSent, '$date') }}
          </template>
          <template v-slot:cell(actions)="data">
            <b-button size="sm" variant="link" :to="'/groups/'+data.item.token" v-b-tooltip.hover title="View Group"><b-icon-people-fill variant="dark"></b-icon-people-fill></b-button>
          </template>
        </b-table>
      </b-tab>
      <b-tab>
        <template #title>
          <strong>Final Reminder Sent</strong> {{ groups.finalReminderSent.total }}
        </template>
        <b-table
          id="groups-final-reminded-table"
          striped
          :items="groups.finalReminderSent.data"
          :fields="[
            {
              key: 'account.primaryProfile.badgeNameFull',
              label: 'Primary Registrant',
            },
            {
              key: 'account.primaryProfile.nameFull',
              label: '',
            },
            {
              key: 'account.primaryProfile.email',
              label: 'Email',
            },
            {
              key: 'priority',
            },
            {
              key: 'schedulingSent',
            },
            {
              key: 'actions',
              label: '',
            }
          ]" 
        >
          <template v-slot:cell(priority)="data">
            {{ $dateHelper(data.item.priority || data.item.purchased) }}
          </template>
          <template v-slot:cell(schedulingSent)="data">
            {{ $dateHelper(data.item.schedulingSent) }}
          </template>
          <template v-slot:cell(actions)="data">
            <b-button size="sm" variant="link" :to="'/groups/'+data.item.token" v-b-tooltip.hover title="View Group"><b-icon-people-fill variant="dark"></b-icon-people-fill></b-button>
          </template>
        </b-table>
      </b-tab>
      <b-tab>
        <template #title>
          <strong>Completed</strong> {{ groups.completed.total }}
        </template>
        <b-table
          id="groups-completed-table"
          striped
          :items="groups.completed.data"
          :fields="[
            {
              key: 'account.primaryProfile.badgeNameFull',
              label: 'Primary Registrant',
            },
            {
              key: 'account.primaryProfile.nameFull',
              label: '',
            },
            {
              key: 'account.primaryProfile.email',
              label: 'Email',
            },
            {
              key: 'priority',
            },
            {
              key: 'schedulingSent',
            },
            {
              key: 'schedulingCompleted',
            },
            {
              key: 'actions',
              label: '',
            }
          ]" 
        >
          <template v-slot:cell(priority)="data">
            {{ $dateHelper(data.item.priority || data.item.purchased) }}
          </template>
          <template v-slot:cell(schedulingSent)="data">
            {{ $dateHelper(data.item.schedulingSent) }}
          </template>
          <template v-slot:cell(schedulingCompleted)="data">
            {{ $dateHelper(data.item.schedulingCompleted) }}
          </template>
          <template v-slot:cell(actions)="data">
            <b-button size="sm" variant="link" :to="'/groups/'+data.item.token" v-b-tooltip.hover title="View Group"><b-icon-people-fill variant="dark"></b-icon-people-fill></b-button>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
	</div>
</template>

<script>
import { findIndex as _findIndex, startCase as _startCase } from 'lodash';

export default {
	name: 'Scheduling',
	props: {
		globals: Object,
	},
	methods: {
    async loadHandler() {
      // groups.ready
      // Construct request
      const readyRequest = {
        url: '/groups',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          $joinRelation: 'registrations.tags',
          $eager: 'account.primaryProfile',
          'groups.eventId': this.globals.event.id,
          registrationTagId: 75,
          schedulingSent: 'null',
          schedulingCompleted: 'null',
          $limit: 100,
          '$sort[priority]': 1
        }
      }

      // Execute request
      const readyResponse = await this.$api3(readyRequest).catch((error) => {
        console.log(error);
      });

      // Update relevant data
      this.groups.ready = readyResponse.data;

      // groups.sent
      // Construct request
      const sentRequest = {
        url: '/groups',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          $eager: 'account.primaryProfile',
          'groups.eventId': this.globals.event.id,
          'schedulingSent[$gt]': '1970-01-01', // Use the UNIX epoch as 'not null'
          schedulingReminderSent: 'null',
          schedulingFinalReminderSent: 'null',
          schedulingCompleted: 'null',
          $limit: 100,
          '$sort[schedulingSent]': 1,
          '$sort[schedulingReminderSent]': 1
        }
      }

      // Execute request
      const sentResponse = await this.$api3(sentRequest).catch((error) => {
        console.log(error);
      });

      // Update relevant data
      this.groups.sent = sentResponse.data;

      // groups.reminderSent
      // Construct request
      const reminderSentRequest = {
        url: '/groups',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          $eager: 'account.primaryProfile',
          'groups.eventId': this.globals.event.id,
          'schedulingReminderSent[$gt]': '1970-01-01', // Use the UNIX epoch as 'not null'
          schedulingFinalReminderSent: 'null',
          schedulingCompleted: 'null',
          $limit: 100,
          '$sort[schedulingReminderSent]': 1
        }
      }

      // Execute request
      const reminderSentResponse = await this.$api3(reminderSentRequest).catch((error) => {
        console.log(error);
      });

      // Update relevant data
      this.groups.reminderSent = reminderSentResponse.data;

      // groups.finalReminderSent
      // Construct request
      const finalReminderSentRequest = {
        url: '/groups',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          $joinRelation: 'registrations.tags',
          'registrationTagId': 75,
          $eager: 'account.primaryProfile',
          'groups.eventId': this.globals.event.id,
          'schedulingFinalReminderSent[$gt]': '1970-01-01', // Use the UNIX epoch as 'not null'
          schedulingCompleted: 'null',
          $limit: 100,
          '$sort[schedulingFinalReminderSent]': 1
        }
      }

      // Execute request
      const finalReminderSentResponse = await this.$api3(finalReminderSentRequest).catch((error) => {
        console.log(error);
      });

      // Update relevant data
      this.groups.finalReminderSent = finalReminderSentResponse.data;

      // groups.completed
      // Construct request
      const completedRequest = {
        url: '/groups',
        headers: { Authorization: 'bearer ' + this.globals.accessToken},
        params: {
          $eager: 'account.primaryProfile',
          'groups.eventId': this.globals.event.id,
          'schedulingCompleted[$gt]': '1970-01-01', // Use the UNIX epoch as 'not null'
          $limit: 100,
          '$sort[schedulingCompleted]': -1,
        }
      }

      // Execute request
      const completedResponse = await this.$api3(completedRequest).catch((error) => {
        console.log(error);
      });

      // Update relevant data
      this.groups.completed = completedResponse.data;



      this.$emit('status', true);
    },
    onRowSelected(items) {
      this.selected = items;
    },
    async sendSchedulingToSelected() {
      // For each group in this.selected
      for(const group of this.selected) {
        // Send E14 to group.account.primaryProfile.email
        const emailRequest = {
          url: '/emails',
          method: 'post',
          data: {
            to: group.account.primaryProfile.email,
            template: 'e14',
            id: group.token
          }
        };

        const emailResponse = await this.$api3(emailRequest).catch((error) => {
          console.log(error);

          let message;

          if (error.response) {
            // Server responded
            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error and close the modal
          this.$emit('alert', { error: true, message: message });
        });
        if(emailResponse.status !== 201) {
          break;
        }
        console.log('✉️')

        // Update Group to set schedulingSent = true
        const groupRequest = {
          url: `/groups/${group.token}`,
          method: 'patch',
          data: {
            schedulingSent: true,
          }
        }
        const groupResponse = await this.$api3(groupRequest).catch((error) => {
          console.log(error);

          let message;

          if (error.response) {
            // Server responded
            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error and close the modal
          this.$emit('alert', { error: true, message: message });
        });
        if(groupResponse.status !== 200) {
          break;
        }
        console.log('📆')

        // Remove the Group from groups.ready - This might throw off the array?
        const index = _findIndex(this.groups.ready.data, {id: group.id});
        this.groups.ready.data.splice(index, 1);
        console.log('🔔');
      }

      // After all have been handled, execute loadHandler to update the Sent tab
      this.$emit('status', false);
      this.loadHandler();
    },
    async sendReminderToSelected() {
      // For each group in this.selected
      for(const group of this.selected) {
        // Send E14 to group.account.primaryProfile.email
        const emailRequest = {
          url: '/emails',
          method: 'post',
          data: {
            to: group.account.primaryProfile.email,
            template: 'e15',
            id: group.token
          }
        };

        const emailResponse = await this.$api3(emailRequest).catch((error) => {
          console.log(error);

          let message;

          if (error.response) {
            // Server responded
            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error and close the modal
          this.$emit('alert', { error: true, message: message });
        });
        if(emailResponse.status !== 201) {
          break;
        }
        console.log('✉️')

        // Update Group to set schedulingSent = true
        const groupRequest = {
          url: `/groups/${group.token}`,
          method: 'patch',
          data: {
            schedulingReminderSent: true,
          }
        }
        const groupResponse = await this.$api3(groupRequest).catch((error) => {
          console.log(error);

          let message;

          if (error.response) {
            // Server responded
            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error and close the modal
          this.$emit('alert', { error: true, message: message });
        });
        if(groupResponse.status !== 200) {
          break;
        }
        console.log('📆')

        // Remove the Group from groups.ready - This might throw off the array?
        const index = _findIndex(this.groups.sent.data, {id: group.id});
        this.groups.sent.data.splice(index, 1);
        console.log('🔔');
      }

      // After all have been handled, execute loadHandler to update the Sent tab
      this.$emit('status', false);
      this.loadHandler();
    },
    async sendFinalReminderToSelected() {
      // For each group in this.selected
      for(const group of this.selected) {
        // Send E14 to group.account.primaryProfile.email
        const emailRequest = {
          url: '/emails',
          method: 'post',
          data: {
            to: group.account.primaryProfile.email,
            template: 'e16',
            id: group.token
          }
        };

        const emailResponse = await this.$api3(emailRequest).catch((error) => {
          console.log(error);

          let message;

          if (error.response) {
            // Server responded
            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error and close the modal
          this.$emit('alert', { error: true, message: message });
        });
        if(emailResponse.status !== 201) {
          break;
        }
        console.log('✉️')

        // Update Group to set schedulingSent = true
        const groupRequest = {
          url: `${this.globals.endpoint}/v3/groups/${group.token}`,
          method: 'patch',
          data: {
            schedulingFinalReminderSent: true,
          }
        }
        const groupResponse = await this.$api3(groupRequest).catch((error) => {
          console.log(error);

          let message;

          if (error.response) {
            // Server responded
            message = 'Server Error: ' + error.response.data.message;
          } else {
            // No response or Error in request
            message = 'Axios: ' + error;
          }

          // Emit the error and close the modal
          this.$emit('alert', { error: true, message: message });
        });
        if(groupResponse.status !== 200) {
          break;
        }
        console.log('📆')

        // Remove the Group from groups.ready - This might throw off the array?
        const index = _findIndex(this.groups.sent.data, {id: group.id});
        this.groups.sent.data.splice(index, 1);
        console.log('🔔');
      }

      // After all have been handled, execute loadHandler to update the Sent tab
      this.$emit('status', false);
      this.loadHandler();
    }
	},
	watch: {},
	data: function() {
		return {
			groups: {
        ready: {},
        sent: {},
        reminderSent: {},
        finalReminderSent: {},
        completed: {},
      },
      selected: [],
      fields: [
				{
					key: 'account.primaryProfile.badgeNameFull',
					label: 'Primary Registrant',
				},
        {
          key: 'priority',
        },
        {
          key: 'schedulingSent',
          label: 'Sent'
        },
        {
          key: 'schedulingReminderSent',
          label: 'Reminder Sent'
        },
        {
          key: 'schedulingReminderSent',
          label: 'Final Reminder Sent'
        },{
          key: 'schedulingCompleted',
          label: 'completed'
        },
				{
					key: 'actions',
					label: ''
				}
			],
      currentPage: 1,
		}
	},
  filters: {
    _startCase
  },
	beforeCreate: function() {
		this.$emit('status', false);
	},
	created () {
		this.loadHandler();
	}
};
</script>

<style>
</style>
